import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CollectionService } from 'src/app/services/collection.service';
import { Meta, Title } from '@angular/platform-browser';
import { StorageService } from 'ng-blockchainx';

@Component({
  selector: 'app-nft-details',
  templateUrl: './nft-details.component.html',
  styleUrls: ['./nft-details.component.css'],
})

/**
 * Component
 */
export class NftDetailsComponent implements OnInit {
  public collectionId:string = '';
  public collection:any = {};
  public nfts:any = [];
  public user: any = {};
  private filterOptions:any = {
    searchKeyword: '',
    saleType: '',
    priceFilter: '',
    attributes: '',
  };
  private sortOptions:any = {
    priceSort: 1,
  };
  public queryFilter = false;
  public loading: boolean = false;
  public isAttrFilter: boolean = false;
  public pageLoader:boolean = false;


  /**
   * constructor
   */
  constructor(
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private collectionService: CollectionService,
    private router: Router,
    private titleService:Title,
    private metaService: Meta,
    private storageService: StorageService,

  ) {
    this.pageLoader = true;
  }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    window.scroll(0, 0);
    this.loading = true;
    this.user = this.storageService.get('user');
    this.activatedRoute.params.subscribe((params) => this.collectionId = params['id']);
    this.getCollections(this.collectionId);
    this.activatedRoute.queryParams
        .subscribe((params) => {
          if (Object.keys(params).length > 0) {
            this.queryFilter = true;
            const attributeObj = JSON.parse(params.attributes);
            this.attributeFilterCallback([`attribute_${Object.keys(attributeObj)[0]}=${encodeURIComponent(Object.values(attributeObj)[0] as string)}`]);
          } else {
            this.queryFilter = false;
            this.getNfts(this.collectionId, this.filterOptions, this.sortOptions);
          }
        });
  }


  /**
   * Get collections list
   * @param {string} id - collection id
   */
  public getCollections(id:string) {
    this.collectionService.getCollection(id)
        .subscribe({
          next: (response:any) => {
            this.collection = response['data'];
            this.titleService.setTitle(this.collection.collectionName);
            this.metaService.updateTag({ property: 'og:title', content: this.collection?.collectionName });
            this.metaService.updateTag({ property: 'og:description', content: this.collection?.description });
            this.metaService.updateTag({ property: 'og:image', content: this.collection?.coverPhoto });
            this.loading = false;
            this.pageLoader = false;
          },
          error: (error:any) => {
            this.pageLoader = false;
            this.toastr.error(error.message, 'Failed to fetch collection details');
          },
        });
  }

  /**
   * Get nft list
   * @param {string} id - collection id
   * @param {any} filterOptions - Filter options
   * @param {any} sortOptions - Sort options
   */
  public getNfts(id:string, filterOptions:any, sortOptions:any) {
    this.collectionService.getCollectionNfts(id, filterOptions, sortOptions, '')
        .subscribe({
          next: (response:any) => {
            this.nfts = response['data'];
            for (const element of this.nfts) {
              const isUrl = element.nftImage.includes('https');
              if (isUrl) {
                const nftImage = element.nftImage.split('/');
                element.nftImage = nftImage[nftImage.length - 1];
              }
            }
            this.pageLoader = false;
          },
          error: (error) => {
            this.toastr.error(error.message);
            this.pageLoader = false;
          },
        });
  }

  /**
   * Search by name callback
   * @param {any} event
   */
  public searchByNameCallback(event:any) {
    this.filterOptions.searchKeyword = event;
    this.getNfts(this.collectionId, this.filterOptions, this.sortOptions);
  }

  /**
   * Price filtering
   *
   * @param {any} event
   */
  public priceFilterCallback(event:any) {
    this.filterOptions.priceFilter = event;
    this.getNfts(this.collectionId, this.filterOptions, this.sortOptions);
  }

  /**
   * price sorting  callback
   * @param {any} value
   */
  public priceSortCallback(value:any) {
    this.sortOptions.priceSort = value;
    this.getNfts(this.collectionId, this.filterOptions, this.sortOptions);
  }

  /**
   * Sale type filter
   *
   * @param {any} value
   */
  public saleTypeFilterCallback(value:any = {}) {
    const payload = Object.values(value);
    this.filterOptions['saleType'] = payload.join(',');
    this.getNfts(this.collectionId, this.filterOptions, this.sortOptions);
  }

  /**
   * Attribute filter callback
   *
   * @param {any} value
   */
  public attributeFilterCallback(value:any = {}) {
    this.isAttrFilter = true;
    this.filterOptions['attributes'] = value.join('&');
    this.getNfts(this.collectionId, this.filterOptions, this.sortOptions);
  }

  /**
   * Clear filter callback
   *
   * @param {any} value
   */
  public clearFilterCallback() {
    this.filterOptions = {
      searchKeyword: '',
      saleType: '',
      priceFilter: '',
      attributes: '',
    };
    this.sortOptions = {
      priceSort: 1,
    };
    if (this.isAttrFilter) {
      this.isAttrFilter = false;
      this.router.navigate(['/nft-details', this.collectionId]);
    }
    this.getNfts(this.collectionId, this.filterOptions, this.sortOptions);
  }
}
