<app-loader *ngIf="pageLoader"></app-loader>
    <div id="wrapper" *ngIf="!pageLoader">
        <app-nav-bar></app-nav-bar>
        <!-- content begin -->
        <!-- <div class="no-bottom" id="content">
            <div id="top"></div> -->

            <section class="top-collections collections-page">
                <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d_profile de-flex">
                                <div class="de-flex-col">
                                    <div class="profile_avatar">
                                        <img src="{{user.profileImage ? user.profileImage : 'assets/images/author_single/author_thumbnail.jpg' }} "
                                            alt="">
                                        <div class="profile_name d-wallet">
                                            <h4>
                                                {{user.name}}
                                                <span class="profile_username">{{user.userName}}</span>
                                                <span id="wallet"
                                                    class="profile_wallet d-wallet-address">{{user.walletAddress}}</span>
                                                <button (click)="copyUserWalletAddress()" id="btn_copy"
                                                    title="Copy Text">Copy</button>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>{{user.bio}}</p>
                        </div>

                        <div class="col-md-12">
                            <div class="de_tab tab_simple">

                                <ul class="tab-menu">
                                    <li class="mr-2">
                                        <span (click)="setActiveMenu(0)" [ngClass]="{'active' : activeMenu == 0}">
                                            Owned ({{usersTokenData?.length}})
                                        </span>
                                    </li>
                                    <li class="mr-2">
                                        <span (click)="setActiveMenu(1)" [ngClass]="{'active' : activeMenu == 1}">
                                            NFT Launchpad ({{privateSalePagination.count}})
                                        </span>
                                    </li>
                                    <li class="mr-2">
                                        <span (click)="setActiveMenu(2)" [ngClass]="{'active' : activeMenu == 2}">
                                            On Sale ({{onSaleTotalCount}})
                                        </span>
                                    </li>
                                </ul>

                                <div class="mt-5">
                                    <div *ngIf="nftLoader" class="loader-section">
                                        <span class="absolute spinner-color spinner-border spinner-border-md mr-1" role="status"></span>    
                                    </div>
                                    <div *ngIf="activeMenu == 0 && !nftLoader"  class="trending-nft pb-0">
                                        <div *ngIf="userOwnedNFTs?.length == 0" class="no-image-section text-center">
                                            <img src="assets/images/no-image.svg"
                                                class="no-items-img mx-auto inline-block" alt="">
                                            <p class="text-xl fw-semibold inline-block">No items to display
                                            </p>
                                        </div>
                                        <div class="row nft-details-row pb-0 profile-onsale">
                                            <!-- nft item begin -->
                                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                                                *ngFor="let nft of userOwnedNFTs">
                                                <div class="nft__item">
                                                    <div class="nft-common-details"> 
                                                        <div class="nft__item_wrap">

                                                            <div [class.loading]="loading" class="w-100">
                                                                <span *ngIf="loading"
                                                                    class="absolute spinner-color spinner-border spinner-border-md mr-1"
                                                                    role="status"></span>
                                                                    <div class="card-img">
                                                                        <img *ngIf="!nft.isNft"
                                                                            src='{{nft?.nftImage ? (nft?.nftImage?.includes("ipfs") ? nft.nftImage : nft.baseUrl+nft.nftImage) : "assets/images/profile.png"}}'
                                                                            onerror="this.src='assets/images/profile.png'"
                                                                            alt="BX-nft" class="nft__item_preview"
                                                                            (loaded)="loading = false">
                                                                        <img *ngIf="nft.isNft"
                                                                            [src]="[nft.nftImageS3 ? nft?.nftImageS3 : nft?.nftCollection?.baseUrl + nft.nftImage]"
                                                                            onerror="this.src='assets/images/profile.png'"
                                                                            alt="BX-nft" class="nft__item_preview"
                                                                            (loaded)="loading = false">

                                                                        <div class="price-div" *ngIf="nft?.nftCollection?.isApproved == 1 || nft?.isApproved == 1"> 
                                                                            
                                                                            <button class="btn-style-one" data-bs-toggle="modal"
                                                                                data-bs-target="#putForSaleModal" type="button"
                                                                                data-modal-toggle="defaultModal"
                                                                                (click)="showPutForSale(nft)"
                                                                                *ngIf="!nft.isOtherCollection && isOwnProfile && nft.forSale == 0">Put
                                                                                for sale</button>                                                                                
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="nft__item_info">
                                                            <a>
                                                                    <h3 class="text-truncate" title="{{nft?.name}} #{{nft.tokenId}}">{{nft?.name}} #{{nft.tokenId}}</h3>
                                                                    <h4>{{nft?.collectionName}}</h4>                      
                                                            </a>
                                                            <div class="collection">
                                                                <h6>Collection</h6>
                                                                <h4 class="text-truncate" title="{{nft?.nftCollection?.collectionName}}">{{nft?.nftCollection?.collectionName}}</h4>
                                                                
                                                            
                                                                </div>                                    
                                                            <hr />
                                                        </div>
                                                        <div class="nft__item_price buy-details"
                                                                *ngIf="nft?.nftCollection?.isApproved != 1 && nft?.isApproved != 1"
                                                                tooltip="The collection of this NFT is not present in BNB NFT Marketplace yet and just present on BNB Chain. Please ask the owner of the collection to join as a Partner and create a collection for this NFT for holders to Buy/Sell.">
                                                                <p class="warning-msg d-flex mb-0">
                                                                    <img src="assets/icons/warning.png" class="warning-img"
                                                                        alt="BX-NFT" />
                                                                    Why can't put this NFT to sale?
                                                                </p>

                                                            </div>
                                                        
                                                        <div class="buy-details" *ngIf="nft?.nftCollection?.isApproved == 1 || nft?.isApproved == 1">
                                                            
                                                            <div class="floor-section d-flex justify-content-between">
                                                                <span class="">Current Bid</span>
                                                                <div class="d-flex gap-2">
                                                                <img src="assets/images/icons/bnb.svg"
                                                                    class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                                                <span
                                                                    class="truncate lg:text-clip floor-price-text">{{nft.price}} <span>BNB</span></span>
                                                                </div>
                                                            </div>

                                                            <div class="nft__item_price"
                                                                *ngIf="nft?.nftCollection?.isApproved != 1 && nft?.isApproved != 1"
                                                                tooltip="The collection of this NFT is not present in BNB NFT Marketplace yet and just present on BNB Chain. Please ask the owner of the collection to join as a Partner and create a collection for this NFT for holders to Buy/Sell.">
                                                                <p class="warning-msg d-flex">
                                                                    <img src="assets/icons/warning.png" class="warning-img"
                                                                        alt="BX-NFT" />
                                                                    Why can't put this NFT to sale?
                                                                </p>

                                                            </div>
                                        
                                                            
                                                        </div>
                                                        <!-- <div class="nft__item_info">
                                                            <a>
                                                                <h5>{{nft?.nftCollection?.collectionName}}</h5>
                                                                <h4>{{nft?.collectionName}}</h4>
                                                                <h3 class="text-truncate"
                                                                    title="{{nft?.name}} #{{nft.tokenId}}">{{nft?.name}}
                                                                    #{{nft.tokenId}}</h3>
                                                            </a>
                                                            <div class="nft__item_price"
                                                                *ngIf="nft?.nftCollection?.isApproved != 1 && nft?.isApproved != 1"
                                                                tooltip="The collection of this NFT is not present in BNB NFT Marketplace yet and just present on BNB Chain. Please ask the owner of the collection to join as a Partner and create a collection for this NFT for holders to Buy/Sell.">
                                                                <p class="warning-msg d-flex">
                                                                    <img src="assets/icons/warning.png" class="warning-img"
                                                                        alt="BX-NFT" />
                                                                    Why can't put this NFT to sale?
                                                                </p>

                                                            </div>
                                                            <div class="d-flex view-btn"
                                                                *ngIf="nft?.nftCollection?.isApproved == 1 || nft?.isApproved == 1">
                                                                <div class="nft__item_price">
                                                                    {{nft.price}} BNB
                                                                </div>
                                                                <button class="btn-main text-them" data-bs-toggle="modal"
                                                                    data-bs-target="#putForSaleModal" type="button"
                                                                    data-modal-toggle="defaultModal"
                                                                    (click)="showPutForSale(nft)"
                                                                    *ngIf="!nft.isOtherCollection && isOwnProfile && nft.forSale == 0">Put
                                                                    for sale</button>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div *ngIf="activeMenu == 1">
                                        <div *ngIf="privateSale?.length == 0" class="no-image-section text-center">
                                            <img src="assets/images/no-image.svg"
                                                class="no-items-img mx-auto inline-block" alt="">
                                            <p class="text-xl fw-semibold inline-block">No items to display
                                            </p>
                                        </div>
                                        <div class="row nft-details-row pb-0 profile-onsale">

                                            <!-- nft item begin -->
                                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                                                *ngFor="let nft of privateSale">
                                                <div class="nft__item">
                                                    <div class="nft-common-details"> 
                                                        <div class="nft__item_wrap">
                                                            <a>                                                               
                                                                    <div class="card-img">
                                                                        <img src="{{nft.collectionImage}}"
                                                                        class="lazy nft__item_preview" alt="">
                                                                    </div>
                                                            </a>
                                                        </div>
                                                        <div class="nft__item_info">
                                                            <a>
                                                                <h4>{{nft?.collectionName}}</h4>
                                                            </a>
                                                        </div>
                                                            <hr />
                                                            <div class="buy-details">                                                            
                                                                <div class="floor-section d-flex justify-content-between">
                                                                    <span class="">Current Bid</span>
                                                                    <div class="d-flex gap-2">
                                                                    <img src="assets/images/icons/bnb.svg"
                                                                        class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                                                    <span
                                                                        class="truncate lg:text-clip floor-price-text">{{nft.totalNft * nft.price}} <span>BNB</span> / {{nft.totalNft}}</span>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                            <!-- <div class="nft__item_price">
                                                                {{nft.totalNft * nft.price}}
                                                                BNB<span>/ {{nft.totalNft}}</span>
                                                            </div> -->
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div *ngIf="activeMenu == 2" class="trending-nft pb-0">
                                        <div *ngIf="onSaleNFT?.length == 0" class="no-image-section text-center">
                                            <img src="assets/images/no-image.svg"
                                                class="no-items-img mx-auto inline-block" alt="">
                                            <p class="text-xl fw-semibold inline-block">No items to display
                                            </p>
                                        </div>
                                        <div class="row nft-details-row pb-0 profile-onsale">
                                            <!-- nft item begin -->
                                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12" *ngFor="let saleNft of onSaleNFT">
                                                <div class="nft__item">
                                                    <div class="nft-common-details">  
                                                        <div class="nft__item_wrap">
                                                            <div [class.loading]="loading" class="w-100">
                                                                <span *ngIf="loading"
                                                                    class="absolute spinner-color spinner-border spinner-border-md mr-1"
                                                                    role="status"></span>
                                                                <!-- <img [src]="[saleNft.nftImageS3 ? saleNft?.nftImageS3 : saleNft?.nftCollection?.baseUrl + saleNft.nftImage]"
                                                                    class="nftImage rounded-t-xl" alt="BX-nft"
                                                                    (loaded)="loading = false"> -->

                                                                
                                                                <div class="card-img">
                                                                    <img [src]="[saleNft.nftImageS3 ? saleNft?.nftImageS3 : saleNft?.nftCollection?.baseUrl + saleNft.nftImage]"
                                                                    class="nft__item_preview" alt="BX-nft"
                                                                    (loaded)="loading = false">

                                                                       

                                                                        <!-- <div class="buy-details" *ngIf="nft.saleType != 'Buy'">
                                                                            <div class="price-div">
                                                                                <a class="btn-style-one" title="{{nft.price}} BNB" 
                                                                                    *ngIf="(checkIfBidEnded(nft.bidStartTime) && !checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                                                            
                                                                                    Bid Now
                                                                                </a>
                                                                                <a class="btn-style-one" title="{{nft.price}} BNB" 
                                                                                    *ngIf="(checkIfBidEnded(nft.bidStartTime) && nft.saleType === 'Bid')">
                                                                                    Offer Now
                                                                                </a>
                                                                            </div>
                                                                        </div>-->
                                                                        <div class="buy-details" *ngIf="isOwnProfile">
                                                                            <div class="price-div">                                       
                                                                                <button type="button" class="btn-style-one" data-bs-toggle="modal"
                                                                                data-bs-target="#openRemoveModal" data-modal-toggle="defaultModal" (click)="showRemoveSaleModal(saleNft)">Remove Sale</button>
                
                                                                                
                                                                            </div>
                                                                        </div> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="nft__item_info">
                                                                <a>
                                                                        <h3 class="text-truncate" title="{{saleNft?.name}} #{{saleNft.tokenId}}">{{saleNft?.name}} #{{saleNft.tokenId}}</h3>
                                                                                            
                                                                </a>
                                                                <div class="collection">
                                                                    <h6>Collection</h6>
                                                                    <h4 class="text-truncate" title="{{saleNft?.nftCollection?.collectionName}}">{{saleNft?.nftCollection?.collectionName}}</h4>
                                                                    
                                                                
                                                                    </div>                                    
                                                            <hr />
                                                        </div>
                                                        <div class="buy-details">
                                                            
                                                            <div class="floor-section d-flex justify-content-between">
                                                                <span class="">Current Bid</span>
                                                                <div class="d-flex gap-2">
                                                                <img src="assets/images/icons/bnb.svg"
                                                                    class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                                                <span
                                                                    class="truncate lg:text-clip floor-price-text">{{saleNft.price}} <span>BNB</span></span>
                                                                </div>
                                                            </div>
                                        
                                                            
                                                        </div>
                                                        <!-- <div class="nft__item_info">
                                                            <a>
                                                                <h4>{{saleNft?.nftCollection?.collectionName}}</h4>
                                                                <h3>{{saleNft?.name}} #{{saleNft.tokenId}}</h3>
                                                            </a>
                                                            <div class="nft__item_price">
                                                                {{saleNft.price}} BNB
                                                            </div>
                                                            <div class="col-span-6" *ngIf="isOwnProfile">
                                                                <button type="button" class="btn-main text-them" data-bs-toggle="modal"
                                                                data-bs-target="#openRemoveModal" data-modal-toggle="defaultModal" (click)="showRemoveSaleModal(saleNft)">Remove Sale</button>

                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
            </section>


        <!-- </div> -->
        <!-- content close -->

        <!-- BEGIN: Put for sale -->
        
                    <app-put-for-sale [user]="user" [nft]="nft"></app-put-for-sale>
              
        <!-- END: Put for sale Modal -->

        <!-- BEGIN: Remove sale Modal -->
    <div class="modal fade" id="openRemoveModal" tabindex="-1" aria-labelledby="openRemoveModal" aria-hidden="true">
        <div class="openRemoveModalDiv">
            <div class="modal-dialog modal-sm">
                <div class="modal-content remove-modal text-center">
                    <div class="modal-title">
                        <h4 class="">Are you sure want to remove sale?</h4>
                    </div>
                    <div class="modal-body">
                        <div class="remove-modal-section d-flex justify-content-between gap-4">
                            <button class="text-center minw-40 btn-style-yellow btn-no" data-bs-dismiss="modal" aria-label="Close"
                                #closeRemoveModal>No</button>
                            <button [disabled]="isRemoveSale" class="minw-40 btn-yes btn-style-yellow text-center"
                                (click)="removeSale(nft.saleType)">
                                <span *ngIf="!isRemoveSale">Yes</span>
                                <span *ngIf="isRemoveSale">Processing...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- END: Remove sale Modal -->
        <app-footer></app-footer>
    </div>