import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BannerService } from 'src/app/services/banner.service';
import { Title } from '@angular/platform-browser';
import { CollectionService } from 'src/app/services/collection.service';

@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.css'],
})

/**
 * Component
 */
export class CollectionDetailsComponent implements OnInit {
  public collections:any = [];
  public pagination:any = {};
  public categorySearch:string = '';
  public categoriesFilter:any = [];
  public partnerFilter:any = [];
  public banners:any = [];
  public initialLoader:boolean = true;
  public collectionLoader:boolean = false;
  public loader:boolean = false;
  public pageNumber:number = 1;
  public isSearch: boolean = false;
  public searchPagination: number = 1;
  public isloadMore : boolean = false;
  public pageLoader:boolean = false;


  /**
   * constructor
   */
  constructor(
    private toastr: ToastrService,
    private collectionService: CollectionService,
    private bannerService: BannerService,
    private titleService:Title,
  ) {
    this.titleService.setTitle('Collections');
    this.pageLoader = true;
  }


  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.isSearch = false;
    this.getCollections();
    this.getBanners();
  }

  /**
   * after view init
   */
  public ngAfterViewInit() {
    this.initialLoader = false;
  }

  /**
 * get collections
 * @param {any} options
 */
  public getCollections(options:any = {}) {
    if (options.limit == undefined) {
      this.loader = true;
    }
    window.scroll(0, 0);
    this.collectionService.getAllCollections(options)
        .subscribe({
          next: (response:any) => {
            this.collectionLoader = false;
            if (this.isSearch) {
              this.collections = response['data'];
              if (this.collections.length > 0) {
                if (response['results'].next_page) {
                  this.searchPagination = response['results'].next_page.page;
                }
              }
            } else {
              this.collections.push(...response['data']);
              this.collections = this.collections.filter((value:any, index:any, self:any) =>
                index === self.findIndex((t:any) => (
                  t._id === value._id
                )),
              );
            }
            this.pagination = response['results'];
            if (this.pagination.total_page > 1) {
              this.isloadMore = true;
            }
            if (this.pageNumber < this.pagination?.total_page) {
              this.isloadMore = true;
            } else {
              this.isloadMore = false;
            }
            this.loader = false;
            this.pageLoader = false;
          },
          error: (error) => {
            this.loader = false;
            this.pageLoader = false;
            this.toastr.error(error.message, 'Failed to fetch collections');
          },
        });
  }

  /**
   * On Load More event
   *
   */
  public loadMore() {
    this.isSearch = false;
    if (this.pagination?.total_page > this.pageNumber && this.pagination?.next_page.page) {
      this.pageNumber = this.pagination?.next_page?.page;
      this.collectionLoader = true;
      setTimeout(() => {
        this.getCollections({ page: this.pagination?.next_page?.page, limit: this.pagination?.next_page?.limit, categories: this.categoriesFilter, categorySearch: this.categorySearch, partner: this.partnerFilter });
      }, 1000);
    }
  }


  /**
   * on partner select
   *
   * @param {any} partner
   */
  public onPartnerSelect(partner:any) {
    this.partnerFilter = partner;
    this.isSearch = true;
    this.pageNumber = 1;
    this.pagination = {
      'next_page': {
        'page': 1,
        'limit': '10',
      },
      'total_page': this.pagination?.total_page,
    };
    this.getCollections({ page: this.pagination?.next_page?.page, limit: this.pagination?.next_page?.limit, categories: this.categoriesFilter, categorySearch: this.categorySearch, partner: this.partnerFilter });
  }

  /**
   * On category search
   *
   * @param {string} keyword
   */
  public onCategorySearch(keyword:string) {
    this.isSearch = true;
    this.categoriesFilter = keyword;
    this.pageNumber = 1;
    this.pagination = {
      'next_page': {
        'page': 1,
        'limit': '10',
      },
      'total_page': this.pagination?.total_page,
    };
    this.getCollections({ page: this.pagination?.next_page?.page, limit: this.pagination?.next_page?.limit, categories: this.categoriesFilter, categorySearch: this.categorySearch, partner: this.partnerFilter });
  }

  /**
   * On category search
   *
   * @param {string} keyword
   */
  public onCollectionSearch(keyword:string) {
    this.isSearch = true;
    this.categorySearch = keyword;
    this.pageNumber = 1;
    this.pagination = {
      'next_page': {
        'page': 1,
        'limit': '10',
      },
      'total_page': this.pagination?.total_page,
    };
    this.getCollections({ page: this.pagination?.next_page?.page, limit: this.pagination?.next_page?.limit, categories: this.categoriesFilter, categorySearch: this.categorySearch, partner: this.partnerFilter });
  }
  /**
   * Get collections banners
   */
  public getBanners() {
    this.bannerService.getCollectionBanners()
        .subscribe({
          next: (response: any) => {
            this.banners = response['data'];
            this.pageLoader = false;
          },
          error: (error:any) => {
            this.pageLoader = false;
            this.toastr.error(error.message, 'Failed to fetch banners');
          },
        });
  }
}
