import { Component, OnInit } from '@angular/core';
import { StorageService } from 'ng-blockchainx';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { TrendingNftsService } from 'src/app/services/trending-nfts.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CollectionService } from 'src/app/services/collection.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

/**
 * Component
 */
export class HomeComponent implements OnInit {
  public account:any = {};
  public user: any = {};
  public nfts: any = [];
  public obj: any;
  public blogPost: OwlOptions = {
    loop: true,
    margin: 0,
    autoplay: true,
    // autoplayTimeout: 4000,
    // smartSpeed: 400,
    center: true,
    dots: false,
    nav: false,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
      1600: {
        items: 3,
        margin: 0,
      },
    },
  };
  public collections: any;
  public getNftResponse: boolean = false;
  public pageLoader:boolean = false;


  /**
   * Constructor
   */
  constructor(
    private accountService: AccountService,
    private storageService: StorageService,
    private trendingNfts: TrendingNftsService,
    private toastr: ToastrService,
    private collectionService:CollectionService,
  ) {
    this.pageLoader = true;
  }

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.account = this.storageService.get('auth');
    this.user = this.storageService.get('user');
    this.getTrendingNfts();
    this.topCollections();
  }

  /**
   * Get Trending Nfts
   */
  public getTrendingNfts() {
    this.getNftResponse = true;
    this.trendingNfts.getTrendingNfts().subscribe({
      next: (response: any) => {
        this.nfts = [...response['data']];
        this.getNftResponse = false;
        this.pageLoader = false;
      },
      error: (error : any) => {
        this.pageLoader = false;
        this.getNftResponse = false;
        // this.toastr.error(error.message, 'Failed to fetch trending nfts');
      },
    });
  }

  /**
   * Get Top Collections
   */
  topCollections() {
    this.collectionService.getTopCollections().subscribe({
      next: (response:any) => {
        this.collections = response['data'];
        this.pageLoader = false;
      },
      error: (error) => {
        this.toastr.error(error.message, 'Failed to fetch collections');
        this.pageLoader = false;
      },
    });
  }
}
