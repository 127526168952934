import { Component, HostListener, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { CollectionService } from 'src/app/services/collection.service';

@Component({
  selector: 'app-hot-collections',
  templateUrl: './hot-collections.component.html',
  styleUrls: ['./hot-collections.component.css'],
})

/**
 * Export Class
 */
export class HotCollectionsComponent implements OnInit {
  public collections:any = [];
  public collection:any = {};
  public innerWidth!:number;
  public cell!: number;
  public getCollectionsResponse: boolean = false;
  public blogPost: OwlOptions = {
    loop: false,
    margin: 24,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      600: {
        items: 2,
        margin: 20,
      },
      1000: {
        items: 3,
        margin: 24,
      },
      1600: {
        items: 3,
        margin: 40,
      },
    },
  };
  /**
   * Creates an instance of top collections component.
   */
  constructor( private toastr: ToastrService,
    private collectionService: CollectionService) { }

  /**
   * on init
   */
  ngOnInit(): void {
    this.topCollections();
    this.onResize();
  }
  /**
   * Get Top Collections
   */
  topCollections() {
    this.getCollectionsResponse = true;
    this.collectionService.getTopCollections().subscribe({
      next: (response:any) => {
        this.collections = response['data'];
        this.getCollectionsResponse = false;
      },
      error: (error) => {
        this.getCollectionsResponse = false;
      },
    });
  }
  /**
   * window resize
   */
    @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth=window.innerWidth;

    if (this.innerWidth <= 640) {
      this.cell = 1;
    } else if (this.innerWidth <= 991) {
      this.cell = 2;
    } else if (this.innerWidth <= 1199) {
      this.cell = 3;
    } else {
      this.cell = 3;
    }
  }
}
