import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Web3 from 'web3';
import { environment } from 'src/environments/environment';

const web3 = new Web3(environment.provider);
const apiUrl = environment.api_url;


@Injectable({
  providedIn: 'root',
})

/**
 * Export Class
 */
export class AuthService {
  /**
   * Creates an instance of auth service.
   */
  constructor(private http: HttpClient) { }

  /**
   * Checks register
   * @param {string} account
   * @return {httpresponse}
   */
  checkRegister(account: string) {
    return this.http.post(`${apiUrl}/api/v1/get-user/wallet-address`, { walletAddress: account });
  }

  /**
   * Checksums address
   * @param {string} address
   */
  async checksumAddress(address: string) {
    return await web3.utils.toChecksumAddress(address);
  }

  /**
   * Registers auth service
   * @param {any} details
   * @return  {httpresponse}
   */
  register(details: any) {
    return this.http.post(`${apiUrl}/api/v1/add-user/`, details);
  }

  /**
   * Checks user name
   * @param {string} username
   * @param {string} id
   * @return {httpresponse}
   */
  checkUserName(username: string, id: string = '') {
    const val = id !== '' ? this.http.get(`${apiUrl}/api/v1/validate-user-username/${username}?id=${id}`) : this.http.get(`${apiUrl}/api/v1/validate-user-username/${username}`);
    return val;
  }

  /**
   * Checks user email
   * @param {string} useremail
   * @param {string} id
   * @return {httpresponse}
   */
  checkUserEmail(useremail: string, id: string = '') {
    return id !== '' ? this.http.get(`${apiUrl}/api/v1/validate-user-email/${useremail}?id=${id}`) : this.http.get(`${apiUrl}/api/v1/validate-user-email/${useremail}`);
  }
}
