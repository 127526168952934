import { Pipe, PipeTransform } from '@angular/core';
import { CommonHelper } from 'src/app/helpers/common.helper';
import * as moment from 'moment';
@Pipe({
  name: 'timer',
})

/**
 * Export Class
 */
export class TimerPipe implements PipeTransform {
  timer: NodeJS.Timeout | undefined;

  /**
   * Transform
   * @param {any} nft
   * @return {any}
   */
  transform(nft: any): any {
    this.timer = setInterval(() => {
      CommonHelper.getTimerForSale(moment(nft.bidEndTime).unix());
    }, 1000);

    // }
    return this.timer;
  }

  /**
   * Check if bid is ended
   *
   * @param {string} date
   * @return {boolean}
   */
  public checkIfBidEnded(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }

  /**
 * Check if bid is started
 *
 * @param {string} date
 * @return {boolean}
 */
  public checkIfBidStarted(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }

  /**
   * destructor for class
   */
  ngOnDestroy(): void {
    clearInterval(this.timer);
  }
}
