import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs';
import { StorageService } from 'ng-blockchainx';
const apiUrl = environment.api_url;

interface BuyPrivateSaleNftParam {
  userId: string;
  collectionId: string | null;
  nftCount: number;
  totalPrice: string;
  txhash: string;
}


@Injectable({
  providedIn: 'root',
})
/**
 * Export Class
 */
export class PrivateSaleService {
  /**
   * Creates an instance of private sale service.
   */
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private commonService: CommonService,
  ) { }

  /**
   * Buys private sale nft
   * @param {BuyPrivateSaleNftParam} reqBody
   * @return {httpResponse}
   */
  public buyPrivateSaleNft(reqBody: BuyPrivateSaleNftParam) {
    const account: any = this.storage.get('auth');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + account.token,
    });
    return this.http.post(`${environment.api_url}/api/v1/buy-private-sale-nft`, reqBody, { headers: reqHeader })
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get collections list
   * @param { number } page - page number to query
   * @param {number} limit - limits per page to query
   * @return {HttpResponse}
   */
  public getAllCollections({ page = 1, limit = 5, partner=[], categories=[], categorySearch='' }: any) {
    let collectionName = categorySearch.replace('#', encodeURIComponent('#'));
    collectionName = collectionName.replace('&', encodeURIComponent('&'));
    return this.http.get(`${apiUrl}/api/v1/filter-private-sale?partner=${partner?.join(',')}&category=${categories?.join(',')}&collectionName=${collectionName}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get collection details
   * @param {string} id - collection id
   * @return {HttpResponse}
   */
  public getCollection(id: string | null) {
    return this.http.get(`${apiUrl}/api/v1/private-sale/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get nft list of a collection
   * @param {string} id - collection id
   * @return {HttpResponse}
   */
  public getCollectionNfts(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-nfts-by-collection/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }
}
