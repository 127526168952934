import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Account Service
 */
export class AccountService {
  public account: any;
  public openWallet:any;
  public searchFilter:any;
  public openWalletObserve : any;
  public searchFilterObserve: any;
  public accountObserve: Observable<{
    walletAddress: string
  }>;
  public acceptBid: any;
  public acceptBidService: Observable<{
    status: boolean
  }>;
  /**
   * Initial Loader
   */
  constructor() {
    this.account = new BehaviorSubject({
      walletAddress: '',
    });
    this.searchFilter = new BehaviorSubject({});
    this.searchFilterObserve = this.searchFilter.asObservable();
    this.accountObserve = this.account.asObservable();
    this.openWallet = new BehaviorSubject({
      status: '',
    });
    this.openWalletObserve = this.openWallet.asObservable();
    this.acceptBid = new BehaviorSubject({
      status: '',
    });
    this.acceptBidService = this.acceptBid.asObservable();
  }

  /**
   * Updates total data's length while search
   * @param {number} data
   */
  public updateSearchFilter(data :{
    length:number,
  }) {
    this.searchFilter.next(data);
  };
  /**
   *
   * @param {any} data
   */
  public setAccount(data: {
    walletAddress: string
  }) {
    this.account.next(data);
  };
  /**
   *
   * @param {any} data
   */
  public openWalletModal(data: {
    status:boolean;
  }) {
    this.openWallet.next(data);
  };

  /**
   *
   * @param {any} data
   */
  public updateAcceptBid(data: {
    status:boolean;
  }) {
    this.acceptBid.next(data);
  };

  /**
   * Get Wallect Connect Type
   * @return {boolean}
   */
  public walletConnectType() {
    const wallectConnect = localStorage.getItem('walletconnect');
    let type = false;
    if (wallectConnect != '' && wallectConnect != null && wallectConnect != undefined) {
      type = true;
    }
    return type;
  }
}
