import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrendingsRoutingModule } from './trendings-routing.module';
import { HotBidsComponent } from './hot-bids/hot-bids.component';
import { TopCollectionsComponent } from './top-collections/top-collections.component';
import { TopPartnersComponent } from './top-partners/top-partners.component';
import { SharedModule } from '../shared/shared.module';
import { TrendingsComponent } from './trendings.component';

@NgModule({
  declarations: [
    HotBidsComponent,
    TopCollectionsComponent,
    TopPartnersComponent,
    TrendingsComponent,
  ],
  imports: [
    CommonModule,
    TrendingsRoutingModule,
    SharedModule,
  ],
})

/**
 * Export Class
 */
export class TrendingsModule { }
