<app-loader *ngIf="pageLoader"></app-loader>
    <div id="wrapper" *ngIf="!pageLoader">
        <app-nav-bar></app-nav-bar>


        <!-- content begin -->
        <div class="no-bottom no-top" id="content">
            <div id="top"></div>

            <!-- section begin -->
            <section id="profile_banner" style="background: url({{collection?.coverPhoto}})" aria-label="section"
                class="text-light">

            </section>
            <!-- section close -->


            <section aria-label="section" class="d_coll no-top nft-details-page">
                <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d_profile">
                                <div class="profile_avatar">
                                    <div class="d_profile_img">
                                        <img [src]="collection.collectionImage ? collection.collectionImage : 'assets/images/author/author-1.jpg'" alt="Profile">
                                    </div>

                                    <div class="profile_name">
                                        <div class="text-center title-wrap pb-0">
                                            <h3>
                                                {{collection?.collectionName}}</h3>
                                            <p class="pb-0">by <a
                                                    [routerLink]="['/partner', collection?.partner?._id]">
                                                    {{collection?.partner?.name}}</a> </p>
                                            <p>{{collection?.description}}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div class="row details-section">
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="inner-content">
                                <img src="assets/images/discover-nft/single-collection/icons/items.png"
                                    class="milestone__img" alt="BX-nft">
                                <p class="milestone__label">Items</p>
                                <h4 class="milestone__value">{{collection?.result?.items | shortNumber}}</h4>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="inner-content">
                                <img src="assets/images/discover-nft/single-collection/icons/floor-price.png"
                                    class="milestone__img" alt="BX-nft">
                                <p class="milestone__label">Floor Price</p>
                                <h4 title="{{collection?.result?.floor_price}}" class="milestone__value" *ngIf="collection?.result?.floor_price < 1000">
                                    {{collection?.result?.floor_price | number:'1.2-2'}} BNB</h4>
                                <h4 title="{{collection?.result?.floor_price}}" class="milestone__value" *ngIf="collection?.result?.floor_price >= 1000">
                                    {{collection?.result?.floor_price | shortNumber}} BNB</h4>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="inner-content">
                                <img src="assets/images/discover-nft/single-collection/icons/market-cap.png"
                                    class="milestone__img" alt="BX-nft">
                                <p class="milestone__label">Market Cap</p>
                                <h4 title="{{collection?.result?.market_cap}}" class="milestone__value" *ngIf="collection?.result?.market_cap < 1000">
                                    {{collection?.result?.market_cap | number:'1.2-2'}} BNB</h4>
                                <h4 title="{{collection?.result?.market_cap}}" class="milestone__value" *ngIf="collection?.result?.market_cap >= 1000">
                                    {{collection?.result?.market_cap | shortNumber}} BNB</h4>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="inner-content">
                                <img src="assets/images/discover-nft/single-collection/icons/owners.png"
                                    class="milestone__img" alt="BX-nft">
                                <p class="milestone__label">Owners</p>
                                <h4 class="milestone__value">{{collection?.result?.total_owners_count | shortNumber}}
                                </h4>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="inner-content">
                                <img src="assets/images/discover-nft/single-collection/icons/highest-sale.png"
                                    class="milestone__img" alt="BX-nft">
                                <p class="milestone__label">Highest Sale</p>
                                <h4 title="{{collection?.result?.highest_sale}}" class="milestone__value" *ngIf="collection?.result?.highest_sale < 1000">
                                    {{collection?.result?.highest_sale | number:'1.2-2'}} BNB</h4>
                                <h4 title="{{collection?.result?.highest_sale}}" class="milestone__value" *ngIf="collection?.result?.highest_sale >= 1000">
                                    {{collection?.result?.highest_sale | shortNumber}} BNB</h4>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <div class="inner-content">
                                <img src="assets/images/discover-nft/single-collection/icons/total-volume.png"
                                    class="milestone__img" alt="BX-nft">
                                <p class="milestone__label">Total Volume</p>
                                <h4 title="{{collection?.result?.total_volume}}" class="milestone__value" *ngIf="collection?.result?.total_volume < 1000">
                                    {{collection?.result?.total_volume | number:'1.2-2'}} BNB</h4>
                                <h4 title="{{collection?.result?.total_volume}}" class="milestone__value" *ngIf="collection?.result?.total_volume >= 1000">
                                    {{collection?.result?.total_volume | shortNumber}} BNB</h4>
                            </div>
                        </div>

                    </div>
                    <app-nft-filters [nfts]="nfts" (attributeFilterCallback)="attributeFilterCallback($event)"
                        (priceFilterCallback)="priceFilterCallback($event)"
                        (priceSortCallback)="priceSortCallback($event)"
                        (saleTypeFilterCallback)="saleTypeFilterCallback($event)"
                        (searchCallback)="searchByNameCallback($event)"
                        (clearFilterCallback)="clearFilterCallback()"
                        [isAttrFilter]="isAttrFilter"
                    >
                    </app-nft-filters>
                    <div *ngIf="nfts.length > 0">
                        <app-nft-lists [nfts]="nfts" [collection]="collection"></app-nft-lists>
                    </div>
                    <div *ngIf="nfts.length == 0" class="no-image">
                        <img src="assets/images/no-image.svg" class="" alt="BX-nft">
                        <p class="flex justify-center">No Items found</p>
                    </div>
                </div>

            </section>


        </div>
        <!-- content close -->

        <app-footer></app-footer>
    </div>