    <div id="wrapper">
        <app-nav-bar></app-nav-bar>
        <!-- content begin -->
        <!-- <div class="no-bottom no-top" id="content">
            <div id="top"></div> -->

            <!-- section begin -->
            <!-- <section id="subheader" class="text-light background-img">
                <div class="center-y relative text-center">
                    <div class="container">
                        <div class="bg-gradient-1 bg-right-top"></div>
                        <div class="bg-gradient-2 bg-right-top"></div>
                        <div class="bg-gradient-1 bg-left-bottom"></div>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h1>{{blogDetails?.title}}</h1>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section> -->
            <!-- section close -->

            <!-- section begin -->
    <section aria-label="section" class="list-section">
                <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
                    <div class="text-center title-wrap">
                        <h2>{{blogDetails?.title}}</h2>
                        <!-- <p>Latest Blog & News</p> -->
                    </div>
                    <div class="row">
                        <div id="blogContent"></div>

                        <div class="spacer-single"></div>
                    </div>
                </div>
            </section>

        <app-footer></app-footer>

    </div>