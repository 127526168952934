<div class="trending-nft">
<div class="row nft-details-row">
    <!-- nft item begin -->
    <div *ngFor="let nft of nfts; let i = index;" class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <div class="d-item">
            <div class="nft__item" [routerLink]="['/single-nft-details', nft._id]">
                <div class="nft-common-details">    
                    <div class="nft__item_wrap">
                        <div class="card-img">
                            <img [src]="[nft.nftImageS3 ? (nft.nftImageS3 ? nft.nftImageS3 : nft.nftCollection.baseUrl + nft.nftImage) : 'assets/images/collections/coll-item-1.jpg']"
                                class="lazy nft__item_preview" alt="">

                                <!-- <a [routerLink]="['/single-nft-details']" class="btn-style-one">
                                    Buy Now
                                </a> -->

                                <div class="buy-details" *ngIf="nft.saleType != 'Buy'">
                                    <div class="price-div">
                                        <a class="btn-style-one" title="{{nft.price}} BNB" 
                                            *ngIf="(checkIfBidEnded(nft.bidStartTime) && !checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                    
                                            Bid Now
                                        </a>
                                        <a class="btn-style-one" title="{{nft.price}} BNB" 
                                            *ngIf="(checkIfBidEnded(nft.bidStartTime) && nft.saleType === 'Bid')">
                                            Offer Now
                                        </a>
                                    </div>
                                </div>
                                <div class="buy-details" *ngIf="nft.saleType == 'Buy'">
                                    <div class="price-div">                                       
                                        <a class="btn-style-one" title="{{nft.price}} BNB" >
                                            Buy Now
                                        </a>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="nft__item_info">
                            <a [routerLink]="['/single-nft-details']">
                                     <h3 class="text-truncate" title="{{nft?.name}} #{{nft.tokenId}}">{{nft?.name}} #{{nft.tokenId}}</h3>
                                                          
                            </a>
                            <div class="collection">
                                <h6>Collection</h6>
                                <h4 class="text-truncate" title="{{nft?.nftCollection?.collectionName}}">{{nft?.nftCollection?.collectionName}}</h4>
                                  
                               
                                </div>                                    
                        <hr />
                    </div>
                </div>
                <div class="buy-details" *ngIf="nft.saleType != 'Buy'">
                    
                    <div class="floor-section d-flex justify-content-between">
                        <span class="">Current Bid</span>
                        <div class="d-flex gap-2">
                        <img src="assets/images/icons/bnb.svg"
                            class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                        <span
                            class="truncate lg:text-clip floor-price-text">{{nft.price}} <span>BNB</span></span>
                        </div>
                    </div>

                    
                </div>
                <div class="buy-details" *ngIf="nft.saleType == 'Buy'">

                    <div class="floor-section d-flex justify-content-between">
                        <span class="">Current Bid</span>
                        <div class="d-flex gap-2">
                        <img src="assets/images/icons/bnb.svg"
                            class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                        <span
                            class="truncate lg:text-clip floor-price-text">{{nft.price}} <span>BNB</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="nft__item style-2" [routerLink]="['/single-nft-details', nft._id]">
            <div class="nft-common-details">
                <span
                    *ngIf="((!checkIfStartEnded(nft.bidStartTime) && (nft.saleType == 'Bid')) && nft.bidStartTime) || ((!checkIfStartEnded(nft.bidStartTime) && (nft.saleType == 'TimedBid')) && nft.bidStartTime) || ((checkIfStartEnded(nft.bidStartTime) && !checkIfStartEnded(nft.bidEndTime)) && nft.bidEndTime) || ((checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')); else isLiveText">

                </span>
                <ng-template #isLiveText>
                    <div class="de_countdown">Live</div>
                </ng-template>
               
                <span *ngIf="checkIfStartEnded(nft.bidStartTime) && !checkIfStartEnded(nft.bidEndTime)">
                    <span *ngIf="nft.bidEndTime">
                        <div class="de_countdown">
                            Ends in :
                            <span id="countDown{{i}}">
                                {{bidEndtimer(nft.bidEndTime, i)}}
                            </span>
                        </div>
                    </span>
                </span>
                <ng-container *ngIf="(nft.saleType != 'Buy')">
                <span *ngIf="!checkIfStartEnded(nft.bidStartTime)">
                    <div class="de_countdown">
                        Start in :
                        <span id="countDownOffers{{i}}">
                            {{OffersTimer(nft.bidStartTime, i)}}
                        </span>
                    </div>
                </span>
            </ng-container>
                <span class="" *ngIf="(checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                    <div class="de_countdown">BID ENDED</div>
                </span>
                <div class="author_list_pp">
                    <a *ngIf="nft.nftCollection != ''">
                        <img class="lazy profile-img-icon"
                            [src]="nft.nftCollection.collectionImage ? nft.nftCollection.collectionImage : 'assets/images/author_single/author_thumbnail.jpg'"
                            alt="Profile-Image">
                        <i class="fa fa-check"></i>
                    </a>
                    <a *ngIf="nft.nftCollection.length == ''">
                        <img class="lazy profile-img-icon"
                            [src]="collection.collectionImage ? collection.collectionImage : 'assets/images/author_single/author_thumbnail.jpg'"
                            alt="Profile-Image">
                        <i class="fa fa-check"></i>
                    </a>
                </div>
                <div class="nft__item_wrap">
                    <a [routerLink]="['/single-nft-details']">
                        <img [src]="[nft.nftImageS3 ? (nft.nftImageS3 ? nft.nftImageS3 : nft.nftCollection.baseUrl + nft.nftImage) : 'assets/images/collections/coll-item-1.jpg']"
                            class="lazy nft__item_preview" alt="">
                    </a>
                </div>
                <div class="nft__item_info">
                    <a [routerLink]="['/single-nft-details']">
                        <h4 class="text-truncate" title="{{nft?.nftCollection?.collectionName}}">{{nft?.nftCollection?.collectionName}}</h4>
                        <h3 class="text-truncate" title="{{nft?.name}} #{{nft.tokenId}}">{{nft?.name}} #{{nft.tokenId}}</h3>
                    </a>
                    <hr class="border-grey border-t border-solid">
                </div>
            </div>
            <div class="buy-details" *ngIf="nft.saleType != 'Buy'">
                <div title="{{nft.price}} BNB" class="d-flex price-div  justify-content-between">
                    {{nft.price | number:'1.3-3'}} BNB
                    <a class="btn-main text-them buy-btn"
                        *ngIf="(checkIfBidEnded(nft.bidStartTime) && !checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">

                        Bid Now
                    </a>
                    <a class="btn-main text-them buy-btn"
                        *ngIf="(checkIfBidEnded(nft.bidStartTime) && nft.saleType === 'Bid')">
                        Offer Now
                    </a>
                </div>
            </div>
            <div class="buy-details" *ngIf="nft.saleType == 'Buy'">
                <div title="{{nft.price}} BNB" class="d-flex price-div  justify-content-between">
                    {{nft.price | number:'1.3-3'}} BNB
                    <a class="btn-main text-them buy-btn cursor-pointer">
                        Buy Now
                    </a>
                </div>
            </div>
        </div> -->

    </div>
</div>
</div>