import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogsRoutingModule } from './blogs-routing.module';
import { BlogsDetailsComponent } from './blogs-details/blogs-details.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BlogsDetailsComponent,
  ],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    SharedModule,
  ],
})

/**
 * Export Class
 */
export class BlogsModule { }
