/* eslint-disable require-jsdoc */
import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs';
import { StorageService } from 'ng-blockchainx';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private commonService: CommonService
  ) { }

  /**
     * Get Private Sale By User
     * @param {string} userId
     * @return {httpresponse}
     */
   public getPrivateSaleByUser(userId: string, page: number, limit: number) {

    return this.http.get(`${environment.api_url}/api/v1/get-private-sale-purchase-list/${userId}?page=${page}&limit=${limit}`)
      .pipe(
          retry(2), // retry a failed request up to n times
          catchError(this.commonService.handleError), // handle error
      );
  }
/**
   * Update Profile
   * @param {string} userId
   * @param {any} reqBody
   * @return {httpresponse}
   */
  public updateProfile(userId: string, reqBody: any) {
    const account: any = this.storage.get('auth');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + account.token,
    });
    return this.http.patch(`${environment.api_url}/api/v1/update-user/${userId}`, reqBody, { headers: reqHeader })
      .pipe(
          retry(2), // retry a failed request up to n times
          catchError(this.commonService.handleError), // handle error
      );
  }

  getPartnerProfile(id: string) {
    return this.http.get(`${environment.api_url}/api/v1/get-partner/${id}`)
      .pipe(
        retry(2), // retry a failed request up to n times
        catchError(this.commonService.handleError), // handle error
      );
  }

  public getUser(userId: string) {
    const account: any = this.storage.get('auth');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + account.token,
    });
    return this.http.get(`${environment.api_url}/api/v1/get-user/${userId}`, { headers: reqHeader })
      .pipe(
        retry(2), // retry a failed request up to n times
        catchError(this.commonService.handleError), // handle error
      );
  }

  /**
   * Get User By Wallet Address
   * @param {string} walletAddress
   */
   public getUserByWalletAddress(walletAddress: string) {
    const reqBody = {
      walletAddress: walletAddress,
    };
    return this.http.post(`${environment.api_url}/api/v1/get-user/wallet-address`, reqBody)
      .pipe(
        retry(2), // retry a failed request up to n times
        catchError(this.commonService.handleError), // handle error
      );
  }
  public getUserByName(userName: string) {
    return this.http.get(`${environment.api_url}/api/v1/get-user/?userName=${userName}`)
      .pipe(
        retry(2), // retry a failed request up to n times
        catchError(this.commonService.handleError), // handle error
      );
  }

  /**
   * Get User OnSale Nfts
   * @param {string} userId
   * @return {object}
   */
   public getUserOnSaleNfts(userId: string, page: number, limit: number) {
   return this.http.get(`${environment.api_url}/api/v1/filter-sale-nft/${userId}?page=${page}&limit=${limit}`)
      .pipe(
        retry(2), // retry a failed request up to n times
        catchError(this.commonService.handleError), // handle error
      );
  }

  public getUsersToken(walletAddress: string) {
    return this.http.get(`${environment.api_url}/api/v1/getNftsByAccount/${walletAddress.toLowerCase()}`)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  public getNFTByTokenId(collectionAddress:string, tokenId:string) {
    
    return this.http.get(`${environment.api_url}/api/v1/get-nft-by-token-id?collectionAddress=${collectionAddress}&tokenId=${tokenId}`)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  public getNFTNameByTokenURI(tokenURI:string) {
    return this.http.get(`${environment.api_url}/api/v1/get-name-by-token-uri?token_uri=${tokenURI}`)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  public getMetaData(baseUrl: string,cid: string) {
    return this.http.get(`${baseUrl}${cid}`)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }
}
