import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css'],
})

/**
 * Component
 */
export class MultiSelectDropdownComponent {
  @Input() list:any[] | undefined;

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  public checkedList : any[];
  public currentSelected : {} | undefined;
  public showDropDown:any;
  public checkedListValue : any[];
  /**
   * Creates an instance of multi select dropdown component.
   */
  constructor() {
    this.checkedList = [];
    this.checkedListValue = [];
  }

  /**
   * Gets selected value
   * @param {boolean} status
   * @param {string} value
   */
  getSelectedValue(status:boolean, value:string) {
    let originalValue = value;
    if (value == 'Bid') {
      originalValue = 'Offer';
    }
    if (status) {
      this.checkedList.push(value);
      this.checkedListValue.push(originalValue);
    } else {
      const index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
      const valueIndex = this.checkedListValue.indexOf(originalValue);
      this.checkedListValue.splice(valueIndex, 1);
    }

    this.currentSelected = { checked: status, name: value };

    // share checked list
    this.shareCheckedlist();

    // share individual selected item
    this.shareIndividualStatus();
  }

  /**
   * Shares checkedlist
   */
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }

  /**
   * Shares individual status
   */
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }

  /**
   * Clear selected list
   */
  clearSelection() {
    this.checkedList = [];
  }
}
