import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { WalletsComponent } from './wallets/wallets.component';
import { FooterComponent } from './footer/footer.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ShimmerComponent } from './shimmer/shimmer.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ContractCallProgressComponent } from './contract-call-progress/contract-call-progress.component';
import { PutForSaleComponent } from './put-for-sale/put-for-sale.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    FooterComponent,
    MultiSelectDropdownComponent,
    NavBarComponent,
    ShimmerComponent,
    WalletsComponent,
    ContractCallProgressComponent,
    PutForSaleComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgxShimmerLoadingModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CarouselModule,
    DirectivesModule,
    ImageCropperModule,
  ],
  exports: [
    FooterComponent,
    MultiSelectDropdownComponent,
    NavBarComponent,
    ShimmerComponent,
    WalletsComponent,
    NgxShimmerLoadingModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CarouselModule,
    NgApexchartsModule,
    ContractCallProgressComponent,
    PutForSaleComponent,
    DirectivesModule,
    ImageCropperModule,
    LoaderComponent,
  ],
})

/**
 * Export Class
 */
export class SharedModule { }
