import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LearnService } from 'src/app/services/learn.service';

@Component({
  selector: 'app-blogs-details',
  templateUrl: './blogs-details.component.html',
  styleUrls: ['./blogs-details.component.css'],
})

/**
 * Export Class
 */
export class BlogsDetailsComponent implements OnInit {
  public blogDetails: any;

  /**
   * Creates an instance of blogs details component.
   */
  constructor(
    public learnService:LearnService,
    private route: ActivatedRoute,
    private titleService:Title,
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['id'] != '' && params['id'] != undefined) {
        this.getBlogList(params['id']);
        window.scrollTo(0, 0);
      }
    });
  }


  /**
   * Gets blog list
   * @param {any} id
   */
  public getBlogList(id:string) {
    this.learnService.getLearnListId(id).subscribe((response: any) => {
      (document.getElementById(`blogContent`) as HTMLInputElement).innerHTML = response.data.content;
      this.blogDetails = response.data;
      this.titleService.setTitle(this.blogDetails.title);
    });
  }
}
