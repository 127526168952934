import { Pipe, PipeTransform } from '@angular/core';
import { AccountService } from '../services/account.service';

@Pipe({
  name: 'objectTextFilter',
})
/**
 * Object text filter
 */
export class ObjectTextFilterPipe implements PipeTransform {
  /**
   * Creates an instance of object text filter pipe.
   */
  constructor(
    private accountService:AccountService,
  ) {

  }
  /**
   * transform function
   *
   * @param {any} value
   * @param {any} keyword
   * @param {string} searchKey
   * @return {any}
   */
  transform(value: any, keyword: any, searchKey:string): any {
    const data = value?.filter((d:any)=>d[searchKey]?.match(new RegExp(keyword, 'i')));
    const dataLength = {
      length: data.length,
    };
    this.accountService.updateSearchFilter(dataLength);
    return value?.filter((d:any)=>d[searchKey]?.match(new RegExp(keyword, 'i')));
  }
}
