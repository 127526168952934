import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { HomeComponent } from './components/home/home.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TrendingNftComponent } from './components/home/trending-nft/trending-nft.component';
import { TopSellersComponent } from './components/home/top-sellers/top-sellers.component';
import { HotCollectionsComponent } from './components/home/hot-collections/hot-collections.component';
import { BlogsModule } from './components/blogs/blogs.module';
import { SharedModule } from './components/shared/shared.module';
import { NftLaunchpadModule } from './components/nft-launchpad/nft-launchpad.module';
import { PipesModule } from './pipes/pipes.module';
import { TrendingsModule } from './components/trendings/trendings.module';
import { ProfileModule } from './components/profile/profile.module';
import { CollectionsModule } from './components/collections/collections.module';
import { CollectionsComponent } from './components/collections/collections.component';
import { PartnerModule } from './components/partner/partner.module';


@NgModule({
  declarations: [
    AppComponent,
    BlogsComponent,
    HomeComponent,
    TrendingNftComponent,
    TopSellersComponent,
    HotCollectionsComponent,
    CollectionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ClipboardModule,
    BrowserAnimationsModule,
    BlogsModule,
    SharedModule,
    NftLaunchpadModule,
    PipesModule,
    TrendingsModule,
    ProfileModule,
    CollectionsModule,
    PartnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})

/**
 * Export Class
 */
export class AppModule { }
