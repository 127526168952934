// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://apinft.blockchainx.tech',
  base_url: 'https://nftmarketplace.blockchainx.tech',

  exchange_contract: '0xD698AB755e74198350aB0916c54B1b6dC6C5024e',
  wbnb_contract: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',

  provider: 'https://data-seed-prebsc-2-s1.binance.org:8545/',
  ETH_PROVIDER: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  BSC_PROVIDER: 'https://data-seed-prebsc-1-s1.binance.org:8545/',

  BSC_CHAINID: '0x61',

  ekta_scan: 'https://testnet.bscscan.com/',
  CHAIN_NAME: 'BSC Test Network',
  EXPLORER_URL: 'https://testnet.bscscan.com/',
  CURRENCY_NAME: 'BSC',
  CURRENCY_SYMBOL: 'BSC',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
