import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, catchError, retry } from 'rxjs';
import { StorageService } from 'ng-blockchainx';

@Injectable({
  providedIn: 'root',
})

/**
 * Export Class
 */
export class EditProfileService {
  /**
   * Creates an instance of edit profile service.
   */
  constructor(private http: HttpClient,
    private storage: StorageService) { }

  /**
   * Updates profile
   * @param {string} userId
   * @param {any} userDetails
   * @return  {httpresponse}
   */
  updateProfile(userId: string, userDetails: any) {
    const account: any = this.storage.get('auth');
    const reqHeader = new HttpHeaders({
      'Authorization': 'bearer ' + account.token,
    });
    return this.http.patch(`${environment.api_url}/api/v1/update-user/${userId}`, userDetails, { headers: reqHeader })
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.handleError), // handle error
        );
  }

  /**
   * Handles error
   * @param  {HttpErrorResponse} error
   * @return  {HttpErrorResponse}
   */
  public handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => error.error);
  }
}
