<a href="#" id="back-to-top"></a>

<!-- footer begin -->
<footer>
    
    <div class="main-footer">
        <div class="container">
            <div class="d-flex justify-content-between">
                <div class="box flogo">
                    <div class="widget">
                        <div id="logo">
                            <a [routerLink]="['/home']">
                                <img alt="Logo" class="logo-img" src="assets/images/logo-dark.svg" />
                            </a>
                        </div>
                        <div class="newsletter">
                            <h5>Newsletter</h5>
                            <p>Signup for our newsletter to get the latest news.</p>
                            <form action="blank.php" class="form-dark" id="form_subscribe" method="post"
                                name="form_subscribe newsletter-subscribe">
                                <div class="newletterform">
                                    <input class="form-control" id="txt_subscribe" name="txt_subscribe"
                                        placeholder="Enter your email" type="text" /> 
                                        <a href="#" id="btn-subscribe">Submit</a>
                                </div>
                            </form>
                            <small>Your email is safe with us. We don't spam.</small>
                        </div>
                    </div>
                </div>
                        <div class="box">
                            <div class="widget">
                                <h5>Build</h5>
                                <ul>
                                    <li><a href="#">Game builders program</a></li>
                                    <li><a href="#">Apply for partnership</a></li>
                                    <li><a href="#">Become a content creator</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="box">
                            <div class="widget">
                                <h5>About</h5>
                                <ul>
                                    <li><a href="#">Gitbook</a></li>
                                    <li><a href="#">Brand Assets</a></li>
                                    <li><a href="#">Newsletter</a></li>
                                    <li><a href="#">Linktree</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="box">
                            <div class="widget">
                                <h5>Discover</h5>
                                <ul>
                                    <li><a href="#">Games</a></li>
                                    <li><a href="#">Staking</a></li>
                                    <li><a href="#">IFO</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
    <div class="subfooter">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="de-flex">
                        <div class="de-flex-col">
                            <a>
                                <span class="copy">Copyright &copy; 2020 - {{currentYear}}  |  BlockchainX  |  <a href="#">Privacy Policy</a> | <a href="#">T&C</a> </span>
                            </a>
                        </div>
                        <div class="de-flex-col">
                            <div class="social-network d-flex align-items-center justify-content-center">
                                <p>Connect with us</p>
                                <div class="social-links">
                                    <a href="#"><img src="assets/images/discord.svg" alt="discord" class="img-fluid"></a>
                                    <a href="#"><img src="assets/images/reddit.svg" alt="reddit" class="img-fluid"></a>
                                    <a href="#"><img src="assets/images/telegram.svg" alt="telegram" class="img-fluid"></a>
                                    <a href="#"><img src="assets/images/twitter.svg" alt="twitter" class="img-fluid"></a>
                                    <a href="#"><img src="assets/images/instagram.svg" alt="instagram" class="img-fluid"></a>
                                    <a href="#"><img src="assets/images/youtube.svg" alt="Youtube" class="img-fluid"></a>
                                    <a href="#"><img src="assets/images/medium.svg" alt="Medium" class="img-fluid"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer close -->