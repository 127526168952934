import { Component, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-trending-nft',
  templateUrl: './trending-nft.component.html',
  styleUrls: ['./trending-nft.component.css'],
})

/**
 * Export Class
 */
export class TrendingNftComponent {
  @Input() nfts : any = [];
  @Input() getNftResponse : any = [];

  public blogPost: OwlOptions = {
    loop: true,
    margin: 30,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      400: {
        items: 2,
        margin: 20,
      },
      600: {
        items: 3,
        margin: 20,
      },
      1000: {
        items: 4,
        margin: 24,
      },
      1600: {
        items: 4,
        margin: 40,
      },
    },
    skip_validateItems: true,

  };

  /**
   * Check if bid is ended
   * @param {string} date
   * @return {boolean}
   */
  public checkIfBidEnded(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }
}
