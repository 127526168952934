import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PartnerRoutingModule } from './partner-routing.module';
import { PartnerComponent } from './partner.component';
import { SharedModule } from '../shared/shared.module';
import { CollectionsModule } from '../collections/collections.module';
import { NftLaunchpadModule } from '../nft-launchpad/nft-launchpad.module';


@NgModule({
  declarations: [
    PartnerComponent,
  ],
  imports: [
    CommonModule,
    PartnerRoutingModule,
    SharedModule,
    CollectionsModule,
    NftLaunchpadModule,
  ],
  exports: [
    PartnerRoutingModule,
  ],
})

/**
 * Export Class
 */
export class PartnerModule { }
