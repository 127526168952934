import * as moment from 'moment';

/**
 * Common helper functions
 */
export class CommonHelper {
  /**
   * get time seconds, minutes and hours
   *
   * @param {any} dateFuture
   * @param {any} dateNow
   * @return {any}
   */
  public static getTimerForSale = (dateFuture:any, dateNow:any = moment(new Date()).unix())=>{
    let seconds = Math.floor((dateFuture as any - (dateNow as any)));
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days;
    if (hours <= 0) {
      days = 0;
    } else {
      days = Math.floor(hours/24);
    }

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    return {
      hours,
      minutes,
      seconds,
      days,
    };
  };
}
