import { Component, OnInit, ElementRef, Inject, ViewChild } from '@angular/core';
import { StorageService } from 'ng-blockchainx';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { ExchangeContractService } from 'src/app/services/exchange-contract.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { TradeService } from 'src/app/services/trade.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Erc721ContractService } from 'src/app/services/erc721-contract.service';
import { ClipboardService } from 'ngx-clipboard';
import { DOCUMENT } from '@angular/common';

const provider = (window as { [key: string]: any })['ethereum'] as string;
const web3 = new Web3(provider || environment.provider);

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})

/**
 * Component
 */
export class ProfileComponent implements OnInit {
  public isPutForSaleShown = false;
  public nft: any = {};
  public activeMenu: number = 0;
  public privateSale: any = [];
  public user: any;
  public onSaleNFT: any = [];
  public usersTokenData: any = [];
  public userOwnedNFTs: any = [];
  public initialLoader:boolean = true;
  public nftLoader: boolean = true;
  public loading:boolean = true;
  public isRemoveSaleModalShown: boolean = false;
  public isRemoveSale: boolean = false;
  public copyWalletAddress : any;
  public isOwnProfile: boolean = true;
  public privateSalePagination: any = { currentPage: 1, limit: 52, totalPages: 1, count: 0 };
  public onSalePagination: any = { currentPage: 1, limit: 52, totalPages: 1, count: 0 };
  public auth: any;
  @ViewChild('closeRemoveModal') closeRemoveModal:any;
  public onSaleTotalCount: number = 0;
  public account:any = {};
  public pageLoader:boolean = false;

  /**
   * @constructor
   */
  constructor(
    private storage: StorageService,
    private profileService: ProfileService,
    private exchangeContractService: ExchangeContractService,
    private tradeService: TradeService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private erc721ContractService: Erc721ContractService,
    private clipboardApi: ClipboardService,
    // @ts-ignore
    private elementRef: ElementRef, @Inject(DOCUMENT) private doc,
    private storageService:StorageService,
  ) {
    this.pageLoader = true;
  }

  /**
   * Called intially
   */
  public ngOnInit(): void {
    this.account = this.storageService.get('auth');
    if (!this.account) {
      window.location.href='home';
    }
    const s14 = document.createElement('script');
    s14.type = 'text/javascript';
    s14.src = '../../../../assets/js/designesia.js';
    this.elementRef.nativeElement.appendChild(s14);

    const s1 = document.createElement('script');
    s1.type = 'text/javascript';
    s1.src = '../../../../assets/js/designesia.js';
    this.elementRef.nativeElement.appendChild(s1);

    this.activatedRoute.queryParams
        .subscribe((params) => {
          this.activeMenu = (params.active >= 0 && params.active < 3) ? params.active : 0;
        },
        );

    if (this.router.url === '/profile') {
      this.user = this.storage.get('user');
      this.auth = this.storage.get('auth');
      const provider = this.user ? this.profileService.getUser(this.user._id) : this.profileService.getUserByWalletAddress(this.auth.walletAddress);

      this.isOwnProfile = true;
      provider.subscribe({
        next: (response: any) => {
          this.user = response.data.user;
          this.getUserOnSaleNfts();
          this.getUserTokens(this.user.walletAddress);

          this.getUserSalePurchase();
          this.initialLoader = false;
        },
        error: (_error) => {
        },
      });
    } else {
      this.isOwnProfile = false;
      this.profileService.getUserByName(this.router.url.substring(1)).subscribe({
        next: (response: any) => {
          this.user = response.data.user;
          this.getUserOnSaleNfts();
          this.getUserTokens(this.user.walletAddress);
          this.getUserSalePurchase();
          this.initialLoader = false;
        },
        error: (_error) => {
        },
      });
    }
  }

  /**
   * Copy User Wallet Address
   */
  public copyUserWalletAddress() {
    this.copyWalletAddress = this.user.walletAddress;
    this.clipboardApi.copyFromContent(this.copyWalletAddress);
    this.toastr.success('Copied to Clipboard');
  }
  /**
   * Organizes order
   * @param {string} type
   */
  public async organizeOrder(type: string) {
    let order;
    if (type === 'Buy') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
        this.nft?.nftCollection?.collectionAddress,
        Number(this.nft?.tokenId),
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        0, // start time is always 0 for buy
        0, // end time is always 0 for buy
        this.nft?.nonce,
      );
    }
    if (type === 'Bid') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
        this.nft?.nftCollection?.collectionAddress,
        Number(this.nft?.tokenId),
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        moment(this.nft?.bidStartTime).unix(),
        0, // end time is always 0 for bid
        this.nft?.nonce,
      );
    }
    if (type === 'TimedBid') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
        this.nft?.nftCollection?.collectionAddress,
        this.nft?.tokenId,
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        moment(this.nft?.bidStartTime).unix(),
        moment(this.nft?.bidEndTime).unix(),
        this.nft?.nonce,
      );
    }
    return order;
  }

  /**
   * Gets token type
   * @param {string} type
   * @return  {string}
   */
  public getTokenType(type: string) {
    return this.exchangeContractService.getTokenType(type);
  }

  /**
   * Gets sale type
   * @param {string} type
   * @return  {string}
   */
  public getSaleType(type: string) {
    return this.exchangeContractService.getSaleType(type);
  }

  /**
   * Gets user on sale nfts
   */
  public getUserOnSaleNfts() {
    this.onSaleNFT = [];
    this.onSaleTotalCount = 0;
    this.profileService.getUserOnSaleNfts(this.user._id, this.onSalePagination.currentPage, this.onSalePagination.limit).subscribe({
      next: (response: any) => {
        this.onSaleNFT.push(...response?.data?.nftSale);
        if (response?.data?.nftSale.length > 0) {
          this.onSalePagination.totalPages = response?.data?.pagination.total_page || 1;
          this.onSalePagination.count = response?.data?.pagination.count;
          this.onSaleTotalCount = this.onSalePagination.count;
        }
        this.nftLoader = false;
        this.pageLoader = false;
      },
      error: (error) => {
        this.pageLoader = false;
        this.toastr.error(error.message, 'Failed to fetch sale nfts');
      },
    });
  }

  /**
   * showPutForSale
   *
   * @param {any} nft
   */
  public showPutForSale(nft?: any) {
    this.nft = nft;
    this.isPutForSaleShown = true;
  }

  /**
   * Gets user tokens
   * @param {string} walletAddress
   */
  public getUserTokens(walletAddress: string) {
    this.profileService.getUsersToken(walletAddress).subscribe((tokenResponse: any) => {
      this.usersTokenData = tokenResponse.data;
      if (this.usersTokenData.length > 0) {
        this.getTokenDetails(this.usersTokenData.slice(0, 52));
      }
    });
  }

  /**
   * Gets token details
   * @param {any} [tokens]
   */
  public getTokenDetails(tokens: any = []) {
    this.nftLoader = true;
    this.userOwnedNFTs = [];
    for (const element of tokens) {
      const tokenId = element.token_id;
      const collectionAddress = element.contract_address;
      // Get NFT by contract address & token id

      this.profileService.getNFTByTokenId(collectionAddress, tokenId).subscribe(async (response: any) => {
        if (Object.keys(response.data).length > 0 && !response.data.isNft) {
          // Note Need to handle for erc1155
          // erc721
          const nftImage = await this.erc721ContractService.getTokenURI(collectionAddress, tokenId);
          if (!response.data.name) {
            this.profileService.getNFTNameByTokenURI(nftImage).subscribe((result: any) => {
              response.data.token_uri = nftImage;
            });
          }
          this.profileService.getMetaData(response.data.baseUrl, nftImage).subscribe((result: any) => {
            response.data.nftImage = result.image;
            response.data.tokenId = tokenId;
            if (!response.data?.isOtherCollection) {
              response.data.isOtherCollection = false;
            }
            if (!response.data?.forSale) {
              response.data.forSale = 0;
            }
          });
        }
        if (Object.keys(response.data).length === 0) {
          const nftImage = await this.erc721ContractService.getTokenURI(collectionAddress, tokenId);
          const baseUrl = await this.erc721ContractService.getBaseUri(collectionAddress);
          const collectionName = await this.erc721ContractService.getName(collectionAddress);
          response.data.baseUrl = baseUrl;
          response.data.collectionName = collectionName;
          response.data.tokenId = tokenId;
          response.data.isOtherCollection = true;
          this.profileService.getMetaData(baseUrl, nftImage).subscribe({
            next: (result: any) => {
              response.data.nftImage = result.image;
            },
          });
        }
        await this.userOwnedNFTs.push(response.data);
        this.nftLoader = false;
        this.pageLoader = false;
      });
    }
  }

  /**
   * Gets user sale purchase
   */
  public getUserSalePurchase() {
    this.profileService.getPrivateSaleByUser(this.user._id, this.privateSalePagination.currentPage, this.privateSalePagination.limit).subscribe((response: any) => {
      if (response?.data?.userSaleList?.length > 0) {
        this.privateSale.push(...response?.data?.userSaleList);
        if (response?.data?.userSaleList.length > 0) {
          this.privateSalePagination.totalPages = response?.data?.results.total_page || 1;
          this.privateSalePagination.count = response?.data?.results.count;
        }
      }
      this.nftLoader = false;
      this.pageLoader = false;
    });
  }
  /**
   * Removes sale
   * @param {string} type
   */
  public async removeSale(type: string) {
    try {
      this.isRemoveSale = true;
      const order = await this.organizeOrder(type);
      const cancelOrderAbi = await this.exchangeContractService.cancelOrder(order);
      const message: any = {
        method: 'eth_sendTransaction',
        from: this.user.walletAddress,
        to: environment.exchange_contract,
        data: cancelOrderAbi,
      };

      web3.eth.sendTransaction(message)
          .on('receipt', async (receipt) => {
          // api call
            this.tradeService.cancelSale(this.nft._id).subscribe({
              next: (response: any) => {
                this.getUserTokens(this.user.walletAddress);
                this.getUserOnSaleNfts();
                this.isRemoveSaleModalShown = false;
                this.isRemoveSale = false;
                this.closeRemoveModal.nativeElement.click();
                this.toastr.success(`You have removed ${this.nft?.name} #${this.nft?.tokenId} from sale`);
                this.pageLoader = false;
              },
              error: (error) => {
                this.isRemoveSale = false;
                this.closeRemoveModal.nativeElement.click();
                this.toastr.error(`Failed to remove ${this.nft?.name} #${this.nft?.tokenId} from sale`);
                this.pageLoader = false;
              },
            });
          })
          .on('error', (error: any) => {
            this.isRemoveSale = false;
            this.closeRemoveModal.nativeElement.click();
            if (error.code === 4001) this.toastr.error('User rejected');
            else this.toastr.error(error['message']);
          });
    } catch (error: any) {
      this.toastr.error(error['message']);
    }
  }


  /**
   * Shows remove sale modal
   * @param {any} nft
   */
  public showRemoveSaleModal(nft: any) {
    this.nft = nft;
  }

  /**
   * On scroll event
   *
   * @param {any} ev
   */
  public onScrollDown(ev: any) {
    if (this.activeMenu === 0 && this.userOwnedNFTs.length != this.usersTokenData.length) {
      this.nftLoader = true;
      setTimeout(() => {
        this.getTokenDetails(this.usersTokenData.slice(this.userOwnedNFTs.length, this.userOwnedNFTs.length+10));
      }, 1000);
    }
    if (this.activeMenu === 1 && this.privateSalePagination.currentPage + 1 <= this.privateSalePagination.totalPages) {
      this.nftLoader = true;
      setTimeout(() => {
        this.privateSalePagination.currentPage += 1;
        this.getUserSalePurchase();
      }, 1000);
    }
    if (this.activeMenu === 2 && this.onSalePagination.currentPage + 1 <= this.onSalePagination.totalPages) {
      this.nftLoader = true;
      setTimeout(() => {
        this.onSalePagination.currentPage += 1;
        this.getUserOnSaleNfts();
      }, 1000);
    }
  }

  /**
   * setActiveMenu
   *
   * @param {number} index
   */
  setActiveMenu(index: number) {
    this.activeMenu = index;
  }
}
