<!-- <app-loader *ngIf="pageLoader"></app-loader>
<div class="row" *ngIf="!pageLoader">
    <div class="col-lg-12">
        <div class="text-center">
            <h2>Top Sellers</h2>
            <div class="small-border bg-color-2"></div>
        </div>
    </div>
    <div class="col-md-12 wow fadeIn">
        <ol class="author_list">
            <li *ngFor="let data of partners">
                <div class="author_list_pp">
                    <a routerLink="/partner/{{data._id}}">
                        <img class="lazy"
                            src="{{data.profileImage ? data.profileImage : 'assets/images/author/author-1.jpg'}}"
                            alt="">
                        <i class="fa fa-check"></i>
                    </a>
                </div>
                <div class="author_list_info position-relative">
                    <a routerLink="/partner/{{data._id}}" class="d-block">{{data.name}}</a>
                    <span class="d-block position-absolute total-price">{{data.total_sold_nft_value | number: '1.3-3'}} BNB</span>
                </div>
            </li>
        </ol>
    </div>
</div> -->




<section class="top-sellers">
<div class="container">
    <div class="bg-gradient-1 bg-left-top"></div>
    <div class="bg-gradient-2 bg-left-top"></div>
    <div class="row">
        <div class="col-lg-12">
            <div class="text-center title-wrap">
                <h2>Top Sellers</h2>
            <p>Discover a new realm of gaming on the Blockchainx Arcade, a hub of games powered by Blockchainx <br/>
                uniting gamers, investors and developers seamlessly.</p>
            </div>
            <!-- <div class="text-center">
                <h2>Top Sellers</h2>
                <div class="small-border bg-color-2"></div>
            </div> -->
        </div>
        <div class="col-md-12 wow fadeIn">
            <ol class="author_list">
                <li *ngFor="let data of partners">
                    <div class="author_list_pp">
                        <a routerLink="/partner/{{data._id}}">
                            <img class="lazy"
                                src="{{data.profileImage ? data.profileImage : 'assets/images/author/author-1.jpg'}}"
                                alt="">
                            <i class="fa fa-check"></i>
                        </a>
                    </div>
                    <div class="author_list_info position-relative">
                        <a routerLink="/partner/{{data._id}}" class="">{{data.name}}</a>
                        <span class="total-price">{{data.total_sold_nft_value | number:'1.2-4'}} BNB</span>
                    </div>
                </li>
            </ol>
        </div>
        <div class="row" *ngIf="partners.length == 0" class="no-trend-nft">
            Top Sellers yet to be update
        </div>
    </div>
</div>
</section>