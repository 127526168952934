import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})

/**
 * Export Class
 */
export class FooterComponent implements OnInit {
  public currentYear: string = '';

  /**
   * on init
   */
  ngOnInit(): void {
    this.currentYear = moment(new Date()).format('YYYY');
  }
}
