import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})

/**
 * Export Class
 */
export class LearnService {
  public BASE_URL = environment.api_url;

  /**
   * Creates an instance of learn service.
   */
  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Gets learn list
   * @param {number} page
   * @return  {httpResponse}
   */
  public getLearnList(page:number) {
    return this.http.get(`${this.BASE_URL}/api/v1/get-all-blogs?page=${page}&limit=9`);
  }
  /**
   * Gets learn list id
   * @param {any} id
   * @return  {httpResponse}
   */
  public getLearnListId(id:any) {
    return this.http.get(`${this.BASE_URL}/api/v1/get-blogs/${id}`);
  }
}
