import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { PrivateSaleService } from 'src/app/services/private-sale.service';
import { BannerService } from 'src/app/services/banner.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-nft-launchpad',
  templateUrl: './nft-launchpad.component.html',
  styleUrls: ['./nft-launchpad.component.css'],
})

/**
 * Export Class
 */
export class NftLaunchpadComponent implements OnInit {
  public collections: any = [];
  private partnerFilter: Array<any> = [];
  private categoriesFilter: Array<any> = [];
  private categorySearch: string = '';
  public banners: any = [];
  public initialLoader:boolean = true;
  public loader: boolean = false;
  public pageLoader:boolean = false;

  /**
   * constructor
   */
  constructor(
    private toastr: ToastrService,
    private collectionService: PrivateSaleService,
    private bannerService: BannerService,
    private titleService:Title,
  ) {
    this.pageLoader = true;
    this.titleService.setTitle('NFT Launchpad');
  }

  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    this.getCollections();
    this.getBanners();
  }

  /**
   * after view init
   */
  public ngAfterViewInit() {
    this.initialLoader = false;
  }

  /**
   * Get collections list
   * @param {object} options - has page and limits for query
   */
  public getCollections(options: any = {}) {
    if (this.initialLoader) {
      this.loader = true;
    }
    window.scroll(0, 0);
    this.collectionService.getAllCollections(options)
        .subscribe({
          next: (response: any) => {
            this.collections = response['data'];
            this.loader = false;
            this.pageLoader = false;
            this.processCollection();
          },
          error: (error) => {
            this.loader = false;
            this.pageLoader = false;
            this.toastr.error(error.message, 'Failed to fetch collections');
          },
        });
  }

  /**
   * Check the sale is upcoming,live or ended
   */
  public processCollection() {
    this.collections.map((item:any) => {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000); // unix timestamp in seconds
      //  if sale is upcoming or live or ended
      if (currentTimeInSeconds >= item.saleStartTime) {
        item.saleStatus = 'live'; // ends in
        if (currentTimeInSeconds > item.saleEndTime) {
          item.saleStatus = 'end'; // sale ended
        }
      } else {
        item.saleStatus = 'upcoming'; // starts in
      }
    });
  }

  /**
   * Get collections banners
   */
  public getBanners() {
    this.bannerService.getSaleBanners()
        .subscribe({
          next: (response: any) => {
            this.banners = response['data'];
            this.pageLoader = false;
          },
          error: (error:any) => {
            this.pageLoader = false;
            this.toastr.error(error.message, 'Failed to fetch banners');
          },
        });
  }


  /**
   * On partners select
   * @param {any} partners
   */
  public onPartnerSelect(partners: any) {
    this.partnerFilter = partners;
    this.getCollections({ categories: this.categoriesFilter, categorySearch: this.categorySearch, partner: this.partnerFilter });
  }

  /**
   * On category search
   * @param {string} keyword
   */
  public onCategorySearch(keyword: string) {
    this.categorySearch = keyword;
    this.getCollections({ categories: this.categoriesFilter, categorySearch: this.categorySearch, partner: this.partnerFilter });
  }

  /**
   * On categories select
   * @param {Array<string>} categories
   */
  public onCategorySelect(categories: Array<string>) {
    this.categoriesFilter = categories;
    this.getCollections({ categories: this.categoriesFilter, categorySearch: this.categorySearch, partner: this.partnerFilter });
  }
}
