
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { CommonHelper } from 'src/app/helpers/common.helper';
import Web3 from 'web3';

import { ExchangeContractService } from 'src/app/services/exchange-contract.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TradeService } from 'src/app/services/trade.service';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { TradeContractService } from 'src/app/services/trade-contract.service';
import { CommonService } from 'src/app/services/common.service';
import { gte } from 'src/app/helpers/validator.helper';
import { StorageService, WalletConnectService, Web3Service } from 'ng-blockchainx';
import { AccountService } from 'src/app/services/account.service';
import { TimerDirective } from 'src/app/directives/timer.directive';

const provider = (window as { [key: string]: any })['ethereum'] as string;
const web3 = new Web3(provider || environment.provider);

@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.css'],
})

/**
 * Export Collections
 */
export class TradeComponent implements OnInit {
  @Input() nft: any = {};
  @Input() ektaPrice: any = {};
  @Input() userBids: any = [];
  @Input() isOwnerWallet: boolean = false;
  @ViewChild('amountFormRef') amountFormRef: FormGroupDirective | undefined;
  @ViewChild('closeBuyNowModal') closeBuyNowModal:any;
  @ViewChild('closeOfferModal') closeOfferModal:any;
  @ViewChild('closeRemoveModal') closeRemoveModal:any;
  @ViewChild('closeCancelModal') closeCancelModal:any;
  @ViewChild('openOfferModal') openOfferModal:any;
  @ViewChild('openBuyNowModalButton') openBuyNowModalButton:any;
  public amountSubmitted: boolean;
  public currentNftDetails: any = {};
  public timer: any;
  public ektaScan: string = '';
  public isCancelBidModalShown: boolean = false;
  public currentSaleTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  @Input() account: any = {};
  public isDoYouHaveNftToggle = true;
  public tradeLoader: boolean = false;
  public isBuyNftShown = false;
  public txHash: string = '';
  public amountForm: FormGroup;
  public progressStatus: any = {
    'C': 'YTS',
    'A': 'YTS',
    'S': 'YTS',
    'B': 'YTS',
  };
  public isBuyNftShownBuy: boolean;
  public isBuyerBlacklisted: boolean = false;
  public isSellerBlacklisted: boolean = false;
  public sellerAddress: string = '';
  public isRemoveSaleModalShown: boolean = false;
  public isRemoveSale: boolean = false;
  public isPutForSaleShown: boolean = false;
  public user: any = {};
  public balance: number = 0;
  public timerValue:any;
  public showClose:boolean = false;

  /**
   * constructor
   */
  constructor(
    private toastr: ToastrService,
    private tradeService: TradeService,
    private router: Router,
    private exchangeContractService: ExchangeContractService,
    private formBuilder: FormBuilder,
    private tradeContractService: TradeContractService,
    private commonService: CommonService,
    private storage: StorageService,
    private accountService: AccountService,
    private walletConnectService:WalletConnectService,
    private web3Service:Web3Service,
    private timerDirective:TimerDirective,
  ) {
    this.amountSubmitted = false;
    this.isBuyNftShownBuy = false;
    this.amountForm = this.formBuilder.group({
      amount: ['', Validators.required],
    });
  }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.ektaScan = environment.ekta_scan;
    // Set connection for metamask package
    const data = {
      'chainId': environment.BSC_CHAINID,
      'rpcUrl': environment.ETH_PROVIDER,
    };
    this.web3Service.connect(data);
  }

  /**
   * getter for amount form
   */
  get f() {
    return this.amountForm.controls;
  }

  /**
   * Update validation
   */
  private updateValidation(): void {
    if (this.userBids.length === 0) {
      this.amountForm.controls['amount'].clearValidators();
      if (this.currentNftDetails?.saleType === 'TimedBid' && this.currentNftDetails?.lastBid) {
        this.amountForm.controls['amount'].addValidators(gte(this.currentNftDetails?.lastBid));
      }
      if ((this.currentNftDetails?.saleType === 'TimedBid' && !this.currentNftDetails?.lastBid) || this.currentNftDetails?.saleType === 'Bid') {
        this.amountForm.controls['amount'].addValidators(gte(this.currentNftDetails?.price));
      }
    } else {
      this.amountForm.controls['amount'].clearValidators();
      this.amountForm.controls['amount'].addValidators(gte(this.userBids[0]?.bidAmount));
    }
    this.amountForm.controls['amount'].updateValueAndValidity();
  }

  /**
   * Triggered on changes
   * @param {SimpleChanges} changes
   */
  public async ngOnChanges(changes: SimpleChanges) {
    if (changes['nft']?.currentValue !== changes['nft']?.previousValue) {
      this.currentNftDetails = changes['nft']?.currentValue;

      if (Object.keys(this.currentNftDetails)?.length > 0) {
        this.updateValidation();
        if (this.currentNftDetails.saleType !== 'Buy') this.getTimeForSale();
        if (this.currentNftDetails.isSold === 1 || this.currentNftDetails.isPendingConfirmation === 1) {
          this.toastr.error('Sold out!');
        } else if (!this.currentNftDetails.isSaleRemoved && this.currentNftDetails.forSale === 0) {
          this.toastr.error('Nft is not listed for sale yet');
        }
      }
    }

    if (Object.keys(this.currentNftDetails)?.length > 0 && Object.keys(this.account)?.length > 0) {
      // if buyer is blacklisted
      this.isBlacklisted(this.account.walletAddress, '1');
      if (!this.currentNftDetails.nftSale) {
        this.sellerAddress = web3.utils.toChecksumAddress(this.currentNftDetails.partnerAddress);
      } else {
        this.sellerAddress = web3.utils.toChecksumAddress(this.currentNftDetails.nftSellerAddress);
      }

      // if seller is blacklisted after put an order
      this.isBlacklisted(this.sellerAddress, '2');
    }

    if (Object.keys(this.currentNftDetails)?.length > 0 && changes['userBids']?.currentValue) {
      this.updateValidation();
    }

    if (Object.keys(this.account)?.length > 0) {
      this.user = this.storage.get('user');
    }
  }

  /**
   * Get time for sale
   */
  public async getTimeForSale() {
    // bid not yet started
    if (!this.checkIfBidStarted(this.nft?.bidStartTime)) {
      this.timer = setInterval(() => {
        this.currentSaleTime = CommonHelper.getTimerForSale(moment(this.currentNftDetails?.bidStartTime).unix());
        if (this.currentSaleTime.hours == 0 &&
          this.currentSaleTime.minutes == 0 &&
          this.currentSaleTime.seconds == 0 &&
          this.currentSaleTime.days == 0
        ) {
          clearInterval(this.timer);
          this.getTimeForSale();
        }
        if (this.currentSaleTime.hours < 0 ||
          this.currentSaleTime.minutes < 0 ||
          this.currentSaleTime.seconds < 0 ||
          this.currentSaleTime.days < 0
        ) {
          this.currentSaleTime = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
          clearInterval(this.timer);
          return;
        }
      }, 1000);
    }
    // bid started but not ended
    if (!this.checkIfBidEnded(this.nft?.bidEndTime) && this.checkIfBidStarted(this.nft?.bidStartTime)) {
      this.timer = setInterval(() => {
        this.currentSaleTime = CommonHelper.getTimerForSale(moment(this.currentNftDetails?.bidEndTime).unix());
      }, 1000);
    }
  }

  /**
   * destructor for class
   */
  public ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  /**
   *
   * @param {string} type
   */
  public onContinue(type: string) {
    if (this.currentNftDetails?.lastBid > 0) {
      if (this.currentNftDetails?.lastBid >= this.amountForm.value?.amount) {
        this.toastr.error('Amount should be greater than last highest Offer');
        return;
      }
    }
    this.amountSubmitted = true;
    this.showClose = true;
    if (this.amountForm.valid) {
      if (this.isDoYouHaveNftToggle) {
        if (type === 'Buy') {
          if (this.account.walletAddress != '' && this.account.walletAddress != undefined && this.account.walletAddress != null) {
            this.openBuyNowModalButton.nativeElement.click();
            this.buyNft();
          } else {
            this.toastr.error('Please Connect Wallet');
            return;
          }
        } else {
          this.openOfferModal.nativeElement.click();
          this.toggleBidPurchase(true);
          this.bidNft(type);
        }
      } else this.router.navigate(['/buy-ekta']);
    }
  }

  /**
   * Gets token type
   * @param {string} type
   * @return {string}
   */
  public getTokenType(type: string) {
    return this.exchangeContractService.getTokenType(type);
  }

  /**
   * Gets sale type
   * @param {string} type
   * @return {string}
   */
  public getSaleType(type: string) {
    return this.exchangeContractService.getSaleType(type);
  }

  /**
   * Connects wallet
   */
  public connectWallet() {
    const data = {
      status: true,
    };
    this.accountService.openWalletModal(data);
  }

  /**
   * Buys nft
   */
  public async buyNft() {
    this.isBuyNftShown = true;
    this.tradeLoader = true;
    this.isBuyNftShownBuy = true;
    try {
      const order = await this.organizeOrder('Buy');
      const tradeEncodedAbi = await this.exchangeContractService.completeFixedSale(order);

      const message = {
        method: 'eth_sendTransaction',
        from: this.account.walletAddress,
        to: environment.exchange_contract,
        data: tradeEncodedAbi,
        value: order?.price,
      };
      web3.eth.sendTransaction(message)
          .on('transactionHash', (hash) => {
            this.txHash = hash;
          })
          .on('receipt', async (receipt) => {
            const reqBody = {
              nftId: this.nft._id,
              buyerId: this.account.walletAddress,
              sellerId: !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
              txHash: this.txHash,
              saleType: this.nft.saleType,
            };
            this.tradeService.buyNft(reqBody).subscribe({
              next: (response: any) => {
                this.tradeLoader = false;
                this.showClose = false;
                this.tradeService.sendBidTrigger(true);
                this.closeBuyNowModal.nativeElement.click();
                this.toastr.success(`You have purchased ${this.nft?.name} #${this.nft?.tokenId} for ${this.nft?.price} BNB`);
              },
              error: (error) => {
              },
            });
          })
          .on('error', (error: any) => {
            this.showClose = false;
            if (error.code === 4001) this.toastr.error('User rejected');
            else this.toastr.error('Transaction Failed');
            this.tradeLoader = false;
            setTimeout(() => {
              this.closeBuyNowModal.nativeElement.click();
            }, 100);
          });
    } catch (error: any) {
      this.showClose = false;
      this.tradeLoader = false;
      this.toastr.error(error['message']);
      setTimeout(() => {
        this.closeBuyNowModal.nativeElement.click();
      }, 100);
    }
  }


  /**
   * Bids nft
   * @param {string} type
   */
  public async bidNft(type: string) {
    this.tradeLoader = true;
    this.toggleBidPurchase(true);
    const enteredAmount = Number(web3.utils.toWei(this.amountForm.value?.amount.toString(), 'ether'));
    try {
      // step 1 - convert BNB to WBNB
      this.progressStatus.C = 'I';
      await this.convertEktaToWekta(enteredAmount);
      this.progressStatus.C = 'C';

      // Step 2 - Approve Exchange contract
      this.progressStatus.A = 'I';
      await this.approveExchangeContract(enteredAmount);
      this.progressStatus.A = 'C';

      // Step 3 - Generate signature
      this.progressStatus.S = 'I';
      const signature = await this.generateSignature(type);
      this.progressStatus.S = 'C';

      // Step 4 - API call
      this.progressStatus.B = 'I';
      // Edit Bid
      let nftForBid;
      if (this.userBids?.length > 0) {
        nftForBid = this.tradeService.editBid(this.userBids[0]?._id, {
          'signature': signature,
          'bidAmount': this.amountForm.value?.amount,
        });
      } else {
        nftForBid = this.tradeContractService.bifNft({
          'nftId': this.currentNftDetails?._id,
          'buyerId': this.account.walletAddress,
          'signature': signature,
          'bidAmount': this.amountForm.value?.amount,
          'sellerId': !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),

        });
      }
      nftForBid.subscribe({
        next: (response: any) => {
          this.progressStatus.B = 'C';
          this.tradeLoader = false;
          this.showClose = false;
          this.tradeService.sendBidTrigger(true);
          this.toastr.success(`You have placed a Offer on ${this.nft?.name} #${this.nft?.tokenId} for ${this.f.amount.value} BNB`);
        },
        error: (error) => {
          this.progressStatus.B = 'E';
        },
      });
    } catch (error: any) {
      this.toggleBidPurchase(false);
      this.tradeLoader = false;
      this.showClose = false;
      if (error.code === 4001) this.toastr.error('User rejected');
      else this.toastr.error('Transaction failed');
      setTimeout(() => {
        this.closeOfferModal.nativeElement.click();
      }, 100);
    }
  }

  /**
   * Converts BNB to wBNB
   * @param {number} enteredAmount
   */
  public async convertEktaToWekta(enteredAmount: number) {
    // Check if account has enough weka balance
    const balance = await this.tradeContractService.getWektaBalance(this.account.walletAddress);
    this.balance = Number(web3.utils.fromWei(balance.toString(), 'ether'));
    // if balance insufficient, convert BNB to wBNB
    if (balance < enteredAmount) {
      const depositeEncode = await this.tradeContractService.depositWekta();
      const message: any = {
        method: 'eth_sendTransaction',
        from: this.account.walletAddress,
        to: environment.wbnb_contract,
        data: depositeEncode,
        value: enteredAmount - balance,
      };
      if (this.accountService.walletConnectType()) {
        await this.walletConnectService.send(message);
      } else {
        const message = {
          method: 'eth_sendTransaction',
          fromAddress: this.account.walletAddress,
          toAddress: environment.wbnb_contract,
          abi: depositeEncode,
          value: enteredAmount - balance,
        };
        await this.web3Service.sendTransaction(message);
      }
    }
  }


  /**
   * Approves exchange contract
   * @param {number} enteredAmount
   */
  public async approveExchangeContract(enteredAmount: number) {
    // Check if account has already approved exhange contract
    // const allowance = await this.tradeContractService.getAllownace(this.account.walletAddress, environment.exchange_contract);

    // if allowance is insufficient, trigger approve
    // if (allowance < enteredAmount) {
    // const approveAmount = allowance + enteredAmount;
    const approveEncode = await this.tradeContractService.approve(environment.exchange_contract, enteredAmount.toString());
    const approveParams: any = {
      method: 'eth_sendTransaction',
      from: this.account.walletAddress,
      to: environment.wbnb_contract,
      data: approveEncode,
    };
    if (this.accountService.walletConnectType()) {
      await this.walletConnectService.send(approveParams);
    } else {
      const approveParams: any = {
        method: 'eth_sendTransaction',
        fromAddress: this.account.walletAddress,
        toAddress: environment.wbnb_contract,
        abi: approveEncode,
      };
      await this.web3Service.sendTransaction(approveParams);
    }
  }

  /**
   * Generates signature
   * @param {string} type
   */
  public async generateSignature(type: string) {
    const orderParams = await this.organizeOrder(type);
    const bidOrderParams = await this.commonService.BidOrder(orderParams, this.account.walletAddress, this.amountForm.value?.amount);

    const param = JSON.stringify({
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        BidOrder: [
          { name: 'tokenType', type: 'bytes4' },
          { name: 'saleType', type: 'bytes4' },
          { name: 'seller', type: 'address' },
          { name: 'tokenAddress', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'tokenAmount', type: 'uint256' },
          { name: 'price', type: 'uint256' },
          { name: 'startTime', type: 'uint256' },
          { name: 'endTime', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'buyer', type: 'address' },
          { name: 'bidAmount', type: 'uint256' },
        ],
      },
      primaryType: 'BidOrder',
      domain: {
        name: 'Order',
        version: '1',
        chainId: environment.BSC_CHAINID,
        verifyingContract: environment.exchange_contract,
      },
      message: bidOrderParams,
    });

    if (this.accountService.walletConnectType()) {
      const params = [this.account.walletAddress, param, environment.exchange_contract];
      const signature = await this.walletConnectService.signMessage(params).then((response:any) => {
      },
      (error) => {
        this.toastr.error(error);
      });
      return signature;
    } else {
      const signature = await (window as { [key: string]: any })['ethereum'].request(
          {
            method: 'eth_signTypedData_v4',
            params: [this.account.walletAddress, param],
          },
      );
      return signature;
    }
  }

  /**
   * Organizes order
   * @param {string} type
   */
  public async organizeOrder(type: string) {
    let order;
    if (type === 'Buy') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
        this.nft?.nftCollection?.collectionAddress,
        Number(this.nft?.tokenId),
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        0, // start time is always 0 for buy
        0, // end time is always 0 for buy
        this.nft?.nonce,
      );
    }
    if (type === 'Bid') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
        this.nft?.nftCollection?.collectionAddress,
        Number(this.nft?.tokenId),
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        moment(this.nft?.bidStartTime).unix(),
        0, // end time is always 0 for bid
        this.nft?.nonce,
      );
    }
    if (type === 'TimedBid') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
        this.nft?.nftCollection?.collectionAddress,
        Number(this.nft?.tokenId),
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        moment(this.nft?.bidStartTime).unix(),
        moment(this.nft?.bidEndTime).unix(),
        this.nft?.nonce,
      );
    }
    return order;
  }

  isBuyNowModalShown = false;

  /**
   * Open buy now modal
   */
  public openBuyNowModal() {
    if (Object.keys(this.account)?.length > 0 && this.account.walletAddress != '') this.isBuyNowModalShown = true;
    else this.toastr.error('Connect Wallet to proceed');
  }

  /**
   * Cancels bid
   */
  public cancelBid() {
    this.isCancelBidModalShown = !this.isCancelBidModalShown;
  }

  /**
   * Closes bid
   */
  public closeBid() {
    this.isCancelBidModalShown = false;
    const reqBody = {
      'isCancelled': true,
    };
    this.tradeService.cancelBid(this.userBids[0]?._id, reqBody).subscribe((response: any) => {
      this.closeCancelModal.nativeElement.click();
      this.toastr.success('Offer Cancelled');
      this.tradeService.sendBidTrigger(true);
    });
  }


  /**
   * Removes sale
   * @param {string} type
   */
  public async removeSale(type: string) {
    try {
      this.isRemoveSale = true;
      const order = await this.organizeOrder(type);
      const cancelOrderAbi = await this.exchangeContractService.cancelOrder(order);
      const message: any = {
        method: 'eth_sendTransaction',
        from: this.account.walletAddress,
        to: environment.exchange_contract,
        data: cancelOrderAbi,
      };

      if (!this.accountService.walletConnectType()) {
        web3.eth.sendTransaction(message)
            .on('receipt', async (receipt) => {
              // api call
              this.tradeService.cancelSale(this.nft._id).subscribe({
                next: (response: any) => {
                  this.isRemoveSaleModalShown = false;
                  this.isRemoveSale = false;
                  this.toastr.success(`You have removed ${this.nft?.name} #${this.nft?.tokenId} from sale`);
                  this.tradeService.sendSaleStatus(true);
                  this.closeRemoveModal.nativeElement.click();
                },
                error: (error) => {
                  this.isRemoveSale = false;
                  this.toastr.success(`Failed to remove ${this.nft?.name} #${this.nft?.tokenId} from sale`);
                  this.closeRemoveModal.nativeElement.click();
                },
              });
            })
            .on('error', (error: any) => {
              this.isRemoveSale = false;
              this.closeRemoveModal.nativeElement.click();
              if (error.code === 4001) this.toastr.error('User rejected');
              else this.toastr.error(error['message']);
            });
      }
    } catch (error: any) {
      this.toastr.error(error['message']);
    }
  }

  /**
   * Go to profile
   */
  public goToProfile() {
    this.closeBuyNowModal.nativeElement.click();
    this.toggleBidPurchase(false);
    this.router.navigate(['/profile']);
  }
  /**
   * Submit amount
   */
  public submitAmount() {
    this.amountSubmitted = true;
    if (this.amountForm.valid) {
      this.openBuyNowModal();
    }
  }

  /**
   * Check if bid is ended
   *
   * @param {string} date
   * @return {boolean}
   */
  public checkIfBidEnded(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }

  /**
 * Check if bid is started
 *
 * @param {string} date
 * @return {boolean}
 */
  public checkIfBidStarted(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }

  /**
   * Toggles bid purchase
   * @param {boolean} value
   */
  public toggleBidPurchase(value: boolean) {
    this.isBuyNftShown = value;
    this.resetProgress();
  }

  /**
   * Determines whether blacklisted is
   * @param {string} walletAddress
   * @param {string} type
   */
  public async isBlacklisted(walletAddress: string, type: string) {
    if (type == '1') {
      this.isBuyerBlacklisted = await this.exchangeContractService.isBlacklisted(walletAddress);
    } else {
      this.isSellerBlacklisted = await this.exchangeContractService.isBlacklisted(walletAddress);
    }
  }

  /**
   * Resets progress
   */
  public resetProgress() {
    this.progressStatus = {
      'C': 'YTS',
      'A': 'YTS',
      'S': 'YTS',
      'B': 'YTS',
    };
  }

  /**
   * Bids complete
   */
  public bidComplete() {
    this.closeOfferModal.nativeElement.click();
    this.toggleBidPurchase(false);
    this.amountSubmitted = false;
    this.amountForm.reset();
  }

  /**
   * Restricts scroll
   * @return  {boolean}
   */
  public restrictScroll() {
    return false;
  }

  /**
   * showPutForSale
   *
   * @param {any} nft
   */
  public showPutForSale() {
    this.isPutForSaleShown = true;
  }
}
