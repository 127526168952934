<!-- content begin -->
<!-- <div class="no-bottom" id="content">
    <div id="top"></div>
    <div class="search-section container">
        <div class="search">
            <img src="assets/images/search.svg" class="w-3 h-3 md:w-4 md:h-4" alt="search">
            <input type="text" id="footer-field" name="footer-input"
                class="pl-4 w-full fw-regular focus:outline-none outline-none text-sm partner-search"
                placeholder="Search by NFT Launchpad" [(ngModel)]="collectionKeywordSearch" (keyup)="searchCallback()">
        </div>
    </div> -->

    <!-- section begin -->
    <section class="top-collections collections-page">
        <div class="container">
            <div class="bg-gradient-1 bg-right-top"></div>
            <div class="bg-gradient-2 bg-right-top"></div>
            <div class="bg-gradient-1 bg-left-bottom"></div>

            
            <div class="search-section d-flex justify-content-between align-items-center">
                <div class="text-left title-wrap">
                    <h2 class="pb-0">NFT Launchpad</h2>
                </div>
                <div class="search">
                    <img src="assets/images/search.png" class="w-3 h-3 md:w-4 md:h-4" alt="search">
                    <input type="text" id="footer-field" name="footer-input"
                            class="pl-4 w-full fw-regular focus:outline-none outline-none text-sm partner-search"
                            placeholder="Search by NFT Launchpad" [(ngModel)]="collectionKeywordSearch" (keyup)="searchCallback()">
                </div>
            </div>

            <div class="row wow fadeIn">
                <div class="col-md-3">
                    <app-nft-launchpad-filter (onPartnerSelect)="onPartnerSelectEvent($event)"
                        (onCategorySelect)="onCategorySelectEvent($event)"></app-nft-launchpad-filter>
                </div>
                <div class="col-md-9 text-center">
                        <div *ngIf="privateSales?.length == 0" class="no-image">
                            <img src="assets/images/no-image.svg" class="" alt="bx-nft">
                            <p class="flex justify-center">No NFT Launchpad Found</p>
                        </div>
                    <app-nft-launchpad-details *ngIf="privateSales?.length > 0" [privateSales]="privateSales"></app-nft-launchpad-details>
                </div>
            </div>

        </div>
    </section>

<!-- </div> -->