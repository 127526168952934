import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

/**
 * Export Class
 */
export class AppComponent {
  title = 'BlockchainX-NFT-Marketplace';
}
