import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CollectionContractService } from 'src/app/services/collection-contract.service';
import { CommonService } from 'src/app/services/common.service';
import { ExchangeContractService } from 'src/app/services/exchange-contract.service';
import { TradeService } from 'src/app/services/trade.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const provider = (window as { [key: string]: any })['ethereum'] as string;
const web3 = new Web3(provider || environment.provider);

@Component({
  selector: 'app-put-for-sale',
  templateUrl: './put-for-sale.component.html',
  styleUrls: ['./put-for-sale.component.css'],
})

/**
 * Export Class
 */
export class PutForSaleComponent implements OnInit {
  @Input() isPutForSaleShown: boolean = false;
  @Input() user: any = {};
  @Input() nft:any = {};
  @Output() closeEvent = new EventEmitter<string>();
  @ViewChild('orderProcess') orderProcess:any;
  @ViewChild('purchaseModalRef') purchaseModalRef:any;
  public saleFormSubmitted:boolean = false;
  public saleForm:any = this.formBuilder.group({
    price: ['', [Validators.required]],
    startDate: [''],
    startTime: [''],
    endDate: [''],
    endTime: [''],
  });
  public isSaleTypeChosen:boolean = true;
  public isSaleTypeChosenTwo:boolean = false;
  public isSaleTypeChosenThree:boolean = false;
  public putForSaleProgress = false;
  public progressStatus: any = {
    'AP': 'YTS',
    'SO': 'YTS',
    'CO': 'YTS',
    'COC': 'YTS',
  };
  public tradeLoader: boolean = false;
  public saleType:string = 'Buy';
  public previousDate = new Date(new Date(new Date().setHours(0, 0, 0, 0)).toISOString());
  public sellId:string = '';
  public ektaPrice: number = 0;
  public buyPriceValue: number = 0;
  public offerPriceValue: number = 0;
  public timeBidPriceValue: number = 0;

  /**
   * Creates an instance of put for sale component.
   */
  constructor(
    private formBuilder: FormBuilder,
    private tradeService: TradeService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private exchangeContractService: ExchangeContractService,
    private collectionContractService: CollectionContractService,
    private route: Router,
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.getEktaPrice();
  }
  /**
   * on changes
   * @param {SimpleChanges} changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isPutForSaleShown?.currentValue) {
      this.isPutForSaleShown = true;
    }
  }


  /**
   * getter for amount form
   */
  get saleFormControl() {
    return this.saleForm.controls;
  }

  /**
   * chooseSaleType
   *
   * @param {number} number
   */
  public chooseSaleType(number: number) {
    this.buyPriceValue = 0;
    this.offerPriceValue = 0;
    this.timeBidPriceValue = 0;
    this.saleForm.patchValue({
      price: '',
    });
    if (number == 1) {
      this.isSaleTypeChosen = true;
      this.isSaleTypeChosenTwo = false;
      this.isSaleTypeChosenThree = false;
      this.saleType = 'Buy';
      this.saleForm.controls['startDate'].removeValidators([Validators.required]);
      this.saleForm.controls['startTime'].removeValidators([Validators.required]);
      this.saleForm.controls['endDate'].removeValidators([Validators.required]);
      this.saleForm.controls['endTime'].removeValidators([Validators.required]);
    } else if (number == 2) {
      this.isSaleTypeChosenTwo = true;
      this.isSaleTypeChosen = false;
      this.isSaleTypeChosenThree = false;
      this.saleType = 'Bid';
      this.saleForm.controls['startDate'].addValidators([Validators.required]);
      this.saleForm.controls['startTime'].addValidators([Validators.required]);
      this.saleForm.controls['endDate'].removeValidators([Validators.required]);
      this.saleForm.controls['endTime'].removeValidators([Validators.required]);
    } else {
      this.isSaleTypeChosenThree = true;
      this.isSaleTypeChosen = false;
      this.isSaleTypeChosenTwo = false;
      this.saleType = 'TimedBid';
      this.saleForm.controls['startDate'].addValidators([Validators.required]);
      this.saleForm.controls['startTime'].addValidators([Validators.required]);
      this.saleForm.controls['endDate'].addValidators([Validators.required]);
      this.saleForm.controls['endTime'].addValidators([Validators.required]);
    }
    this.saleForm.controls['startDate'].updateValueAndValidity();
    this.saleForm.controls['startTime'].updateValueAndValidity();
    this.saleForm.controls['endDate'].updateValueAndValidity();
    this.saleForm.controls['endTime'].updateValueAndValidity();
  }

  /**
   * Restricts scroll
   * @return  {boolean}
   */
  public restrictScroll() {
    return false;
  }

  /**
   * Gets image source
   * @param {any} nft
   * @return {any}
   */
  public getImageSource(nft: any) {
    if (nft.nftImageS3) {
      return nft?.nftImageS3;
    } else if (nft?.nftCollection?.baseUrl) {
      return nft?.nftCollection?.baseUrl + nft.nftImage;
    } else {
      return nft.baseUrl + nft.nftImage;
    }
  }
  /**
   * put for sale form submit
   */
  public async onSaleFormSubmit() {
    try {
      this.saleFormSubmitted = true;
      if (!this.saleForm.valid) return;
      const bidStartTime = await this.commonService.getUnixTimestamp(this.saleFormControl.startDate.value, this.saleFormControl.startTime.value);

      // Validate start date time
      if (this.saleType === 'TimedBid' || this.saleType === 'Bid') {
        if (moment(bidStartTime).unix() < moment().utc().unix()) {
          this.toastr.error('Start date time must be greater than current UTC time');
          return;
        }
      }
      // If TimedBid need to validate if end time is greater than start time
      if (this.saleType === 'TimedBid') {
        const bidEndTime = await this.commonService.getUnixTimestamp(this.saleFormControl.endDate.value, this.saleFormControl.endTime.value);
        if (moment(bidStartTime).unix() > moment(bidEndTime).unix()) {
          this.toastr.error('End date time must be greater than start date time');
          return;
        }
      }
      this.purchaseModalRef.nativeElement.click();
      this.tradeLoader = true;
      this.putForSaleProgress = true;
      this.resetProgress();

      // step 1 - Approve exchange contract
      this.progressStatus.AP = 'I';
      await this.approveExchangeContract();
      this.progressStatus.AP = 'C';

      // step 2 - generate signature for order
      this.progressStatus.SO = 'I';
      const signature = await this.generateSignature(this.saleType);
      this.progressStatus.SO = 'C';

      // step 3 - create order in exchange contract
      this.progressStatus.CO = 'I';
      const order = await this.organizeOrder(this.saleType);
      const orderStatus = await this.checkOrderStatus(order);
      let tx: any = {};
      if (orderStatus == 0x00000000) {
        tx = await this.createOrder(order, signature);
      }
      this.progressStatus.CO = 'C';

      // step 4 - store in db
      this.progressStatus.COC = 'I';
      const params = {
        nftId: this.nft._id,
        nftSeller: this.user._id,
        nftSellerAddress: this.user.walletAddress,
        txHash: tx.transactionHash,
        forSale: 1,
        saleType: this.saleType,
        bidStartTime: order?.startTime ? order?.startTime * 1000 : 0,
        bidEndTime: order?.endTime ? order?.endTime * 1000 : 0,
        price: this.saleFormControl?.price.value,
        nonce: this.user?.nonce,
        signature,
        token_uri: this.nft.token_uri,
      };
      this.tradeService.placeSellOrder(params).subscribe({
        next: (response: any) => {
          this.progressStatus.COC = 'C';
          this.tradeLoader = false;
          this.sellId = response.data._id;
        },
        error: (error) => {
          this.progressStatus.COC = 'E';
        },
      });
    } catch (error: any) {
      this.putForSaleProgress = false;
      this.tradeLoader = false;
      this.orderProcess.nativeElement.click();
      if (error.code === 4001) this.toastr.error('User rejected');
      else this.toastr.error('Something went wrong. Try again after sometime');
    }
  }

  /**
   * Checks order status
   * @param {any} order
   */
  public async checkOrderStatus(order: any) {
    const abiEncode = web3.eth.abi.encodeParameters(['bytes4', 'bytes4', 'address', 'address', 'uint256', 'uint256', 'uint256', 'uint256', 'uint256', 'uint256'], Object.values(order));
    const hex = web3.utils.soliditySha3(abiEncode);
    return await this.exchangeContractService.getOrderStatus(hex);
  }

  /**
   * Creates order
   * @param {any} order
   * @param {string} signature
   */
  public async createOrder(order: any, signature: string) {
    const orderAbi = await this.exchangeContractService.createOrder(order, signature);
    const message: any = {
      method: 'eth_sendTransaction',
      from: this.user.walletAddress,
      to: environment.exchange_contract,
      data: orderAbi,
    };
    const tx = await web3.eth.sendTransaction(message);
    return tx;
  }

  /**
   * Approves exchange contract
   */
  public async approveExchangeContract() {
    let isApproved;
    // Check if account has already approved exhange contract
    if (this.nft.collectionAddress) {
      isApproved = this.nft?.nftType === 'ERC721' ?
      await this.collectionContractService.getERC721Approval(this.nft?.collectionAddress, this.nft?.tokenId) :
      await this.collectionContractService.getERC1155Approval(this.nft?.collectionAddress, this.user.walletAddress, environment.exchange_contract);
      if (!isApproved) {
        const approveEncodeAbi = this.nft?.nftType === 'ERC721' ?
          await this.collectionContractService.approveErc721(
              this.nft?.collectionAddress,
              environment.exchange_contract,
              this.nft?.tokenId,
          ) :
          await this.collectionContractService.approveErc1155(this.nft?.collectionAddress, environment.exchange_contract);

        const message: any = {
          method: 'eth_sendTransaction',
          from: this.user.walletAddress,
          to: this.nft?.collectionAddress,
          data: approveEncodeAbi,
        };
        await web3.eth.sendTransaction(message);
      }
    } else {
      isApproved = this.nft?.nftCollection?.nftType === 'ERC721' ?
      await this.collectionContractService.getERC721Approval(this.nft?.nftCollection?.collectionAddress, this.nft?.tokenId) :
      await this.collectionContractService.getERC1155Approval(this.nft?.nftCollection?.collectionAddress, this.user.walletAddress, environment.exchange_contract);
      if (!isApproved) {
        const approveEncodeAbi = this.nft?.nftCollection?.nftType === 'ERC721' ?
          await this.collectionContractService.approveErc721(
              this.nft?.nftCollection?.collectionAddress,
              environment.exchange_contract,
              this.nft?.tokenId,
          ) :
          await this.collectionContractService.approveErc1155(this.nft?.nftCollection?.collectionAddress, environment.exchange_contract);

        const message: any = {
          method: 'eth_sendTransaction',
          from: this.user.walletAddress,
          to: this.nft?.nftCollection?.collectionAddress,
          data: approveEncodeAbi,
        };
        await web3.eth.sendTransaction(message);
      }
    }
  }

  /**
   * Generates signature
   * @param {string} type
   */
  public async generateSignature(type: string) {
    const orderParams = await this.organizeOrder(type);

    const param = JSON.stringify({
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Order: [
          { name: 'tokenType', type: 'bytes4' },
          { name: 'saleType', type: 'bytes4' },
          { name: 'seller', type: 'address' },
          { name: 'tokenAddress', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'tokenAmount', type: 'uint256' },
          { name: 'price', type: 'uint256' },
          { name: 'startTime', type: 'uint256' },
          { name: 'endTime', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
        ],
      },
      primaryType: 'Order',
      domain: {
        name: 'Order',
        version: '1',
        chainId: environment.BSC_CHAINID,
        verifyingContract: environment.exchange_contract,
      },
      message: orderParams,
    });
    const signature = await (window as { [key: string]: any })['ethereum'].request(
        {
          method: 'eth_signTypedData_v4',
          params: [this.user.walletAddress, param],
        },
    );
    return signature;
  }

  /**
   * Resets progress
   */
  public resetProgress() {
    this.progressStatus = {
      'AP': 'YTS',
      'SO': 'YTS',
      'CO': 'YTS',
      'COC': 'YTS',
    };
  }

  /**
   * Organizes order
   * @param {string} type
   */
  public async organizeOrder(type: string) {
    let order;
    let nftType;
    let collectionAddress;
    if (this.nft?.nftType) {
      nftType = this.nft?.nftType;
      collectionAddress = this.nft?.collectionAddress;
    } else {
      nftType = this.nft?.nftCollection?.nftType;
      collectionAddress = this.nft?.nftCollection?.collectionAddress;
    }
    if (type === 'Buy') {
      order = await this.commonService.Order(
          await this.getTokenType(nftType),
          await this.getSaleType(this.saleType),
          web3.utils.toChecksumAddress(this.user?.walletAddress),
          collectionAddress,
          Number(this.nft?.tokenId),
          1, // TODO: handle tokenAmount for ERC1155
          this.saleFormControl?.price.value,
          0, // start time is always 0 for buy
          0, // end time is always 0 for buy
          this.user?.nonce || 0,
      );
    }
    if (type === 'Bid') {
      const bidStartTime = await this.commonService.getUnixTimestamp(this.saleFormControl.startDate.value, this.saleFormControl.startTime.value);
      order = await this.commonService.Order(
          await this.getTokenType(nftType),
          await this.getSaleType(this.saleType),
          web3.utils.toChecksumAddress(this.user?.walletAddress),
          collectionAddress,
          Number(this.nft?.tokenId),
          1, // TODO: handle tokenAmount for ERC1155
          this.saleFormControl?.price.value,
          moment(bidStartTime).unix(),
          0, // end time is always 0 for bid
          this.user?.nonce || 0,
      );
    }
    if (type === 'TimedBid') {
      const bidStartTime = await this.commonService.getUnixTimestamp(this.saleFormControl.startDate.value, this.saleFormControl.startTime.value);
      const bidEndTime = await this.commonService.getUnixTimestamp(this.saleFormControl.endDate.value, this.saleFormControl.endTime.value);
      order = await this.commonService.Order(
          await this.getTokenType(nftType),
          await this.getSaleType(this.saleType),
          web3.utils.toChecksumAddress(this.user?.walletAddress),
          collectionAddress,
          Number(this.nft?.tokenId),
          1, // TODO: handle tokenAmount for ERC1155
          this.saleFormControl?.price.value,
          moment(bidStartTime).unix(),
          moment(bidEndTime).unix(),
          this.user?.nonce || 0,
      );
    }
    return order;
  }

  /**
   * Gets token type
   * @param {string} type
   * @return  {string}
   */
  public getTokenType(type: string) {
    return this.exchangeContractService.getTokenType(type);
  }

  /**
   * Gets sale type
   * @param {string} type
   * @return  {string}
   */
  public getSaleType(type: string) {
    return this.exchangeContractService.getSaleType(type);
  }

  /**
   * Toggles put for sale progress
   * @param {boolean} value
   */
  public togglePutForSaleProgress(value: boolean) {
    this.putForSaleProgress = value;
  }

  /**
   * Navigates to sale
   * @param {boolean} value
   */
  public navigateToSale(value: boolean) {
    this.orderProcess.nativeElement.click();
    this.togglePutForSaleProgress(value);
    // routing to same url, need to reload page
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate(['/single-nft-details/' + this.sellId]);
  }

  /**
   * Convert Buy Price To Doller
   * @param {any} event
   */
  public convertBuyPriceToDoller(event:any) {
    this.buyPriceValue = +event.target.value;
  }
  /**
   * Convert Offer Price To Doller
   * @param {any} event
   */
  public convertOfferPriceToDoller(event:any) {
    this.offerPriceValue = +event.target.value;
  }
  /**
   * Convert TimedBid Price To Doller
   * @param {any} event
   */
  public convertTimedBidPriceToDoller(event:any) {
    this.timeBidPriceValue = +event.target.value;
  }
  /**
   * Get BNB Price
   */
  public getEktaPrice() {
    this.commonService.getEktaPrice().subscribe((response:any) => {
      this.ektaPrice = response['binancecoin'].usd;
    });
  }
}
