import { Component, OnInit } from '@angular/core';
import { LearnService } from 'src/app/services/learn.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css'],
})

/**
 * Export Class
 */
export class BlogsComponent implements OnInit {
  public blogDetails: any;
  blogDetailsPagenation: any;
  public pageLoader:boolean = false;


  /**
   * Creates an instance of blogs component.
   */
  constructor(
    public learnService:LearnService,
  ) {
    this.pageLoader = true;
  }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.getBlogList(1);
  }

  /**
   * Extracts content
   * @param {any} content
   * @return  {any}
   */
  public extractContent(content:any) {
    const span = document.createElement('span');
    span.innerHTML = content;
    return span.textContent || span.innerText;
  };


  /**
   * Gets blog list
   * @param {number} page
   */
  public getBlogList(page: number) {
    this.learnService.getLearnList(page).subscribe((response: any) => {
      this.blogDetails = response.data.data;
      this.blogDetailsPagenation = response.data;
      this.pageLoader = false;
      window.scrollTo(0, 0);
    });
  }

  /**
   * Blogs content
   * @param {any} content
   * @return  {any}
   */
  public blogContent(content:any) {
    const blogContent = this.extractContent(content);
    const blogContentDetails = blogContent.replace(/\s+/g, ' ').trim();
    return blogContentDetails.substring(0, 145);
  }
}
