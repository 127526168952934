import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { TimerDirective } from 'src/app/directives/timer.directive';


@Component({
  selector: 'app-nft-launchpad-details',
  templateUrl: './nft-launchpad-details.component.html',
  styleUrls: ['./nft-launchpad-details.component.css'],
})

/**
 * Export Class
 */
export class NftLaunchpadDetailsComponent {
  @Input() privateSales : any;
  @Input() isPartner : boolean = false;

  public timerValue: any;
  public currentSaleTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  /**
   * Creates an instance of nft launchpad details component.
   */
  constructor(
    private timerDirective:TimerDirective,
  ) {
  }
  /**
   * Timers nft details component
   * @param {any} date
   * @param {number} i
   */
  public currentSaleTimer(date:any, i : number) {
    const countDownDate = new Date(date * 1000);
    const countDownValue = document.getElementById('countDown'+i);
    setTimeout(() => {
      this.timerValue = this.timerDirective.getTimer(countDownDate);
      if (countDownValue) {
        countDownValue.innerHTML = this.timerValue;
      }
    }, 1000);
  }

  /**
 * Check if bid is Ented
 * @param {string} date
 * @return {boolean}
 */
  public checkIfEnted(date: any): boolean {
    const dateTime = new Date(date * 1000);
    return moment(new Date()).isAfter(dateTime);
  }

  /**
 * Check if bid is Started
 * @param {string} date
 * @return {boolean}
 */
  public checkIfStarted(date: any): boolean {
    const dateTime = new Date(date * 1000);
    return moment(dateTime).isAfter(new Date());
  }
}
