import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesRoutingModule } from './directives-routing.module';
import { ClickOutsideDirective } from './click-outside.directive';
import { LoadedDirective } from './loaded.directive';
import { TimerDirective } from './timer.directive';

@NgModule({
  declarations: [
    TimerDirective,
    LoadedDirective,
    ClickOutsideDirective,
  ],
  imports: [
    CommonModule,
    DirectivesRoutingModule,
  ],
  exports: [
    TimerDirective,
    LoadedDirective,
    ClickOutsideDirective,
  ],
  providers: [TimerDirective],
})

/**
 * Export Class
 */
export class DirectivesModule { }
