import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateSaleService } from 'src/app/services/private-sale.service';
import { ToastrService } from 'ngx-toastr';
import { PrivateSaleContractService } from 'src/app/services/private-sale-contract.service';
import { StorageService } from 'ng-blockchainx';
import { CommonService } from 'src/app/services/common.service';
import { CommonHelper } from 'src/app/helpers/common.helper';
import * as moment from 'moment';
import { AccountService } from 'src/app/services/account.service';
import Web3 from 'web3';
import { environment } from 'src/environments/environment';
import { ExchangeContractService } from 'src/app/services/exchange-contract.service';
import { Title } from '@angular/platform-browser';
import { TimerDirective } from 'src/app/directives/timer.directive';

const web3 = new Web3(environment.provider);

@Component({
  selector: 'app-single-launchpad-nft',
  templateUrl: './single-launchpad-nft.component.html',
  styleUrls: ['./single-launchpad-nft.component.css'],
})

/**
 * Export Collections
 */
export class SingleLaunchpadNftComponent implements OnInit, OnDestroy {
  public id: string | null = '';
  public collection: any;
  public timerValue:any;
  public quantity: any;
  public loading: boolean = false;
  public ektaPrice: number = 0;
  public auth: any;
  public isWhiteListingEnabled: boolean | unknown = false;
  public currentSaleTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  public timer:any;
  public saleStarted:boolean = false;
  public saleEnded: boolean = false;
  public userBalance: number = 0;
  public saleStatus: string = '';
  public accountSubscription: any;
  public isWhiteListed: boolean = false;
  public statusHandled: boolean = false;
  public isBuyerBlacklisted: boolean = false;
  public account: any;
  public pageLoader:boolean = false;

  /**
   * Creates an instance of single launchpad nft component.
   */
  constructor(
    private storage: StorageService,
    private privateSaleContract: PrivateSaleContractService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private privateSaleService: PrivateSaleService,
    private commonService: CommonService,
    private accountService: AccountService,
    private exchangeContractService: ExchangeContractService,
    private storageService:StorageService,
    private titleService:Title,
    private timerDirective:TimerDirective,
  ) {
    this.pageLoader = true;
  }

  /**
   * on init
   */
  public ngOnInit() {
    this.account = this.storageService.get('auth');
    this.accountService.accountObserve.subscribe((response) => {
      this.account = response.walletAddress;
    });
    this.commonService.getEktaPrice().subscribe((response:any) => {
      this.ektaPrice = response['binancecoin'].usd;
    });
    if (this.account?.chainId != environment.BSC_CHAINID) {
      this.pageLoader = false;
    }
    if (this.account) {
      this.isBlacklisted(this.account?.walletAddress);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.accountSubscription = this.accountService.accountObserve.subscribe(async (response) => {
      if (response.walletAddress != '') {
        if (response.walletAddress !== this.account?.walletAddress) {
          this.auth = response;
          this.isBlacklisted(this.auth.walletAddress);
        }
      }
    });
    this.getSale();
  }

  /**
   * on destroy
   */
  public ngOnDestroy(): void {
    clearInterval(this.timer);
    this.accountSubscription.unsubscribe();
  }

  /**
   * Connects wallet
   */
  public connectWallet() {
    const data = {
      status: true,
    };
    this.accountService.openWalletModal(data);
  }
  /**
   * Gets sale
   */
  public getSale() {
    window.scroll(0, 0);
    this.privateSaleService.getCollection(this.id)
        .subscribe({
          next: async (response:any) => {
            this.collection = response['data'].privateSale;
            this.titleService.setTitle(this.collection.collectionName);
            this.isWhiteListingEnabled = await this.privateSaleContract.checkWhiteListingEnabled(this.collection.saleAddress);
            await this.handleSale();
            if (!this.saleEnded && this.isWhiteListingEnabled && this.auth?.walletAddress) {
              this.checkConnectedAddressWhiteListed();
            }
            this.userBalance = this.auth?.walletAddress ? await this.commonService.getEktaBalance(this.auth.walletAddress) : 0;
            this.pageLoader = false;
          },
          error: (error) => {
            this.pageLoader = false;
            this.toastr.error(error.message, 'Failed to fetch collections');
          },
        });
  }

  /**
   * Handles sale
   */
  public async handleSale() {
    const currentTime = moment().unix();
    if (currentTime > this.collection.saleStartTime && currentTime > this.collection.saleEndTime) {
      this.saleStarted = true;
      this.saleEnded = true;
      this.saleStatus = 'ended';
    } else if (currentTime > this.collection.saleStartTime) {
      this.saleStatus = 'live';
      this.saleStarted = true;
      this.getTimeForSale(this.collection.saleEndTime);
    } else if (currentTime < this.collection.saleStartTime) {
      this.saleStatus = 'upcoming';

      this.getTimeForSale(this.collection.saleStartTime);
    }
    this.statusHandled = true;
  }

  /**
   * Checks connected address white listed
   */
  public async checkConnectedAddressWhiteListed() {
    const whiteListed = await this.privateSaleContract.isWhiteListed(this.collection?.saleAddress, this.auth.walletAddress);
    if (!whiteListed) {
      this.toastr.error('Connected address not whitelisted for this sale');
      this.isWhiteListed = false;
    } else {
      this.isWhiteListed = true;
    }
  }

  /**
   * Determines whether blacklisted is
   * @param {string} walletAddress
   */
  public async isBlacklisted(walletAddress: string) {
    this.isBuyerBlacklisted = await this.exchangeContractService.isBlacklisted(walletAddress);
  }

  /**
   * Buys now
   */
  public async buyNow() {
    if (!this.auth) {
      this.toastr.error('Connect Wallet to proceed');
      return;
    }
    if (!this.quantity) {
      this.toastr.error('Quantity field is required'); return;
    }
    if (this.quantity > this.collection?.leftNftCount) {
      this.toastr.error('Not Enough NFT'); return;
    }
    this.loading = true;
    const price = web3.utils.toWei(((Number(this.quantity) * Number(this.collection?.price)).toString()), 'ether');

    const buyNow: any = await this.privateSaleContract.buyToken(this.auth.walletAddress, this.collection?.saleAddress, price).catch((error:any) => {
      this.loading = false;
      if (error?.code == 4001) {
        this.toastr.error('User rejected');
      }
    });
    if (buyNow) {
      const user:any = this.storage.get('user');
      const reqBody = {
        'userId': user?._id,
        'collectionId': this.id,
        'nftCount': this.quantity,
        'totalPrice': this.collection?.price,
        'price': this.collection?.price,
        'txhash': buyNow.transactionHash,
      };
      this.privateSaleService.buyPrivateSaleNft(reqBody)
          .subscribe((response:any) => {
            this.getSale();
            this.quantity = '';
            this.toastr.success('Transaction completed successfully');
          });
    }
    this.loading = false;
  }

  /**
   * Gets time for sale
   * @param {number} time
   */
  public getTimeForSale(time: number):any {
    this.currentSaleTime = CommonHelper.getTimerForSale(time);
    this.timer = setInterval(()=>{
      this.currentSaleTime = CommonHelper.getTimerForSale(time);

      if (this.currentSaleTime.hours == 0 &&
        this.currentSaleTime.minutes == 0 &&
        this.currentSaleTime.seconds == 0 &&
        this.currentSaleTime.days == 0
      ) {
        clearInterval(this.timer);
        if (this.saleStarted) {
          this.saleEnded = true;
          this.saleStatus = 'ended';
        } else {
          this.saleStarted = true;
          this.saleStatus = 'live';
          this.getTimeForSale(this.collection.saleEndTime);
        }
      }
    }, 1000);
  }

  /**
   * Timers nft details component
   * @param {any} date
   */
  public currentSaleTimer(date:any) {
    const countDownDate = moment.unix(date);
    setTimeout(() => {
      this.timerValue = this.timerDirective.getTimer(countDownDate);
      if (!this.timerValue) {
        this.timerValue = '';
        return;
      }
    }, 1000);
  }
}
