import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError, catchError, retry, lastValueFrom } from 'rxjs';


import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import * as moment from 'moment';

const web3 = new Web3(environment.provider);


@Injectable({
  providedIn: 'root',
})

/**
 * Export Class
 */
export class CommonService {
  /**
   * Creates an instance of common service.
   */
  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Handles error
   * @param {HttpErrorResponse} error
   * @return  {HttpErrorResponse}
   */
  public handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => error.error);
  }

  /**
   * Gets ekta price
   * @return  {HttpErrorResponse}
   */
  public getEktaPrice() {
    return this.http.get(`https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=USD`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.handleError), // handle error
        );
  }

  /**
   * Gets user by wallet address
   * @param {string} walletAddress
   * @return  {HttpErrorResponse}
   */
  public getUserByWalletAddress(walletAddress: string) {
    const reqBody = {
      walletAddress: walletAddress,
    };
    return this.http.post(`${environment.api_url}/api/v1/get-user/wallet-address`, reqBody)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.handleError), // handle error
        );
  }

  /**
   * Gets ekta balance
   * @param {string} walletAddress
   * @return  {HttpErrorResponse}
   */
  async getEktaBalance(walletAddress: string) {
    const balance = await web3.eth.getBalance(walletAddress);
    return Number(web3.utils.fromWei(balance, 'ether'));
  }

  /**
   * Gets unix timestamp
   * @param {any} date
   * @param {string} time
   * @return  {HttpErrorResponse}
   */
  async getUnixTimestamp(date: any, time: string) {
    return moment.utc(moment(`${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()} ${new Date(time).getHours()}:${new Date(time).getMinutes()}:00`)).format();
  }

  /**
   * Validates file
   * @param {string} name
   * @return  {HttpErrorResponse}
   */
  public validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png') {
      return true;
    } else if (ext.toLowerCase() == 'jpeg') {
      return true;
    } else if (ext.toLowerCase() == 'jpg') {
      return true;
    } else if (ext.toLowerCase() == 'gif') {
      return true;
    } else if (ext.toLowerCase() == 'svg') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Orders common service
   * @param {string} tokenType
   * @param {string} saleType
   * @param {string} seller
   * @param {string} tokenAddress
   * @param {number} tokenId
   * @param {number} tokenAmount
   * @param {number} price
   * @param {number} startTime
   * @param {number} endTime
   * @param {number} nonce
   */
  async Order(tokenType: string, saleType: string, seller: string, tokenAddress: string, tokenId: number, tokenAmount: number, price: number, startTime: number, endTime: number, nonce: number) {
    return {
      tokenType,
      saleType,
      seller,
      tokenAddress,
      tokenId,
      tokenAmount,
      price: price > 0 ? await web3.utils.toWei(price.toString(), 'ether') : 0,
      startTime,
      endTime,
      nonce,
    };
  }

  /**
   * Bids order
   * @param {any} order
   * @param {string} buyer
   * @param {any} bidAmount
   */
  async BidOrder(order: any = {}, buyer: string, bidAmount: any) {
    return {
      ...order,
      buyer,
      bidAmount: await web3.utils.toWei(bidAmount.toString(), 'ether'),
    };
  }

  /**
   * Gets pre signed url
   * @param {string} type
   * @param {string} name
   * @param {string} contentType
   */
  async getPreSignedUrl(type: string, name: string, contentType: string) {
    const response = await this.http.post(`${environment.api_url}/api/v1/getPresignedUrl`, { type, name, contentType });
    return JSON.parse(JSON.stringify(await lastValueFrom(response)));
  }

  /**
   * Uploads to presigned url
   * @param {string} url
   * @param {any} file
   */
  async uploadToPresignedUrl(url: string, file: any) {
    const response = await this.http.put(url, file);
    return await lastValueFrom(response);
  }
}
