<app-loader *ngIf="pageLoader"></app-loader>
<div id="wrapper" *ngIf="!pageLoader">

    <!-- Nav Bar -->
    <app-nav-bar></app-nav-bar>
<!-- content begin -->
<!-- <div class="no-bottom no-top" id="content" *ngIf="!pageLoader">
    <div id="top"></div> -->

    <!-- section begin -->
    <!-- <section id="subheader" class="text-light background-img">
        <div class="center-y relative text-center">
            <div class="container">
                <div class="row">

                    <div class="col-md-12 text-center">                       
                        <h1>Blog</h1>
                        <p>Latest Blog & News</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- section close -->


    <!-- section begin -->
    <section aria-label="section" class="list-section">
        <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
            <div class="text-center title-wrap">
                <h2>Blog</h2>
                <p>Latest Blog & News</p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 mb30" *ngFor="let details of blogDetails; let i= index;">
                    <div class="bloglist item cursor-pointer" routerLink="/blog-details/{{details._id}}">
                        <div class="post-content">
                            <div class="post-image">
                                <img alt="" src="{{details.blogImage}}" class="lazy">
                            </div>
                            <div class="post-text">
                                <span class="p-date">{{details.updatedAt | date:'MM/dd/YYYY'}}</span>
                                <h4>{{details.title.substring(0,25)}}<span></span></h4>
                                <p>{{blogContent(details.content)}}...</p>
                                <a class="btn-style-one" routerLink="/blog-details/{{details._id}}">Read more</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer-single"></div>
                <ul class="pagination justify-content-center">
                    <li><a class="disabled cursor-pointer page-prew"
                            *ngIf="blogDetailsPagenation.pre_page == null">Prev</a></li>
                    <li><a class="cursor-pointer page-prew" *ngIf="blogDetailsPagenation.pre_page != null"
                            (click)="getBlogList(blogDetailsPagenation.pre_page)">Prev</a></li>
                    <li *ngIf="blogDetailsPagenation.pre_page != null && (blogDetailsPagenation.pre_page - 1) != 0 && (blogDetailsPagenation.pre_page - 1) != blogDetailsPagenation.page"
                        (click)="getBlogList(1)"><a class="cursor-pointer">1</a></li>
                    <li
                        *ngIf="blogDetailsPagenation.pre_page != null && (blogDetailsPagenation.pre_page - 1) != 0 && (blogDetailsPagenation.pre_page - 1) != 1">
                        <a class="cursor-pointer">.....</a></li>
                    <li><a class="cursor-pointer" *ngIf="blogDetailsPagenation.pre_page > 0"
                            (click)="getBlogList(blogDetailsPagenation.pre_page)">{{blogDetailsPagenation.pre_page}}</a>
                    </li>
                    <li class="active"><a class="cursor-pointer">{{blogDetailsPagenation.page}}</a></li>
                    <li><a class="cursor-pointer" *ngIf="blogDetailsPagenation.next_page > 0"
                            (click)="getBlogList(blogDetailsPagenation.next_page)">{{blogDetailsPagenation.next_page}}</a>
                    </li>
                    <li
                        *ngIf="blogDetailsPagenation.total_pages > blogDetailsPagenation.next_page && blogDetailsPagenation.next_page != null && (blogDetailsPagenation.next_page + 1) != blogDetailsPagenation.total_pages">
                        <a class="cursor-pointer">.....</a></li>
                    <li *ngIf="blogDetailsPagenation.total_pages > blogDetailsPagenation.next_page && blogDetailsPagenation.next_page != null"
                        (click)="getBlogList(blogDetailsPagenation.total_pages)"><a
                            class="cursor-pointer">{{blogDetailsPagenation.total_pages}}</a></li>
                    <li><a class="disabled cursor-pointer page-next"
                            *ngIf="blogDetailsPagenation.next_page == null">Next</a></li>
                    <li><a class="cursor-pointer page-next" *ngIf="blogDetailsPagenation.next_page != null"
                            (click)="getBlogList(blogDetailsPagenation.next_page)">Next</a></li>
                </ul>

            </div>
        </div>
    </section>


<app-footer></app-footer>
</div>