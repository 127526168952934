<app-loader *ngIf="pageLoader"></app-loader>
    <div id="wrapper" *ngIf="!pageLoader">
        <app-nav-bar></app-nav-bar>

        <!-- content begin -->
        <div class="single-nft-details" id="content">
            <section id="nft-item-details" aria-label="section" class="p-0">
                <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
                    <div class="row g-5">
                        <div class="col-md-6 text-center">
                            <div class="nft-image-wrapper">
                                <img [src]="[nft.nftImageS3 ? nft?.nftImageS3 : nft?.nftCollection?.baseUrl + nft.nftImage]"
                                    class="image-autosize img-fluid img-rounded mb-sm-30" alt="">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="item_info">

                                <app-trade [nft]="nft" [isOwnerWallet]="isOwnerWallet" [userBids]="userBids"
                                    [account]="account" [ektaPrice]="ektaPrice"></app-trade>
                                <app-trade-details [nft]="nft" [offers]="offers" [account]="account" [nftId]="nftId">
                                </app-trade-details>


                            </div>
                        </div>

                        <!-- Information -->
                        <div class="col-12 card">
                            <h3 class="text-base lg:text-xlg fw-semibold">Information</h3>
                            <div class="table-responsive">
                                <table class="w-full text-sm text-left">
                                    <thead>
                                        <tr>
                                            <th><span class="text-sm fw-regular">Contract Address</span></th>
                                            <th><span class="text-sm fw-regular">Token ID</span></th>
                                            <th><span class="text-sm fw-regular">Token Standard</span></th>
                                            <th><span class="text-sm fw-regular">Creator Fees</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="border-grey">
                                            <td><span class="text-base text-green fw-regular pt-1 truncate">
                                                    {{nft?.nftCollection?.collectionAddress}}</span></td>

                                            <td><span class="text-base text-green fw-regular pt-1 truncate">
                                                    {{nft?.tokenId}}</span></td>

                                            <td><span class="text-base text-green fw-regular pt-1 truncate">
                                                    {{nft?.nftCollection?.nftType}}</span></td>

                                            <td><span class="text-base text-green fw-regular pt-1 truncate">
                                                    {{nft?.nftCollection?.ektaFee}}%</span></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- Item Activity -->
                        <div class="col-12 card">
                            <h3 class="text-base lg:text-xlg fw-semibold">Item Activity</h3>
                            <div class="table-responsive">
                                <table class="w-full text-sm text-left">
                                    <thead class="text-sm fw-regular capitalize bg-light-white">
                                        <tr>
                                            <th scope="col" class="px-6 py-3 font-normal">
                                                Event
                                            </th>
                                            <th scope="col" class="px-6 py-3 font-normal">
                                                Price
                                            </th>
                                            <th scope="col" class="px-6 py-3 font-normal">
                                                From
                                            </th>
                                            <th scope="col" class="px-6 py-3 font-normal">
                                                To
                                            </th>
                                            <th scope="col" class="px-6 py-3 font-normal">
                                                Date
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="border-grey item-row" *ngFor="let activity of nftActivity">
                                            <td class="p-6 whitespace-nowrap">
                                                <div class="flex items-center gap-4">
                                                    <p class="text-base fw-regular tracking-wider">
                                                        <span *ngIf="activity.saleType == 'Bid'">
                                                            Offer
                                                        </span>
                                                        <span *ngIf="activity.saleType != 'Bid'">
                                                            {{activity.saleType}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </td>
                                            <td class="p-6">
                                                <span
                                                    class="flex items-center text-base fw-regular tracking-wider"
                                                    *ngIf="activity.saleType != 'Transfer'">
                                                    <p class="pl-1">{{activity.price || activity.bidAmount}}</p>
                                                </span>
                                                <span
                                                    class="flex items-center text-base fw-regular tracking-wider"
                                                    *ngIf="activity.saleType == 'Transfer'">
                                                    <p class="pl-1">-</p>
                                                </span>
                                            </td>
                                            <td class="p-6">
                                                <p class="text-green fw-semibold text-base" *ngIf="activity.sellerName">
                                                    {{activity.sellerName}}</p>
                                                <p class="text-green fw-semibold text-base" *ngIf="!activity.sellerName">
                                                    {{activity?.sellerAddress?.slice(0,
                                                    5)}}...{{activity?.sellerAddress.slice(activity?.sellerAddress.length -
                                                    5)}}</p>
                                            </td>
                                            <td class="p-6">
                                                <p class="text-green fw-semibold text-base" *ngIf="activity.buyerName">
                                                    {{activity.buyerName}}</p>
                                                <p class="text-green fw-semibold text-base" *ngIf="!activity.buyerName">
                                                    {{activity?.buyerAddress?.slice(0,
                                                    5)}}...{{activity?.buyerAddress.slice(activity?.buyerAddress.length -
                                                    5)}}</p>
                                            </td>
                                            <td class="p-6">
                                                <p class="text-base fw-regular tracking-wider">
                                                    {{activity.createdAt |
                                                    date:'dd/MM/YYYY'}}</p>
                                            </td>
                                        </tr>
                                        <tr class="border-grey" *ngIf="nftActivity.length == 0">
                                            <td colspan="5" class="text-center p-2">
                                                No Transactions found
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- About -->
                        <div class="col-12 card">
                            <h3 class="text-base lg:text-xlg fw-semibold">About</h3>
                            <div class="relative overflow-x-auto">
                                <p class="fw-regular text-sm lg:text-base line-clamp-2 mb-2">{{nft?.description}}
                                </p>
                                <div class="about-share">
                                    <a href="{{nft?.nftCollection?.websiteLink}}" target="_blank"><img
                                            src="assets/icons/link.svg" class="w-10 h-10" alt="Bx-Binance"></a>
                                    <a href="{{nft?.nftCollection?.discord}}" target="_blank"><img
                                            src="assets/icons/discord.svg" class="w-10 h-10" alt="Bx-Binance"></a>
                                    <a href="{{nft?.nftCollection?.instagram}}" target="_blank"><img
                                            src="assets/icons/insta.svg" class="w-10 h-10" alt="Bx-Binance"></a>
                                    <a href="{{nft?.nftCollection?.twitter}}" target="_blank"><img
                                            src="assets/icons/twitter.svg" class="w-10 h-10" alt="Bx-Binance"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
        <!-- content close -->

        
        <app-footer></app-footer>
    </div>