import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { TimerDirective } from 'src/app/directives/timer.directive';
import { CommonHelper } from 'src/app/helpers/common.helper';

@Component({
  selector: 'app-nft-launchpad-lists',
  templateUrl: './nft-launchpad-lists.component.html',
  styleUrls: ['./nft-launchpad-lists.component.css'],
})

/**
 * Export Class
 */
export class NftLaunchpadListsComponent {
  @Input() privateSales:any = [];
  @Output() onCategorySelectValue = new EventEmitter<any>();
  @Output() onPartnerSelectValue = new EventEmitter<any>();
  @Output() onCategorySearch = new EventEmitter<any>();
  public collection: any;
  public collectionKeywordSearch:string = '';
  public saleStatus: string = '';
  public saleStarted:boolean = false;
  public saleEnded: boolean = false;
  public statusHandled: boolean = false;
  public timerValue:any;
  public currentSaleTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  /**
   * Creates an instance of nft launchpad lists component.
   */
  constructor(
    private timerDirective:TimerDirective,
  ) {}

  /**
   *
   * @param {number} time
   * @return {object}
   */
  public getTimeForSale(time: number) {
    const countDownDate = moment.unix(time);
    setTimeout(() => {
      this.currentSaleTime = CommonHelper.getTimerForSale(countDownDate);
    }, 1000);
    return this.currentSaleTime;
  }

  /**
   * Determines whether partner select event on
   * @param {any} event
   */
  public onPartnerSelectEvent(event : any) {
    this.onPartnerSelectValue.emit(event);
  }
  /**
   * Determines whether partner select event on
   * @param {any} event
   */
  public onCategorySelectEvent(event : any) {
    this.onCategorySelectValue.emit(event);
  }

  /**
   * Timers nft details component
   * @param {any} date
   * @param {number} i
   */
  public currentSaleTimer(date:any, i : number) {
    const countDownDate = new Date(date * 1000);
    const countDownValue = document.getElementById('countDown'+i);
    setTimeout(() => {
      this.timerValue = this.timerDirective.getTimer(countDownDate);
      if (countDownValue) {
        countDownValue.innerHTML = this.timerValue;
      }
    }, 1000);
  }


  /**
 * Check if bid is Started
 * @param {string} date
 * @return {boolean}
 */
  public checkIfStartEnded(date: any): boolean {
    const dateTime = new Date(date * 1000);
    return moment(dateTime).isAfter(new Date());
  }

  /**
   * Search callback
   */
  public searchCallback() {
    this.onCategorySearch.emit(this.collectionKeywordSearch);
  }
}
