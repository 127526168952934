import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { catchError, retry } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrendingNftsService {

  constructor(private http: HttpClient, 
    private commonService: CommonService
  ) { }

  public getTrendingNfts() {
    return this.http.get(`${environment.api_url}/api/v1/trending-nfts`)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }


}
