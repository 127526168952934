import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionDetailsComponent } from './collection-details/collection-details.component';
import { NftDetailsComponent } from './nft-details/nft-details.component';
import { SingleNftDetailsComponent } from './single-nft-details/single-nft-details.component';

const routes: Routes = [
  { path: '', component: CollectionDetailsComponent },
  { path: 'nft-details/:id', component: NftDetailsComponent },
  { path: 'single-nft-details/:id', component: SingleNftDetailsComponent },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

/**
 * Export Class
 */
export class CollectionsRoutingModule { }
