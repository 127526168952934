import { Injectable } from '@angular/core';
import { WalletConnectService } from 'ng-blockchainx';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { AccountService } from './account.service';

const web3 = new Web3((window as { [key: string]: any })['ethereum'] as string || environment.provider);
const abi = require('../constants/abi/private-sale.json');

@Injectable({
  providedIn: 'root',
})
/**
 * Private sale contract service
 */
export class PrivateSaleContractService {
  /**
   * constructor
   */
  constructor(
    private accountService: AccountService,
    private walletConnectService:WalletConnectService,
  ) { }

  /**
   * check white listed
   * @param {string} address
   */
  async checkWhiteListingEnabled(address: string) {
    return new Promise(async (resolve, reject) => {
      const contract = new web3.eth.Contract(abi, address);
      const isWhiteListed = await contract.methods.enableWhitelisting().call();
      resolve(isWhiteListed);
    });
  }


  /**
   * Determines whether white listed is
   * @param {string} saleAddress
   * @param {string} walletAddress
   */
  async isWhiteListed(saleAddress: string, walletAddress: string) {
    return new Promise(async (resolve, reject) => {
      const contract = new web3.eth.Contract(abi, saleAddress);
      const isWhiteListed = await contract.methods.isWhitelisted(walletAddress).call();
      resolve(isWhiteListed);
    });
  }

  /**
   * Buys token
   * @param {string} fromAddress
   * @param {string} address
   * @param {string} value
   */
  async buyToken(fromAddress: string, address: string, value: string) {
    return new Promise(async (resolve, reject) => {
      const contract = new web3.eth.Contract(abi, address);
      const tradeEncodedAbi = await contract.methods.buyToken(fromAddress).encodeABI();
      const message = {
        method: 'eth_sendTransaction',
        from: fromAddress,
        to: address,
        data: tradeEncodedAbi,
        value: value,
      };
      if (this.accountService.walletConnectType()) {
        const transaction = await this.walletConnectService.send(message)
            .catch((error:any) => {
              reject(error);
            });
        resolve(transaction);
      } else {
        const transaction = await web3.eth.sendTransaction(message).catch((error:any) => {
          reject(error);
        });
        resolve(transaction);
      }
    });
  }
}

