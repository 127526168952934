import { Directive, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[appTimer]',
})

/**
 * Export Class
 */
export class TimerDirective {
  @Output() appTimer: EventEmitter<any> = new EventEmitter<any>();
  public timer:any;
  public timerObservable : any;
  /**
   * Creates an instance of timer directive.
   */
  constructor() {
    this.timer = new BehaviorSubject({
      timer: '',
    });
    this.timerObservable = this.timer.asObservable();
  }

  /**
  * on init
  */
  ngOnInit() {
    this.appTimer.emit('dummy');
  }

  /**
   * Gets timer
   * @param {any} date
   * @return {date}
   */
  public getTimer(date:any) {
    // Update the count down every 1 second
    // Get today's date and time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    const distance = date - now;

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    if (days <=0 && hours <= 0 && minutes <=0 && seconds <= 0) {
      days = 0;
      hours = 0;
      minutes = 0;
      seconds = 0;
      return false;
    }
    return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';

    // If the count down is over, write some text
  }
}
