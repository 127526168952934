import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3((window as { [key: string]: any })['ethereum'] as string || environment.provider);
const erc721Abi = require('../constants/abi/erc721.json');
const erc1155Abi = require('../constants/abi/erc1155.json');


@Injectable({
  providedIn: 'root',
})

/**
 * Export Class
 */
export class CollectionContractService {
  /**
   * Approves erc721
   * @param {string} collectionAddress
   * @param {string} spender
   * @param {number} tokenId
   */
  async approveErc721(collectionAddress: string, spender: string, tokenId: number) {
    const contract = new web3.eth.Contract(erc721Abi, collectionAddress);
    const approveEncodeAbi = await contract.methods.approve(spender, tokenId).encodeABI();
    return approveEncodeAbi;
  }

  /**
   * Approves erc1155
   * @param {string} collectionAddress
   * @param {string} spender
   */
  async approveErc1155(collectionAddress: string, spender: string) {
    const contract = new web3.eth.Contract(erc1155Abi, collectionAddress);
    const approveEncodeAbi = await contract.methods.setApprovalForAll(spender, true).encodeABI();
    return approveEncodeAbi;
  }

  /**
   * Gets erc721 approval
   * @param {string} collectionAddress
   * @param {number} tokenId
   */
  async getERC721Approval(collectionAddress: string, tokenId: number) {
    const contract = new web3.eth.Contract(erc721Abi, collectionAddress);
    const approvedAddress = await contract.methods.getApproved(tokenId).call();
    return await web3.utils.toChecksumAddress(approvedAddress) == await web3.utils.toChecksumAddress(environment.exchange_contract);
  }

  /**
   * Gets erc1155 approval
   * @param {string} collectionAddress
   * @param {string} owner
   * @param {string} spender
   */
  async getERC1155Approval(collectionAddress: string, owner: string, spender: string) {
    const contract = new web3.eth.Contract(erc1155Abi, collectionAddress);
    const isApproved = await contract.methods.isApprovedForAll(owner, spender).call();
    return isApproved;
  }
}
