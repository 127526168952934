<div class="modal fade" id="putForSaleModal" tabindex="-1" aria-labelledby="putForSaleModal" aria-hidden="true">
    <div class="modal-dialog  modal-xl put-for-sale modal-dialog-centered">
        <div class="modal-content">

            
<section class="put-for-sale-section">
  <div class="row">
    <div class="col-12 sm:pb-0">
      <div class="d-flex items-center">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          src="assets/icons/arrow-left.svg"
          class="me-4 cursor-pointer"
          alt="back-arrow"
        />
        <h2 class="text-lg xl:text-2xl fw-medium">Put for Sale</h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 put-for-sale-left-side">
      <p class="text-sm xl:text-base fw-regular pt-2">Choose Sale Type</p>
      <div class="d-flex flex-row justify-center gap-4 my-2 md:my-4">
        <div class="my-2 w-100">
          <button
            (click)="chooseSaleType(1)"
            [ngClass]="{ 'btn-active': isSaleTypeChosen }"
            class="button flex border w-100 gap-x-4 items-center justify-center h-16 rounded-xl border-light-grey border-solid text-base 2xl:text-lg md:px-4"
          >
            <img src="assets/icons/buy.png" class="block w-6 h-6" alt="" />
            <span class="block ms-4 fw-semibold">Buy </span>
          </button>
        </div>

        <div class="my-2 w-100">
          <button
            (click)="chooseSaleType(2)"
            [ngClass]="{ 'btn-active': isSaleTypeChosenTwo }"
            class="button border border-[#ff4744] bg-[#ffe6e6] flex w-100 gap-x-4 justify-center items-center h-16 border border-light-grey rounded-xl border-solid text-base 2xl:text-lg md:px-4"
          >
            <img src="assets/icons/bid.png" class="block w-6 h-6" alt="" />
            <span class="block ms-4 fw-semibold">Offer</span>
          </button>
        </div>
        <div class="my-2 w-100">
          <button
            (click)="chooseSaleType(3)"
            [ngClass]="{ 'btn-active': isSaleTypeChosenThree }"
            class="button border border-solid flex w-100 gap-x-4 justify-center items-center h-16 border-light-grey rounded-xl text-base 2xl:text-lg md:px-4"
          >
            <img
              src="assets/icons/time-check.png"
              class="block w-6 h-6"
              alt=""
            />
            <span class="block ms-4 fw-semibold"> Bid</span>
          </button>
        </div>
      </div>
      <!-- BEGIN: BUY || INPUT -->
      <form
        [formGroup]="saleForm"
        autocomplete="off"
        (submit)="onSaleFormSubmit()"
        class="flex flex-col h-full pb-8 sale-form"
      >
        <div *ngIf="isSaleTypeChosen">
          <label
            class="form-label"
            for="price"
            class="block mb-1 text-sm fw-regular"
          >
            Price <span class="text-red"> * </span> (${{
              (buyPriceValue * ektaPrice).toFixed(3)
            }})
          </label>
          <input
            type="number"
            placeholder="Price"
            id="price"
            class="form-control"
            class="form-control text-box"
            name="price"
            formControlName="price"
            (mousewheel)="restrictScroll()"
            min="0"
            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
            (keyup)="convertBuyPriceToDoller($event)"
          />
          <div *ngIf="saleFormSubmitted && saleFormControl?.price?.errors">
            <div
              *ngIf="saleFormControl?.price?.errors?.required"
              class="text-red"
            >
              Price is required
            </div>
          </div>
        </div>
        <div *ngIf="isSaleTypeChosenTwo">
          <label
            class="form-label"
            for="min-price"
            class="d-block mb-1 text-sm"
          >
            Minimum Price <span class="text-red"> * </span> (${{
              (offerPriceValue * ektaPrice).toFixed(3)
            }})
          </label>
          <input
            type="number"
            placeholder="Minimum Price"
            id="min-price"
            class="form-control"
            class="form-control text-box"
            formControlName="price"
            (mousewheel)="restrictScroll()"
            min="0"
            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
            (keyup)="convertOfferPriceToDoller($event)"
          />
          <div *ngIf="saleFormSubmitted && saleFormControl?.price?.errors">
            <div
              *ngIf="saleFormControl?.price?.errors?.required"
              class="text-red"
            >
              Minimum Price is required
            </div>
            <div *ngIf="saleFormControl?.price?.errors?.min" class="text-red">
              Minimum Price should not be in negative
            </div>
          </div>
        </div>
        <div class="d-flex gap-4 mt-4">
          <div *ngIf="isSaleTypeChosenTwo" class="w-100">
            <label
              class="form-label"
              for="bid-start-date"
              class="block mb-1 text-sm fw-regular"
            >
              Start Date <span class="text-red"> * </span>
            </label>
            <input
              [min]="previousDate"
              class="text-box form-control border border-grey border-solid text-sm fw-medium rounded-md block w-100 p-2.5 focus:outline-none focus:border focus:border-solid focus:border-primary"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              placeholder="Date"
              formControlName="startDate"
            />
            <owl-date-time
              [pickerType]="'calendar'"
              aria-owns="owl-dt-picker-0"
              #dt1
            ></owl-date-time>
            <div
              *ngIf="saleFormSubmitted && saleFormControl?.startDate?.errors"
            >
              <div
                *ngIf="saleFormControl?.startDate?.errors?.required"
                class="text-red pb-2"
              >
                Start date is required
              </div>
            </div>
          </div>
          <div *ngIf="isSaleTypeChosenTwo" class="w-100">
            <label
              class="form-label"
              for="bid-start-time"
              class="block mb-1 text-sm fw-regular"
            >
              Start Time <span class="text-red"> * </span>
              <span
                class="info-img tool"
                data-tip="Please enter time in local time we will covert it"
              >
                <img src="assets/images/info-circle.svg" alt="" />
              </span>
            </label>
            <input
              class="text-box form-control border border-grey border-solid text-sm fw-medium rounded-md block w-100 p-2.5 focus:outline-none focus:border focus:border-solid focus:border-primary"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              placeholder="Time"
              formControlName="startTime"
            />
            <owl-date-time
              [pickerType]="'timer'"
              [hour12Timer]="true"
              #dt1
            ></owl-date-time>
            <div
              *ngIf="saleFormSubmitted && saleFormControl?.startTime?.errors"
            >
              <div
                *ngIf="saleFormControl?.startTime?.errors?.required"
                class="text-red pb-2"
              >
                Start time is required
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isSaleTypeChosenThree">
          <label
            class="form-label"
            for="min-price"
            class="d-block mb-1 text-sm fw-regular"
          >
            Minimum Price <span class="text-red"> * </span> (${{
              (timeBidPriceValue * ektaPrice).toFixed(3)
            }})
          </label>
          <input
            type="number"
            placeholder="Minimum Price"
            id="min-price"
            class="text-box form-control"
            formControlName="price"
            name="price"
            (mousewheel)="restrictScroll()"
            min="0"
            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
            (keyup)="convertTimedBidPriceToDoller($event)"
          />
          <div *ngIf="saleFormSubmitted && saleFormControl?.price?.errors">
            <div
              *ngIf="saleFormControl?.price?.errors?.required"
              class="text-red"
            >
              Minimum Price is required
            </div>
            <div *ngIf="saleFormControl?.price?.errors?.min" class="text-red">
              Minimum Price should not be in negative
            </div>
          </div>
        </div>
        <div class="d-flex flex-col md:flex-row gap-4 mt-4">
          <div *ngIf="isSaleTypeChosenThree" class="w-100">
            <label
              class="form-label"
              for="bid-start-date"
              class="block mb-1 text-sm fw-regular"
            >
              Start Date <span class="text-red"> * </span>
            </label>
            <input
              [min]="previousDate"
              class="text-box form-control border border-grey border-solid text-sm fw-medium rounded-md block w-100 p-2.5 focus:outline-none focus:border focus:border-solid focus:border-primary"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              placeholder="Date"
              formControlName="startDate"
            />
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            <div
              *ngIf="saleFormSubmitted && saleFormControl?.startDate?.errors"
            >
              <div
                *ngIf="saleFormControl?.startDate?.errors?.required"
                class="text-red pb-2"
              >
                Start date is required
              </div>
            </div>
          </div>
          <div *ngIf="isSaleTypeChosenThree" class="w-100">
            <label
              class="form-label"
              for="bid-start-time"
              class="block mb-1 text-sm fw-regular"
            >
              Start Time <span class="text-red"> * </span>
              <span
                class="info-img tool"
                data-tip="Please enter time in local time we will covert it"
              >
                <img src="assets/images/info-circle.svg" alt="" />
              </span>
            </label>
            <input
              class="text-box form-control border border-grey border-solid text-sm fw-medium rounded-md block w-100 p-2.5 focus:outline-none focus:border focus:border-solid focus:border-primary"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              placeholder="Time"
              formControlName="startTime"
            />
            <owl-date-time
              [pickerType]="'timer'"
              [hour12Timer]="true"
              #dt1
            ></owl-date-time>
            <div
              *ngIf="saleFormSubmitted && saleFormControl?.startTime?.errors"
            >
              <div
                *ngIf="saleFormControl?.startTime?.errors?.required"
                class="text-red pb-2"
              >
                Start time is required
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-col md:flex-row gap-4 mt-4">
          <div *ngIf="isSaleTypeChosenThree" class="w-100">
            <label
              class="form-label"
              for="bid-end-date"
              class="block mb-1 text-sm fw-regular"
            >
              End Date <span class="text-red"> * </span>
            </label>
            <input
              [min]="previousDate"
              class="text-box form-control border border-grey border-solid text-sm fw-medium rounded-md block w-100 p-2.5 focus:outline-none focus:border focus:border-solid focus:border-primary"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              placeholder="Date"
              formControlName="endDate"
            />
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            <div *ngIf="saleFormSubmitted && saleFormControl?.endDate?.errors">
              <div
                *ngIf="saleFormControl?.endDate?.errors?.required"
                class="text-red pb-2"
              >
                End date is required
              </div>
            </div>
          </div>
          <div *ngIf="isSaleTypeChosenThree" class="w-100">
            <label
              class="form-label"
              for="bid-end-time"
              class="block mb-1 text-sm fw-regular"
            >
              End Time <span class="text-red"> * </span>
              <span
                class="info-img tool"
                data-tip="Please enter time in local time we will covert it"
              >
                <img src="assets/images/info-circle.svg" alt="" />
              </span>
            </label>
            <input
              class="text-box form-control border border-grey border-solid text-sm fw-medium rounded-md block w-100 p-2.5 focus:outline-none focus:border focus:border-solid focus:border-primary"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              placeholder="Time"
              formControlName="endTime"
            />
            <owl-date-time
              [pickerType]="'timer'"
              [hour12Timer]="true"
              #dt1
            ></owl-date-time>
            <div *ngIf="saleFormSubmitted && saleFormControl?.endTime?.errors">
              <div
                *ngIf="saleFormControl?.endTime?.errors?.required"
                class="text-red pb-2"
              >
                End time is required
              </div>
            </div>
          </div>

          <!-- END: TIMED BID || INPUT -->
        </div>
        <div
          class="d-flex flex-row md:flex-row gap-4 justify-content-between md:pt-4 mt-auto"
        >
          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="justify-content-center btn-back"
          >
            <img
              src="assets/icons/arrow-left.svg"
              class="w-5 h-5 mr-2"
              alt="BX-nft"
            />
            Back
          </button>
          <span
            data-bs-toggle="modal"
            data-bs-target="#purchaseModal"
            data-modal-toggle="defaultModal"
            #purchaseModalRef
          ></span>
          <button type="submit" class="btn-continue">Continue</button>
        </div>
      </form>
    </div>
    <div class="col-md-4">
      <div class="w-16 mx-auto">
        <p class="">Preview</p>
        <img
          [src]="[getImageSource(nft)]"
          onerror="this.src='assets/images/profile.png'"
          class="rounded-t-xl rounded-b-none w-100 mw-100 preview-img"
          alt="BX-nft"
        />
        <div class="card-box border-grey border-solid rounded-b-xl border-2">
          <div class="p-2 preview-card">
            <h4
              class="text-sm fw-regular qu"
              *ngIf="nft?.nftCollection?.collectionName"
            >
              {{ nft?.nftCollection?.collectionName }}
            </h4>
            <h4 class="text-sm fw-regular" *ngIf="nft.collectionName">
              {{ nft?.nftCollection?.collectionName }}
            </h4>
            <h3
              class="text-base fw-semibold text-truncate w-100 line-clamp-1 px-0"
            >
              <b> {{ nft?.name }} #{{ nft?.tokenId }}</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
</div>
</div>

<!-- BEGIN: Purchase Processing -->
<div
  class="modal fade"
  id="purchaseModal"
  tabindex="-1"
  aria-labelledby="purchaseModal"
  aria-hidden="true"
>
  <div class="modal-dialog put-for-sale purchase-modal-dialog">
    <span data-bs-dismiss="modal" aria-label="Close" #orderProcess></span>
    <div class="modal-content">
      <div class="row col-12 gap-4 p-4 md:p-8 text-center">
        <div class="col-12">
          <h2 class="fw-semibold text-xl text-center" *ngIf="tradeLoader">
            Your order is Processing!
          </h2>
          <h2 class="fw-semibold text-xl text-center" *ngIf="!tradeLoader">
            Your order is Created!
          </h2>
          <p class="fw-regular text-lg text-center">
            <span class="fw-semibold">{{ nft?.name }} #{{ nft?.tokenId }}</span>
          </p>
          <img
            [src]="[getImageSource(nft)]"
            onerror="this.src='assets/images/profile.png'"
            class="wh-48"
            alt="BX-nft"
          />
        </div>
        <div class="row col-2 col-12">
          <div class="d-flex purchase-processing">
            Approve
            <app-contract-call-progress
              [progress]="progressStatus?.AP"
            ></app-contract-call-progress>
          </div>
          <div class="d-flex purchase-processing">
            Sign Order
            <app-contract-call-progress
              [progress]="progressStatus?.SO"
            ></app-contract-call-progress>
          </div>
          <div class="d-flex purchase-processing">
            Create Order
            <app-contract-call-progress
              [progress]="progressStatus?.CO"
            ></app-contract-call-progress>
          </div>
          <div class="d-flex purchase-processing">
            Complete order creation
            <app-contract-call-progress
              [progress]="progressStatus?.COC"
            ></app-contract-call-progress>
          </div>
        </div>
        <div
          class="col-span-12 flex justify-center"
          *ngIf="progressStatus?.COC === 'C'"
        >
          <button class="btn-done" (click)="navigateToSale(false)">Done</button>
        </div>
        <div
          class="col-span-12 flex justify-center"
          *ngIf="progressStatus?.COC === 'E'"
        >
          <button class="text-red" (click)="togglePutForSaleProgress(false)">
            Try Again
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Purchase Processing -->
