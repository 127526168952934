import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogsComponent } from './components/blogs/blogs.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { HomeComponent } from './components/home/home.component';
import { NftLaunchpadComponent } from './components/nft-launchpad/nft-launchpad.component';
import { PartnerComponent } from './components/partner/partner.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TrendingsComponent } from './components/trendings/trendings.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'blogs',
    component: BlogsComponent,
    children: [
      {
        path: 'blog-details/:id',
        loadChildren: () => import('./components/blogs/blogs.module').then((module) => module.BlogsModule),
      },

    ],
  },
  {
    path: 'nft-launchpad',
    component: NftLaunchpadComponent,
    children: [
      {
        path: 'single-launchpad-nft/:id',
        loadChildren: () => import('./components/nft-launchpad/nft-launchpad.module').then((module) => module.NftLaunchpadModule),
      },

    ],
  },
  {
    path: 'trending',
    component: TrendingsComponent,
    children: [
      {
        path: 'single-launchpad-nft/:id',
        loadChildren: () => import('./components/trendings/trendings.module').then((module) => module.TrendingsModule),
      },

    ],
  },
  {
    path: 'collections',
    component: CollectionsComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./components/collections/collections.module').then((module) => module.CollectionsModule),
      },

    ],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    children: [
      {
        path: 'edit-profile',
        loadChildren: () => import('./components/profile/profile.component').then((module) => module.ProfileComponent),
      },

    ],
  },
  {
    path: 'partner/:id',
    component: PartnerComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./components/partner/partner.module').then((module) => module.PartnerModule),
      },

    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

/**
 * Export Class
 */
export class AppRoutingModule { }
