import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contract-call-progress',
  templateUrl: './contract-call-progress.component.html',
  styleUrls: ['./contract-call-progress.component.css'],
})
/**
 * Contract call progress component
 */
export class ContractCallProgressComponent {
  @Input() progress:string = '';
}
