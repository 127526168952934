<!-- <app-loader *ngIf="pageLoader"></app-loader>
<div class="row pb-100" *ngIf="!pageLoader">
    <div class="col-lg-12">
       
    </div> -->

    <!-- <section class="top-collections">
        <div class="container">
            <div class="bg-gradient-1 bg-right-top"></div>
            <div class="bg-gradient-2 bg-right-top"></div>
            <div class="row wow fadeIn">
                <div class="col-lg-12">
                    <div class="text-center title-wrap">
                        <h2>Hot Bids</h2>
                    <p>Discover a new realm of gaming on the Blockchainx Arcade, a hub of games powered by Blockchainx <br/>
                        uniting gamers, investors and developers seamlessly.</p>
                    </div>  
                </div>
            </div>

            <owl-carousel-o [options]="blogPost">
                <ng-template carouselSlide *ngFor="let nft of nfts; let i = index;">
                    <div id="collection-carousel-alt{{i}}">
                        <div class="nft_coll cursor-pointer" [routerLink]="['/single-nft-details', nft._id]">
                            <div class="nft_wrap back-img">
                                <img [src]="[nft.nftImageS3 ? nft?.nftImageS3 : nft?.nftCollection?.baseUrl + nft.nftImage]"
                                    class="lazy img-fluid" alt="">
                            </div>
                            <div class="nft_coll_pp">
                                <a><img class="lazy pp-coll"
                                        [src]="nft.nftCollection ? nft.nftCollection.collectionImage : 'assets/images/author_single/author_thumbnail.jpg'"
                                        alt=""></a>
                                <i class="fa fa-check"></i>
                            </div>
                            <div class="nft_coll_info">
                                <a>
                                    <h4>{{nft?.nftCollection?.collectionName}}</h4>
                                </a>
                                <span class="">{{nft?.name}} #{{nft.tokenId}}</span>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="nft.saleType != 'Buy'">
                                <div class="col-6">
                                    <h4 class="text-sm fw-regular pb-1">Top Bid:</h4>
                                    <div class="flex items-center space-x-2">
                                        <span class="text-base fw-semibold">{{nft.price}} BNB</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="view-btn btn-style-yellow float-right"
                                        *ngIf="(!checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                                        Bid Now
                                    </button>
                                    <button type="button" class="view-btn btn-style-yellow float-right" *ngIf="(nft.saleType === 'Bid')">
                                        Offer Now
                                    </button>
                                    <button type="button" class="view-btn btn-style-yellow float-right" *ngIf="(nft.saleType === 'Buy')">
                                        Buy Now
                                    </button>
                                </div>
                                <div class="col-span-12"
                                    *ngIf="(!checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                                    <div class="flex items-center space-x-2">
                                        <h5 class="text-sm fw-regular">{{getTimeForTimedBid(nft.bidEndTime)}}</h5>
                                    </div>
                                </div>
                                <div class="col-span-12"
                                    *ngIf="(checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                                    <div class="flex items-center space-x-2">
                                        <h5 class="text-sm text-red fw-regular">BID ENDED</h5>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-12 items-center gap-x-3 p-5 h-28" *ngIf="nft.saleType == 'Buy'">
                                <div class="col-span-6">
                                    <h5 class="text-sm fw-regular pb-1">Best Offer</h5>
                                    <div class="flex items-center space-x-2">
                                        <img src="assets/images/trending-nft/ekta-coin.svg" class="w-5 h-5" alt="BX-nft">
                                        <span class="nft__card--ekta-value-lg">{{nft.price}} BNB</span>
                                    </div>
                                </div>
                                <div class="col-span-6">
                                    <button type="button" class="nft__card--red-btn">Buy Now</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </ng-template>
            </owl-carousel-o>
            <div class="row" *ngIf="nfts.length == 0">
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
            </div>
        </div>
    </section>         -->


    

    <section class="trending-nft">
        <div class="container">
            <div class="bg-gradient-1 bg-right-top"></div>
            <div class="bg-gradient-2 bg-right-top"></div>
            <div class="row wow fadeIn">
                <div class="col-lg-12">
                    <div class="text-center title-wrap">
                        <h2>Hot Bids</h2>
                        <p>Discover a new realm of gaming on the Blockchainx Arcade, a hub of games powered by Blockchainx <br/>
                            uniting gamers, investors and developers seamlessly.</p>
                    </div>
                </div>
                <owl-carousel-o [options]="blogPost">
                    <ng-template carouselSlide *ngFor="let nft of nfts; let i = index;">
                        <div class="d-item">
                            <div class="nft__item" [routerLink]="['/single-nft-details', nft._id]" [ngClass]="{'row col-md-4': nfts.length < 2}">
                                <div class="nft-common-details">
                                    <div class="nft__item_wrap">
                                        <div class="card-img">
                                            <img [src]="[nft.nftImageS3 ? (nft.nftImageS3 ? nft.nftImageS3 : nft.nftCollection.baseUrl + nft.nftImage) : 'assets/images/collections/coll-item-1.jpg']"
                                                class="lazy nft__item_preview" alt="">   
                                            
                                                <button type="button" class="btn-style-one"
                                                    *ngIf="(!checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                                                    Bid Now
                                                </button>
                                                <button type="button" class="btn-style-one" *ngIf="(nft.saleType === 'Bid')">
                                                    Offer Now
                                                </button>
                                                <button type="button" class="btn-style-one" *ngIf="(nft.saleType === 'Buy')">
                                                    Buy Now
                                                </button>
                                        </div>
                                    </div>
                                    <div class="nft__item_info">
                                            <a [routerLink]="['/single-nft-details']">
                                            <h3 class="text-truncate" title="{{nft?.name}} #{{nft.tokenId}}">{{nft?.name}}
                                                #{{nft.tokenId}}</h3>
                                            </a>
                                            <div class="collection">
                                                <h6>Collection</h6>
                                                <h4 class="text-truncate" title="{{nft?.nftCollection?.collectionName}}">
                                                    {{nft?.nftCollection?.collectionName}}</h4>
                                                </div>                                    
                                        <hr />
                                        <div class="col-span-12"
                                            *ngIf="(!checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                                            <div class="flex items-center space-x-2">
                                                <h5 class="text-sm fw-regular">{{getTimeForTimedBid(nft.bidEndTime)}}</h5>
                                            </div>
                                        </div>
                                        <div class="col-span-12"
                                            *ngIf="(checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
                                            <div class="flex items-center space-x-2">
                                                <h5 class="text-sm text-red fw-regular">BID ENDED</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="buy-details" *ngIf="nft.saleType != 'Buy'">
                                    
                                    <div class="floor-section d-flex justify-content-between">
                                        <span class="">Top Bid</span>
                                        <div class="d-flex gap-2">
                                        <img src="assets/images/icons/bnb.svg"
                                            class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                        <span
                                            class="truncate lg:text-clip floor-price-text">{{nft.price}} <span>BNB</span></span>
                                        </div>
                                    </div>
    
                                    <!-- <div class="d-flex price-div text-them justify-content-between">
                                        {{nft.price}} BNB
                                        <a class="btn-main text-them buy-btn"
                                            *ngIf="(checkIfBidEnded(nft.bidStartTime) && !checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">
    
                                            Bid Now
                                        </a>
                                        <a class="btn-main text-them buy-btn"
                                            *ngIf="(checkIfBidEnded(nft.bidStartTime) && nft.saleType === 'Bid')">
                                            Offer Now
                                        </a>
                                    </div> -->
                                </div>
                                <div class="buy-details" *ngIf="nft.saleType == 'Buy'">
    
                                    <div class="floor-section d-flex justify-content-between">
                                        <span class="">Top Bid</span>
                                        <div class="d-flex gap-2">
                                        <img src="assets/images/icons/bnb.svg"
                                            class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                        <span
                                            class="truncate lg:text-clip floor-price-text">{{nft.price}} <span>BNB</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
    
                    </ng-template>
                
                </owl-carousel-o>
                <div class="row" *ngIf="nfts.length == 0 && !getNftResponse" class="no-trend-nft">
                    Hot Bids yet to be update
                </div>
                <div class="row" *ngIf="getNftResponse">
                    <div class="col-4">
                        <app-shimmer></app-shimmer>
                    </div>
                    <div class="col-4">
                        <app-shimmer></app-shimmer>
                    </div>
                    <div class="col-4">
                        <app-shimmer></app-shimmer>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <a [routerLink]="['/nft-launchpad']" *ngIf="getNftResponse" class="btn-style-default">Explore All NFT</a>
        </div>
    </section>    