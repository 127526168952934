import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FeaturedPartnersService } from 'src/app/services/featured-partners.service';

@Component({
  selector: 'app-top-sellers',
  templateUrl: './top-sellers.component.html',
  styleUrls: ['./top-sellers.component.css'],
})

/**
 * Component
 */
export class TopSellersComponent implements OnInit {
  public partners: any = [];

  /**
   * Creates an instance of top sellers component.
   */
  constructor(private featuredPartners: FeaturedPartnersService, private toastr: ToastrService) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.getLatestPartners();
  }

  /**
   * Gets latest partners
   */
  public getLatestPartners() {
    this.featuredPartners.getFeaturedPartners()
        .subscribe({
          next: (response:any) => {
            this.partners = response['data'];
          },
          error: (error : any) => {
          },
        });
  }
}
