<app-loader *ngIf="pageLoader"></app-loader>
    <div id="wrapper" *ngIf="!pageLoader">
        <app-nav-bar></app-nav-bar>
        <!-- content begin -->
        <!-- <div class="no-bottom" id="content">
            <div id="top"></div> -->

            <section class="top-collections collections-page">
                <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d_profile de-flex">
                                <div class="de-flex-col">
                                    <div class="profile_avatar">
                                        <img src="{{partner.profileImage ? partner.profileImage : 'assets/images/author_single/author_thumbnail.jpg' }} "
                                            alt="">
                                        <div class="profile-content d-wallet">
                                            <h4>
                                                {{partner.name}}
                                                <span class="profile_username">Joined {{ partner.createdAt | date }}</span>
                                            </h4>
                                            <p>{{partner.about}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div>
                                <ul class="tab-menu">
                                    <li class="mr-2">
                                        <span (click)="setActiveMenu(0)" [ngClass]="{'active' : isMenuActive == 0}">
                                            On Sale ({{partnerNftsOnSale.length}})
                                        </span>
                                    </li>
                                    <li class="mr-2">
                                        <span (click)="setActiveMenu(1)" [ngClass]="{'active' : isMenuActive == 1}">
                                            Collections ({{partnerCollections.length}})
                                        </span>
                                    </li>
                                    <li class="mr-2">
                                        <span (click)="setActiveMenu(2)" [ngClass]="{'active' : isMenuActive == 2}">
                                            Private Sale ({{partnerPrivateSale.length}})
                                        </span>
                                    </li>
                                </ul>

                                <div class="mt-5 tab-menu-content">
                                    <div *ngIf="isMenuActive == 0">
                                        <div *ngIf="partnerNftsOnSale?.length == 0" class="no-image text-center">
                                            <img src="assets/images/no-image.svg" class="" alt="bx-nft">
                                            <p class="flex justify-center">No Sale Found</p>
                                        </div>
                                        <app-nft-lists [nfts]="partnerNftsOnSale"></app-nft-lists>
                                    </div>

                                    <div *ngIf="isMenuActive == 1">
                                        <div *ngIf="partnerCollections?.length == 0" class="no-image-section text-center">
                                            <img src="assets/images/no-image.svg"
                                                class="no-items-img mx-auto inline-block" alt="">
                                            <p class="text-xl fw-semibold inline-block">No items to display
                                            </p>
                                        </div>
                                        <div class="row">

                                            <!-- nft item begin -->
                                            <ng-container *ngIf="partnerCollections?.length > 0">
                                                <div class="col-lg-3 col-md-6 col-sm-6 col-lg-4 col-xs-12" *ngFor="let collection of partnerCollections">
                                                    <div class="nft__item style-2">
                                                        <div class="nft__item_wrap">

                                                            <a>
                                                                <img src="{{collection.coverPhoto}}"
                                                                    class="lazy nft__item_preview cover-image" alt="">
                                                            </a>
                                                        </div>
                                                        <div class="nft__item_info">
                                                            <a>
                                                                <h4>{{collection?.collectionName}}</h4>
                                                            </a>
                                                            <div class="nft__item_price">
                                                                {{collection?.partner?.category?.categoryName}}
                                                                by<span>/{{collection?.partner?.name}}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </ng-container>

                                        </div>
                                    </div>

                                    <div *ngIf="isMenuActive == 2">
                                        <div *ngIf="partnerPrivateSale?.length == 0" class="no-image text-center">
                                            <img src="assets/images/no-image.svg" class="" alt="bx-nft">
                                            <p class="flex justify-center">No NFT Launchpad Found</p>
                                        </div>
                                        <app-nft-launchpad-details *ngIf="partnerPrivateSale?.length > 0" [isPartner]="true" [privateSales]="partnerPrivateSale"></app-nft-launchpad-details>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        <!-- </div> -->
        <!-- content close -->
        <app-footer></app-footer>
    </div>