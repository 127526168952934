import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs';
import { CommonService } from './common.service';

const apiUrl = environment.api_url;

@Injectable({
  providedIn: 'root',
})
/**
 * Collections service
 */
export class CollectionService {
  /**
   * @constructor
   *
   * @param {HttpClient} http
   * @param {ConfigService} CommonService
   */
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
  ) { }

  /**
   * Get collections list
   * @param {number} page - page number to query
   * @param {number} limit - limits per page to query
   * @return {HttpResponse}
   */
  public getAllCollections({ page = 1, limit = 10, categories = [], categorySearch = '', partner=[] }: any) {
    let collectionName = String(categorySearch).replace('#', encodeURIComponent('#'));
    collectionName = String(collectionName).replace('&', encodeURIComponent('&'));
    return this.http.get(`${apiUrl}/api/v1/filter-collections/?page=${page}&limit=${limit}&category=${categories?.join(',')}&collectionName=${collectionName}&partner=${partner?.join(',')}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get Top Collections
   * @return {Observable}
   */
  public getTopCollections() {
    return this.http.get(`${apiUrl}/api/v1/top-collections`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }


  /**
   * Get categories list
   *
   * @return {HttpResponse}
   */
  public getAllCategories():any {
    return this.http.get(`${apiUrl}/api/v1/get-categories/`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get collection details
   * @param {string} id - collection id
   * @return {HttpResponse}
   */
  public getCollection(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-collection/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }
  /**
   * Get Partner collection details
   * @param {string} id - collection id
   * @return {HttpResponse}
   */
  public getPartnerCollection(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-partner-collections-on-sale/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get Partner nfts on sale
   * @param {string} id - partner id
   * @return {HttpResponse}
   */
  public getPartnerNftsOnSale(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-partner-nfts-on-sale/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get Partner collection details
   * @param {string} id - partner id
   * @return {HttpResponse}
   */
  public getPartnerNfts(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-partner-nfts-on-sale/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get Partner private sale
   * @param {string} id - partner id
   * @return {HttpResponse}
   */
  public getPartnerPrivateSale(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-partner-private-sale/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get nft list of a collection
   *
   * @param {string} id - collection id
   * @param {any} filterOptions - Filter options
   * @param {any} sortOptions - Sort options
   * @param {any} limit - limit
   *
   * @return {HttpResponse}
   */
  public getCollectionNfts(id: string, filterOptions:any, sortOptions:any, limit:any) {
    const attributes = filterOptions?.attributes;
    let searchKeyword = filterOptions?.searchKeyword.replace('#', encodeURIComponent('#'));
    searchKeyword = searchKeyword.replace('&', encodeURIComponent('&'));
    return this.http.get(`${apiUrl}/api/v1/filter-nfts/${id}?saleType=${filterOptions?.saleType}&from=${filterOptions?.priceFilter?.min || ''}&to=${filterOptions?.priceFilter?.max || ''}&item=${searchKeyword || ''}&sort=${sortOptions?.priceSort || 1}&limit=${limit}${attributes?'&'+attributes:''}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get nft details
   * @param {string} id - nft id
   * @return {HttpResponse}
   */
  public getNft(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-nft/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get Hot Bids
   * @return {HttpResponse}
   */
  public getHotBids() {
    return this.http.get(`${apiUrl}/api/v1/hot-bids`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get nft details
   * @param {string} id - nft id
   * @return {HttpResponse}
   */
  public getAttributes(id: string) {
    return this.http.get(`${apiUrl}/api/v1/get-attributes-list/${id}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get partners
   * @return {any}
   */
  public getPartners() {
    return this.http.get(`${apiUrl}/api/v1/get-partners`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }
}
