import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { CollectionService } from 'src/app/services/collection.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-nft-launchpad-filter',
  templateUrl: './nft-launchpad-filter.component.html',
  styleUrls: ['./nft-launchpad-filter.component.css'],
})

/**
 * Export  Class
 */
export class NftLaunchpadFilterComponent implements OnInit {
  @Input() collections:any = [];
  @ViewChildren('categorySelectedList')
    categorySelectedList!: QueryList<ElementRef>;
  @ViewChildren('partnerSelectedList')
    partnerSelectedList!: QueryList<ElementRef>;
  @Output() onCategorySelect = new EventEmitter<any>();
  @Output() onPartnerSelect = new EventEmitter<any>();
  @Input() initialLoader:boolean = false;
  public searchLength:number | undefined;
  public categoryFilterList:Array<any> = [];
  public selectedCategories:Array<any> = [];
  public selectedPartner:Array<any> = [];
  public partnerList:Array<any> = [];
  public partnerFilterSearch = '';
  public categoryFilterSearch = '';
  /**
   * constructor
   */
  constructor(
    private collectionService: CollectionService,
    private accountService:AccountService,
  ) { }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.getAllCategories();
    this.getAllPartnersForFiltering();
    this.accountService.searchFilterObserve.subscribe((reponse : any) => {
      setTimeout(() => {
        this.searchLength = reponse.length;
      }, 100);
    });
  }


  /**
   * after view init
   */
  public ngAfterViewInit() {
    this.initialLoader = false;
  }

  /**
   * On Category check
   */
  public onCategoryCheck() {
    this.selectedCategories = this.categorySelectedList.filter((data:any)=>data?.nativeElement?.checked)?.map((data)=>data?.nativeElement?.value);
    this.onCategorySelect.emit(this.selectedCategories);
  }

  /**
   * On Category check
   */
  public onPartnerCheck() {
    this.selectedPartner = this.partnerSelectedList.filter((data:any)=>data?.nativeElement?.checked)?.map((data)=>data?.nativeElement?.value);
    this.onPartnerSelect.emit(this.selectedPartner);
  }

  /**
   * Get all categories
   */
  private getAllCategories() {
    this.collectionService.getAllCategories()
        .subscribe({
          next: (response:any)=>{
            this.categoryFilterList = response?.data?.category;
          },
        });
  }

  /**
   * Get all partners for filtering
   */
  private getAllPartnersForFiltering() {
    this.collectionService.getPartners()
        .subscribe({
          next: (response:any)=>{
            this.partnerList = response?.data;
          },
        });
  }
}

