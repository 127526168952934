import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from 'ng-blockchainx';

const web3 = new Web3(environment.provider);
const collectionAbi = require('../constants/abi/wbnb.json');

const contract = new web3.eth.Contract(collectionAbi, environment.wbnb_contract);
const apiUrl = environment.api_url;
@Injectable({
  providedIn: 'root',
})
/**
 * Trade contract service
 */
export class TradeContractService {
  /**
   * @constractor
   */
  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) { }

  /**
   * Deposit WBNB
   */
  public async depositWekta():Promise<any> {
    try {
      const depositeEncode = await contract.methods.deposit().encodeABI();
      return depositeEncode;
    } catch (e) {
    }
  }

  /**
   * Gets wBNB balance
   * @param {string} address
   * @return {string}
   */
  public async getWektaBalance(address: string):Promise<any> {
    try {
      const balance = await contract.methods.balanceOf(address).call();
      return Number(balance);
    } catch (e) {
    }
  }

  /**
   * Approves trade contract service
   * @param {string} spenderAddress
   * @param {any} amount
   */
  public async approve(spenderAddress: string, amount: any):Promise<any> {
    try {
      const approveEncode = await contract.methods.increaselAllowance(spenderAddress, amount).encodeABI();
      return approveEncode;
    } catch (e) {
    }
  }

  /**
   * Gets allownace
   * @param {string} holderAddress
   * @param {string} spenderAddress
   */
  public async getAllownace(holderAddress: string, spenderAddress: string):Promise<any> {
    try {
      const allowance = await contract.methods.allowance(holderAddress, spenderAddress).call();
      return Number(allowance);
    } catch (e) {
    }
  }

  /**
   * Bit nft api call
   *
   * @param {any} payload
   * @return {any}
   */
  public bifNft(payload:any):Observable<any> {
    const account: any = this.storage.get('auth');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + account.token,
    });
    return this.http.post(`${apiUrl}/api/v1/bid-nft`, payload, { headers: reqHeader });
  }
}
