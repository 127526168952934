import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartnerComponent } from './partner.component';

const routes: Routes = [
  { path: 'partner/:id', component: PartnerComponent },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

/**
 * Export Class
 */
export class PartnerRoutingModule { }
