import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CollectionService } from 'src/app/services/collection.service';

@Component({
  selector: 'app-nft-filters',
  templateUrl: './nft-filters.component.html',
  styleUrls: ['./nft-filters.component.css'],
})

/**
 * Export Class
 */
export class NftFiltersComponent implements OnInit {
  @Output() searchCallback = new EventEmitter<any>();
  @Output() clearFilterCallback = new EventEmitter<any>();
  @Output() saleTypeFilterCallback = new EventEmitter<any>();
  @Output() priceSortCallback = new EventEmitter<any>();
  @Input() nfts: any;
  @Input() isAttrFilter: boolean = false;
  @Output() priceFilterCallback = new EventEmitter<any>();
  @ViewChild('priceRangeModal') priceRangeModal:any;
  @ViewChild('attributesModal') attributesModal:any;
  @Output() attributeFilterCallback = new EventEmitter<any>();
  @ViewChildren('subattributes')
    subattributes!: QueryList<ElementRef>;
  @ViewChild('multiselect', { static: false }) multiselect: any;

  public searchByName:string = '';
  public list : any[] = [];
  public isSalesDropdownCollapsed = false;
  public currentPriceSort = 1;
  public saleTypeFilter:any = {
    Bid: false,
    Buy: false,
    TimedBid: false,
  };
  public isSaleTypeApplyFilter: boolean = false;
  public isPriceApplyFilter: boolean = false;
  public searchAttribute:string = '';
  public objectKeys: any = Object.keys;
  public attributesFilter:any = {};
  public searchSubAttribute:string = '';
  public attributeFilterMapper:any = {};
  public isApplyFilter: boolean = false;
  private collectionId:string = '';
  public priceRangeForm: FormGroup;
  public priceRangeFormSubmitted: boolean = false;
  public showDropDown:any;
  public attributesSubToggle:any = {};
  public priceSortText: string = 'Price Low to High';
  public isPriceSortFilter: boolean = false;
  public isMaxPriceError: boolean = false;
  public attributesSubToggleAll: any = {};
  public currentAttribute!:number;

  /**
   * Creates an instance of nft filters component.
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private collectionService: CollectionService,
    private formBuilder: FormBuilder,
  ) {
    this.priceRangeForm = this.formBuilder.group({
      minPrice: ['', [Validators.required]],
      maxPrice: [''],
    });
    this.priceRangeForm.controls.maxPrice.setValidators([
      Validators.required,
      (control: AbstractControl) => Validators.min(this.priceRangeForm.controls.minPrice.value)(control),
    ]);
  }

  /**
   * on init
   */
  public ngOnInit(): void {
    // Sale Type drop down lits
    this.list =
    [
      { name: 'Buy', checked: false, value: 'Buy' },
      { name: 'Offer', checked: false, value: 'Bid' },
      { name: 'TimedBid', checked: false, value: 'TimedBid' },
    ];
    this.activatedRoute.params.subscribe((params:any) => {
      this.collectionId = params?.id;
      this.getAttributes();
    });
  }


  /**
   * On sorting price
   * @param {number} sort
   */
  public onPriceSort(sort:number) {
    this.showDropDown = false;
    this.isPriceSortFilter = true;
    if (sort == 1) {
      this.priceSortText = 'Price Low to High';
    } else {
      this.priceSortText = 'Price High to Low';
    }
    this.currentPriceSort = sort;
    this.priceSortCallback.emit(sort);
  }

  /**
   * Shares checked list
   * @param {any} item
   */
  public shareCheckedList(item:any[]) {
    this.isSaleTypeApplyFilter = true;
    this.saleTypeFilterCallback.emit({ ...item });
  }
  /**
   * Search by name
   *
   * @param {any} event
   */
  public searchByNameCallback(event:any) {
    this.searchCallback.emit(event?.target?.value);
  }

  /**
   * Price Range filter
   *
   * @param {boolean} clearTrigger
   */
  public priceRangeFilter(clearTrigger:boolean = false) {
    this.priceRangeFormSubmitted = true;
    if (!this.priceRangeForm.valid) return;
    const { minPrice, maxPrice } = this.priceRangeForm.value;
    if (minPrice > maxPrice) {
      this.isMaxPriceError = true;
      return;
    } else {
      this.isMaxPriceError = false;
    }
    if (clearTrigger) {
      this.priceRangeForm.patchValue({ minPrice: 0, maxPrice: 0 });
    }
    const payload = {
      min: clearTrigger ? 0 : minPrice,
      max: clearTrigger ? 0 : maxPrice,
    };
    if (payload.min > 0 || payload.max > 0) {
      this.isPriceApplyFilter = true;
    } else {
      this.isPriceApplyFilter = false;
    }
    this.priceRangeModal.nativeElement.click();
    this.priceFilterCallback.emit({ ...payload });
  }

  /**
   * get all attributes for filtering
   */
  public getAttributes() {
    this.collectionService.getAttributes(this.collectionId).subscribe({
      next: (response:any) => {
        if (response?.data) {
          this.attributesFilter = response?.data;
          const obj = {};
          Object.keys(this.attributesFilter).forEach((key) => {
            const value = this.attributesFilter[key];
            const myset = [...new Set(value)];
            Object.assign(obj, { [key]: myset });
          });
          this.attributesFilter = obj;
        }
      },
      error: (error:any) => {
      },
    });
  }


  /**
   * Search attribute callback
   *
   * @param {any} event
   */
  public searchAttributeCallback(event:any) {
    this.searchAttribute = event?.target?.value;
  }

  /**
   * Search sub attribute callback
   *
   * @param {any} event
   */
  public searchSubAttributeCallback(event:any) {
    this.searchSubAttribute = event?.target?.value;
  }

  /**
   * Select sub attributes
   *
   * @param {string} attribute
   * @param {string} subAttribute
   * @param {any} event
   */
  public selectSubAttributes(attribute:string, subAttribute:string, event:any) {
    if (!this.attributeFilterMapper[attribute]) {
      this.attributeFilterMapper[attribute] = [];
    }
    if (event?.target?.checked) {
      this.attributeFilterMapper[attribute].push(subAttribute);
    } else {
      this.attributeFilterMapper[attribute].splice(this.attributeFilterMapper[attribute].indexOf(subAttribute), 1);
    }
  }

  /**
   * Clear all attributes
   */
  public clearAllAttributeFilters() {
    this.clearAttributesField();
    this.filterAttributes();
  }

  /**
   * Clear all attributes field
   */
  clearAttributesField() {
    this.searchAttribute = '';
    this.searchSubAttribute = '';
    this.isApplyFilter = false;
    this.subattributes.forEach((element:any) => {
      element.nativeElement.checked = false;
    });
    this.attributeFilterMapper = {};
  }

  /**
   * Filter attrbutes
   */
  public filterAttributes() {
    const queryArray:Array<string> = [];
    let count = 0;
    for (const key in this.attributeFilterMapper) {
      // console.log('checkkkk key', key);
      if (key && this.attributeFilterMapper[key] != '') {
        count++;
        encodeURIComponent(this.attributeFilterMapper[key]);
        for (const value in this.attributeFilterMapper[key]) {
          queryArray.push(`attribute_${key}=${encodeURIComponent(this.attributeFilterMapper[key][value])}`);
        }
      }
    }
    if (count > 0) {
      this.isApplyFilter = true;
    } else {
      this.isApplyFilter = false;
    }
    this.attributeFilterCallback.emit(queryArray);
    this.attributesModal.nativeElement.click();
  }

  /**
   * Clear all Filters
   */
  clearAllFilters() {
    // price range filter
    this.isPriceApplyFilter = false;
    this.priceRangeForm.patchValue({ minPrice: 0, maxPrice: 0 });
    // attributes filter
    this.clearAttributesField();
    // search filter
    this.searchByName = '';
    // sale type filter
    this.isSaleTypeApplyFilter = false;
    this.list.forEach((element:any) => {
      element.checked = false;
    });
    this.multiselect.clearSelection();
    // price sort filter
    this.isPriceSortFilter = false;
    this.currentPriceSort = 1;
    this.priceSortText = 'Price Low to High';
    // clear filters
    this.clearFilterCallback.emit();
  }

  /**
   * toggleBackgroundItem
   *
   * @param{number}index
   */
  toggleAttributesSubItem(index:number) {
    if (this.currentAttribute ===index) {
      this.currentAttribute=-1;
    } else {
      this.currentAttribute=index;
    }
    this.searchSubAttribute = '';
  }
}
