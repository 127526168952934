    <div id="wrapper">
        <app-nav-bar></app-nav-bar>



            <!-- section begin -->
            <section id="section-collections" class="pt-100">
                <div class="container">
                    <!-- <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div> -->
                    <app-top-collections></app-top-collections>
                    <app-hot-bids></app-hot-bids>
                    <app-top-partners></app-top-partners>

                </div>
            </section>


        

        <!-- place a bid -->
        <div class="modal fade" id="place_a_bid" tabindex="-1" aria-labelledby="place_a_bid" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered de-modal">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="modal-body">
                        <div class="p-3 form-border">
                            <h3>Place a Bid</h3>
                            You are about to place a bid for <b>Alien vs Predator</b> from <b>Monica Lucas</b>
                            <div class="spacer-single"></div>
                            <h6>Your bid (ETH)</h6>
                            <input type="text" name="bid_value" id="bid_value" class="form-control"
                                placeholder="Enter bid" />
                            <div class="spacer-single"></div>
                            <h6>Enter quantity. <span class="id-color-2">10 available</span></h6>
                            <input type="text" name="bid_qty" id="bid_qty" class="form-control" value="1" />
                            <div class="spacer-single"></div>
                            <div class="de-flex">
                                <div>Your bidding balance</div>
                                <div><b>0.013325 ETH</b></div>
                            </div>
                            <div class="de-flex">
                                <div>Your balance</div>
                                <div><b>10.67856 ETH</b></div>
                            </div>
                            <div class="de-flex">
                                <div>Service fee 2.5%</div>
                                <div><b>0.00325 ETH</b></div>
                            </div>
                            <div class="de-flex">
                                <div>You will pay</div>
                                <div><b>0.013325 ETH</b></div>
                            </div>
                            <div class="spacer-single"></div>
                            <a target="_blank" class="btn-main btn-fullwidth">Place a bid</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer -->
        <app-footer></app-footer>
    </div>