<app-loader *ngIf="pageLoader"></app-loader>
<div id="wrapper" *ngIf="!pageLoader">
        <!-- Nav Bar -->
        <app-nav-bar></app-nav-bar>

        <!-- content begin -->
        <div class="no-bottom no-top" id="content">
            <section id="section-hero" class="banner-slider">
                    <div class="container">
                        <div class="bg-gradient-1 bg-left-bottom"></div>
                        <div class="bg-gradient-2 bg-left-top"></div>
                        <div class="bg-gradient-1 bg-right-top"></div>
                        <div class="bg-gradient-2 bg-right-bottom"></div>
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="banner-description">
                                    <h1 class="wow fadeInUp" data-wow-delay=".75s">Buy, Sell & Collect Digital Items.</h1>
                                    <p class="wow fadeInUp lead" data-wow-delay="1s">Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable</p>
                                    <a [routerLink]="['/collections/']" class="wow fadeInUp btn-style-one" data-wow-delay="1.2s">Collections</a>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                 <owl-carousel-o [options]="blogPost">
                                            <ng-template carouselSlide *ngFor="let collection of collections; let i = index;">
                                                 
                                                <div class="slider-item"> 
                                                    <div class="item-img">
                                                        <img [src]=" collection.collectionImage ? collection.collectionImage : 'assets/images/author/author-1.jpg'" class="lazy img-fluid" alt="Profile Image">
                                                    </div>  
                                                    <h3>                        
                                                        <a [routerLink]="['/nft-details/', collection._id]">
                                                            {{collection?.collectionName}}                                                            
                                                        </a>
                                                    </h3> 
                                                    <div class="author-img">
                                                        <img [src]=" collection.collectionImage ? collection.collectionImage : 'assets/images/author/author-1.jpg'" class="lazy img-fluid" alt="Profile Image">
                                                        <div class="creator">
                                                            <h6>Creator</h6>
                                                            <h5>DeFiApes</h5>
                                                        </div>
                                                    </div>
                                                </div>  
                                
                                            </ng-template>
                                        </owl-carousel-o>
                                       

                            </div>
                        </div>
                    </div>
            </section>
 
            <section id="section-collections">

                    <app-trending-nft [nfts]="nfts" [getNftResponse]="getNftResponse"></app-trending-nft>


                    <app-hot-collections></app-hot-collections>

                    <app-top-sellers></app-top-sellers>

            </section>

            <section id="section-text" class="no-top create-and-sell-your-nft">
                <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center title-wrap">
                                <h2>Create And Sell Your NFTs</h2>
                               <p>Discover a new realm of gaming on the Blockchainx Arcade, a hub of games powered by Blockchainx <br/>
                                uniting gamers, investors and developers seamlessly.</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="feature-box">
                                <div class="step-arrow"><img src="assets/images/step-arrow.svg" alt="step arrow" class="img-fluid" /></div>
                                <figure>
                                    <img src="assets/images/set-up-your-wallet.svg" alt="Set up your wallet" class="img-fluid" />
                                </figure>
                                <h6>Step 1</h6>
                                <h4 class="wow fadeInUp">Set up your wallet</h4>
                                <p class="wow fadeInUp" data-wow-delay=".25s">Fusce non dignissim velit, sit amet semper eros. Quisque orci est</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="feature-box feature-box2">
                                <div class="step-arrow"><img src="assets/images/step-arrow.svg" alt="step arrow" class="img-fluid" /></div>
                                <figure>
                                    <img src="assets/images/create-your-collection.svg" alt="Create your collection" class="img-fluid" />
                                </figure>
                                <h6>Step 2</h6>
                                <h4 class="wow fadeInUp">Create your collection</h4>
                                <p class="wow fadeInUp" data-wow-delay=".25s">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque luctus</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="feature-box">
                                <div class="step-arrow"><img src="assets/images/step-arrow.svg" alt="step arrow" class="img-fluid" /></div>
                                <figure>
                                    <img src="assets/images/add-your-nfts.svg" alt="Add your NFTs" class="img-fluid" />
                                </figure>
                                <h6>Step 3</h6>
                                <h4 class="wow fadeInUp">Add your NFTs</h4>
                                <p class="wow fadeInUp" data-wow-delay=".25s">Suspendisse porttitor id est non accumsan. Cras vel viverra velit</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="feature-box">
                                <figure>
                                    <img src="assets/images/sell-your-nfts.svg" alt="Sell your NFTs" class="img-fluid" />
                                </figure>
                                <h6>Step 4</h6>
                                <h4 class="wow fadeInUp">Sell your NFTs</h4>
                                <p class="wow fadeInUp" data-wow-delay=".25s">Maecenas non semper quam. Vivamus et arcu condimentum ipsum consectetur</p>
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-md-6 mb-sm-30">
                            <div class="feature-box f-boxed style-3">
                                <i class="wow fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt"></i>
                                <div class="text">
                                    <h4 class="wow fadeInUp">Add your NFT's</h4>
                                    <p class="wow fadeInUp" data-wow-delay=".25s">Sed ut perspiciatis unde omnis iste
                                        natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>
                                </div>
                                <i class="wm icon_cloud-upload_alt"></i>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-sm-30">
                            <div class="feature-box f-boxed style-3">
                                <i class="wow fadeInUp bg-color-2 i-boxed icon_tags_alt"></i>
                                <div class="text">
                                    <h4 class="wow fadeInUp">Sell your NFT's</h4>
                                    <p class="wow fadeInUp" data-wow-delay=".25s">Sed ut perspiciatis unde omnis iste
                                        natus error sit voluptatem accusantium doloremque laudantium, totam rem.</p>
                                </div>
                                <i class="wm icon_tags_alt"></i>
                            </div>
                        </div> -->
                    </div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
                </div>
            </section>

        </div>
        <!-- content close -->

        <!-- place a bid -->
        <div class="modal fade" id="place_a_bid" tabindex="-1" aria-labelledby="place_a_bid" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered de-modal">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="modal-body">
                        <div class="p-3 form-border">
                            <h3>Place a Bid</h3>
                            You are about to place a bid for <b>Alien vs Predator</b> from <b>Monica Lucas</b>
                            <div class="spacer-single"></div>
                            <h6>Your bid (ETH)</h6>
                            <input type="text" name="bid_value" id="bid_value" class="form-control"
                                placeholder="Enter bid" />
                            <div class="spacer-single"></div>
                            <h6>Enter quantity. <span class="id-color-2">10 available</span></h6>
                            <input type="text" name="bid_qty" id="bid_qty" class="form-control" value="1" />
                            <div class="spacer-single"></div>
                            <div class="de-flex">
                                <div>Your bidding balance</div>
                                <div><b>0.013325 ETH</b></div>
                            </div>
                            <div class="de-flex">
                                <div>Your balance</div>
                                <div><b>10.67856 ETH</b></div>
                            </div>
                            <div class="de-flex">
                                <div>Service fee 2.5%</div>
                                <div><b>0.00325 ETH</b></div>
                            </div>
                            <div class="de-flex">
                                <div>You will pay</div>
                                <div><b>0.013325 ETH</b></div>
                            </div>
                            <div class="spacer-single"></div>
                            <a href="03_grey-wallet.html" target="_blank" class="btn-main btn-fullwidth">Place a bid</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer -->
        <app-footer></app-footer>
</div>