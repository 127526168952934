import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Web3 from 'web3';

import { CollectionService } from 'src/app/services/collection.service';
import { CommonService } from 'src/app/services/common.service';
import { AccountService } from 'src/app/services/account.service';
import { TradeService } from 'src/app/services/trade.service';
import { TradeContractService } from 'src/app/services/trade-contract.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'ng-blockchainx';
import { Meta, Title } from '@angular/platform-browser';

const web3 = new Web3(environment.provider);


@Component({
  selector: 'app-single-nft-details',
  templateUrl: './single-nft-details.component.html',
  styleUrls: ['./single-nft-details.component.css'],
})

/**
 * Export Class
 */
export class SingleNftDetailsComponent implements OnInit {
  @Input() ektaPrice:any = {};
  public nftId:string = '';
  public nft:any = {};
  public account:any = {};
  public collectionId:string = '';
  public collection:any = {};
  public accountSubscription: any;
  public statusSubscription: any;
  public saleStatusSubscription: any;
  public offers: any = [];
  public userBids: any = [];
  public isOwnerWallet: boolean = false;
  public acceptBidSubscription: any;
  public nftActivity: any = [];
  public nftActivityList: any = [];
  public totalPages: number = 1;
  public limit: number = 5;
  public page: number = 0;
  public pageLoader:boolean = false;

  /**
   * constructor
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private collectionService: CollectionService,
    private tradeService: TradeService,
    private tradeContractService: TradeContractService,
    private commonService: CommonService,
    private accountService: AccountService,
    private storage: StorageService,
    private titleService:Title,
    private metaService: Meta,
  ) {
    this.pageLoader = true;
  }

  /**
   * Initial Loader
   */
  public async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => this.nftId = params['id']);
    const account = this.storage.get('auth');
    this.account = account !== null ? account : {};
    this.getNft(this.nftId);
    this.getEktaPrice();
    this.accountSubscription = this.accountService.accountObserve.subscribe((response) => {
      if (response.walletAddress !== '' && response.walletAddress !== this.account.walletAddress) {
        this.account = response;
        this.getNft(this.nftId);
      }
    });

    this.statusSubscription = this.tradeService.bidStatus.subscribe((response: any) => {
      if (response.isCompleted) {
        this.getNft(this.nftId);
      }
    });

    this.saleStatusSubscription = this.tradeService.getSaleStatus().subscribe((response: any) => {
      if (response.isClosed) {
        this.getNft(this.nftId);
      }
    });

    this.acceptBidSubscription = this.accountService.acceptBidService.subscribe((response) => {
      if (response.status) {
        this.getNft(this.nftId);
        this.getEktaPrice();
      }
    });
    this.getNftTransactionActivity();
  }

  /**
   * destructor for class
   */
  public ngOnDestroy(): void {
    this.accountSubscription.unsubscribe();
    this.statusSubscription.unsubscribe();
    this.saleStatusSubscription.unsubscribe();
  }


  /**
   * Get nft details
   * @param {string} id - nft id
   */
  public getNft(id:string) {
    window.scroll(0, 0);
    this.collectionService.getNft(id)
        .subscribe({
          next: async (response:any) => {
            this.nft = response['data'];
            console.log('this.nft', this.nft);

            this.nft.nftSellerAddress = this.nft.nftSellerAddress ? this.nft.nftSellerAddress : this.nft.partnerAddress;
            this.nft.nftSellerAddress = web3.utils.toChecksumAddress(this.nft.nftSellerAddress);
            this.metaService.updateTag({ property: 'og:title', content: this.nft?.collectionName });
            this.metaService.updateTag({ property: 'og:image', content: this.nft?.coverPhoto });
            this.titleService.setTitle(this.nft.name);
            if (web3.utils.toChecksumAddress(this.nft.nftSellerAddress) == web3.utils.toChecksumAddress(this.account?.walletAddress) && this.nft.nftSellerAddress != undefined && this.account?.walletAddress != undefined) this.isOwnerWallet = true;
            else this.isOwnerWallet = false;
            if (this.nft.saleType !== 'Buy') {
              this.getOfferList(this.nftId);
            }
            this.pageLoader = false;
          },
          error: (error) => {
            this.pageLoader = false;
            this.toastr.error(error.message);
          },
        });
  }

  /**
   * Gets ekta price
   */
  public getEktaPrice() {
    this.commonService.getEktaPrice().subscribe((response:any) => {
      this.pageLoader = false;
      this.ektaPrice = response['binancecoin'].usd;
    });
  }

  /**
   * Gets offer list
   * @param {any} id
   */
  public getOfferList(id: any) {
    this.tradeService.getOffers(id).subscribe({
      next: async (response: any) => {
        const offers = [...response['data']];
        this.offers = [];
        if (this.nft.forSale === 1 && offers.length > 0) {
          offers.map(async (obj: any, index: number) => {
            if (await this.tradeContractService.getWektaBalance(obj.buyerId) >= Number(web3.utils.toWei(obj.bidAmount.toString(), 'ether'))) {
              this.offers.push(obj);
            }
            if (index === offers.length - 1) {
              const userBidList = this.offers.filter((obj: any) => obj.buyerId === this.account?.walletAddress && ((obj.isCancelled === false && obj.isUpdated === false)));
              this.userBids = userBidList;
            }
          });
        } else {
          this.offers = offers;
        }
        this.pageLoader = false;
      },
      error: (error) => {
        this.pageLoader = false;
        this.toastr.error(error.message, 'Failed to fetch offers');
      },
    });
  }

  /**
   * Gets nft transaction activity
   */
  public getNftTransactionActivity() {
    this.tradeService.getNftTransactionActivity(this.nftId).subscribe({
      next: (response: any) => {
        this.nftActivityList = [...response['data']];
        this.totalPages = Math.ceil(this.nftActivityList.length / this.limit);
        this.nftActivity = this.nftActivityList.slice(this.page, this.limit);
        console.log('this.nftActivity', this.nftActivity);
      },
      error: (error) => {
        this.toastr.error(error.message, 'Failed to fetch offers');
      },
    });
  }
}
