import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CollectionService } from 'src/app/services/collection.service';
import { Meta, Title } from '@angular/platform-browser';
import { StorageService } from 'ng-blockchainx';

@Component({
  selector: 'app-trendings',
  templateUrl: './trendings.component.html',
  styleUrls: ['./trendings.component.css'],
})

/**
 * Component
 */
export class TrendingsComponent implements OnInit {
  public loading: boolean = false;
  public pageLoader:boolean = false;


  /**
   * constructor
   */
  constructor(
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private collectionService: CollectionService,
    private router: Router,
    private titleService:Title,
    private metaService: Meta,
    private storageService: StorageService,

  ) {
    this.pageLoader = true;
  }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    window.scroll(0, 0);
    this.loading = true;
  }
}

