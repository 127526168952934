import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CollectionService } from 'src/app/services/collection.service';
import * as moment from 'moment';
import { CommonHelper } from 'src/app/helpers/common.helper';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-hot-bids',
  templateUrl: './hot-bids.component.html',
  styleUrls: ['./hot-bids.component.css'],
})

/**
 * Explore Class
 */
export class HotBidsComponent implements OnInit {
  public nfts:any=[];
  public innerWidth!:number;
  public cell!: number;
  public pageLoader:boolean = false;
  public getNftResponse : boolean = false;
  public blogPost: OwlOptions = {
    loop: false,
    margin: 30,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
      1600: {
        items: 4,
        margin: 40,
      },
    },
  };

  /**
   * Creates an instance of hot bids component.
   */
  constructor( private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private collectionService: CollectionService,
  ) {
    this.pageLoader = true;
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.getHotBids();
    this.onResize();
  }

  /**
   * Get Hot Bids
   */
  getHotBids() {
    this.getNftResponse = true;
    this.collectionService.getHotBids()
        .subscribe({
          next: (response:any) => {
            this.getNftResponse = false;
            this.nfts = response['data'];
            this.pageLoader = false;
          },
          error: (error) => {
            this.getNftResponse = false;
            this.pageLoader = false;
          },
        });
  }

  /**
   * window resize
   */
    @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth=window.innerWidth;

    if (this.innerWidth <= 640) {
      this.cell = 1;
    } else if (this.innerWidth <= 1024) {
      this.cell = 2;
    } else if (this.innerWidth <= 1280) {
      this.cell = 3;
    } else {
      this.cell = 4;
    }
  }
    /**
   * Check if bid is ended
   *
   * @param {string} date
   * @return {boolean}
   */
    public checkIfBidEnded(date:string):boolean {
      return new Date().getTime() > new Date(date).getTime();
    }
    /**
         * Time for timedbid
         *
         * @param {string} time
         * @return {string}
         */
    public getTimeForTimedBid(time:string):string {
      const timeCalculated = CommonHelper.getTimerForSale(moment(time).unix());
      if (timeCalculated?.days) {
        return `${timeCalculated?.days} days left`;
      } else if (timeCalculated?.hours) {
        return `${timeCalculated?.hours} hours left`;
      } else if (timeCalculated?.minutes) {
        return `${timeCalculated?.minutes} minutes left`;
      } else {
        return `${timeCalculated?.seconds} seconds left`;
      }
    }
}
