import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesRoutingModule } from './pipes-routing.module';
import { ObjectTextFilterPipe } from './object-text-filter.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { TextFilterPipe } from './text-filter.pipe';
import { TimerPipe } from './timer.pipe';

@NgModule({
  declarations: [
    ObjectTextFilterPipe,
    ShortNumberPipe,
    TextFilterPipe,
    TimerPipe,
  ],
  imports: [
    CommonModule,
    PipesRoutingModule,
  ],
  exports: [
    ObjectTextFilterPipe,
    ShortNumberPipe,
    TextFilterPipe,
    TimerPipe,
  ],
})

/**
 * Export Class
 */
export class PipesModule { }
