<section class="trending-nft">
    <div class="container">
        <div class="row wow fadeIn">
            <div class="col-lg-12">
                <div class="text-center title-wrap">
                    <h2>Trending NFT</h2>
                    <p>Discover a new realm of gaming on the Blockchainx Arcade, a hub of games powered by Blockchainx <br/>
                        uniting gamers, investors and developers seamlessly.</p>
                </div>
            </div>
            <owl-carousel-o [options]="blogPost">
                    <ng-template carouselSlide *ngFor="let nft of nfts; let i = index;">
                    <div class="d-item">
                        <div class="nft__item" [routerLink]="['/single-nft-details', nft._id]" [ngClass]="{'row col-md-4': nfts.length < 2}">
                            <div class="nft-common-details">
                                <div class="nft__item_wrap">
                                    <div class="card-img">
                                        <img [src]="[nft.nftImageS3 ? (nft.nftImageS3 ? nft.nftImageS3 : nft.nftCollection.baseUrl + nft.nftImage) : 'assets/images/collections/coll-item-1.jpg']"
                                            class="lazy nft__item_preview" alt="">

                                            <a [routerLink]="['/single-nft-details']" class="btn-style-one">
                                                Buy Now
                                            </a>
                                            <a class="btn-style-one"
                                                *ngIf="(checkIfBidEnded(nft.bidStartTime) && !checkIfBidEnded(nft.bidEndTime) && nft.saleType == 'TimedBid')">

                                                Bid Now
                                            </a>
                                            <a class="btn-style-one"
                                                *ngIf="(checkIfBidEnded(nft.bidStartTime) && nft.saleType === 'Bid')">
                                                Offer Now
                                            </a>
                                        </div>
                                </div>
                                <div class="nft__item_info">
                                        <a [routerLink]="['/single-nft-details']">
                                        <h3 class="text-truncate" title="{{nft?.name}} #{{nft.tokenId}}">{{nft?.name}}
                                            #{{nft.tokenId}}</h3>
                                        </a>
                                        <div class="collection">
                                            <h6>Collection</h6>
                                            <h4 class="text-truncate" title="{{nft?.nftCollection?.collectionName}}">
                                                {{nft?.nftCollection?.collectionName}}</h4>
                                            </div>                                    
                                    <hr />
                                </div>
                            </div>
                            <div class="buy-details" *ngIf="nft.saleType != 'Buy'">
                                
                                <div class="floor-section d-flex justify-content-between">
                                    <span class="">Current Bid</span>
                                    <div class="d-flex gap-2">
                                    <img src="assets/images/icons/bnb.svg"
                                        class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                    <span
                                        class="truncate lg:text-clip floor-price-text">{{nft.price}} <span>BNB</span></span>
                                    </div>
                                </div>

                               
                            </div>
                            <div class="buy-details" *ngIf="nft.saleType == 'Buy'">

                                <div class="floor-section d-flex justify-content-between">
                                    <span class="">Current Bid</span>
                                    <div class="d-flex gap-2">
                                    <img src="assets/images/icons/bnb.svg"
                                        class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                    <span
                                        class="truncate lg:text-clip floor-price-text">{{nft.price}} <span>BNB</span></span>
                                    </div>
                                </div>

                                <!-- <div class="d-flex price-div text-them justify-content-between">
                                    {{nft.price}} BNB
                                    <a class="btn-main text-them buy-btn cursor-pointer">
                                        Buy Now
                                    </a>
                                </div> -->
                            </div>
                        </div>
                    </div>


                </ng-template>
            
            </owl-carousel-o>
            <div class="row" *ngIf="nfts.length == 0 && !getNftResponse" class="no-trend-nft">
                Trending NFT's yet to be update
            </div>
            <div class="row" *ngIf="getNftResponse">
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
            </div>
            <div>
            </div>
        </div>
        <a [routerLink]="['/nft-launchpad']" *ngIf="getNftResponse" class="btn-style-default">Explore All NFT</a>
    </div>
</section>