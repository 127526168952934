import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textFilter',
})
/**
 * Text filter
 */
export class TextFilterPipe implements PipeTransform {
  /**
   * transform function
   * @param {any[]} textFields
   * @param {any} searchWord
   * @return {any}
   */
  transform(textFields: any[], searchWord: any): any {
    return textFields.filter((val:string = '') => val?.toLowerCase().includes(searchWord.toLowerCase()));
  }
}
