import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StorageService } from 'ng-blockchainx';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EditProfileService } from 'src/app/services/edit-profile.service';
import { ProfileService } from 'src/app/services/profile.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/auth.service';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
})

/**
 * Component
 */
export class EditProfileComponent implements OnInit {
  public editProfileForm!: FormGroup | any;
  public files: File[] = [];
  public user: any;
  public userDetails: any;
  public tempProfile: string = '';
  public profile: any;
  public banner: any;
  public bannerChange: any = false;
  public updateProfileLoader: any = false;
  public urlValidationTextDiscard: string = '';
  public urlValidationTextInsta: string = '';
  public urlValidationTextTwitt: string = '';
  public urlValidationTextFb: string = '';
  public userNameError: string = '';
  public userEmailError: string = '';
  public remainingTextName: string = '';
  public remainingTextUserName: string = '';
  public remainingTextBio: string = '';
  public userDetailsName: any;
  public userDetailsuserName: any;
  public userDetailsbio: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  profileFile: File | undefined;
  showCrope: boolean = false;
  public pageLoader:boolean = false;


  /**
   * Creates an instance of edit profile component.
   */
  constructor(
    private formBuilder: FormBuilder,
    private storage: StorageService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private editProfileService: EditProfileService,
    private router: Router,
    private commonService: CommonService,
    private authService: AuthService,
  ) {
    this.pageLoader = false;
  }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.user = this.storage.get('user');
    this.editProfileForm = this.formBuilder.group({
      displayName: ['', [Validators.required]],
      userName: ['', [Validators.required]],
      emailAddress: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      walletAddress: ['', [Validators.required]],
      bio: [''],
      website: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      discord: [''],
      facebook: [''],
      twitter: [''],
      instagram: [''],
      youtube: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      profileImage: [''],
      profileBanner: [''],
    });
    this.getUser();
  }


  /**
   * Files change event
   * @param {any} event
   */
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  /**
   * Images cropped
   * @param {any} event
   */
  imageCropped(event: ImageCroppedEvent) {
    this.profileFile = new File([base64ToFile(event.base64 as string)], 'profile-pic.jpeg');
    this.croppedImage = event.base64;
  }

  /**
   * Gets profile form
   */
  public get profileForm() {
    return this.editProfileForm.controls;
  }

  /**
   * Valids url
   * @param {string} str
   * @return  {string}
   */
  public validURL(str: string) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  /**
   * Updates profile
   */
  public async updateProfile() {
    const { displayName, userName, emailAddress, walletAddress, bio, website, twitter, discord, instagram, facebook, youtube, profileBanner } = this.profileForm;

    if (this.validURL(twitter.value)) {
      this.urlValidationTextTwitt = 'Url not allowed. Please enter username only';
      return;
    }
    if (this.validURL(discord.value)) {
      this.urlValidationTextDiscard = 'Url not allowed. Please enter username only';
      return;
    }
    if (this.validURL(instagram.value)) {
      this.urlValidationTextInsta = 'Url not allowed. Please enter username only';
      return;
    }
    if (this.validURL(facebook.value)) {
      this.urlValidationTextFb = 'Url not allowed. Please enter username only';
      return;
    } else {
      this.urlValidationTextFb = '';
    }
    if (this.editProfileForm.invalid || this.userNameError !== '' || this.userEmailError !== '') return;

    this.updateProfileLoader = true;

    let profilePreSignedUrl;
    if (this.profileFile) {
      profilePreSignedUrl = await this.commonService.getPreSignedUrl(userName.value, `${(userName.value).toLowerCase()}-${Date.now()}.${this.profileFile.name.split('.').pop()}`, this.profileFile.type);

      // upload image to presigned url
      await this.commonService.uploadToPresignedUrl(profilePreSignedUrl['url'], this.profileFile);
    }

    // get presigned url for cover image
    let coverPreSignedUrl;
    if (profileBanner.value != '') {
      coverPreSignedUrl = await this.commonService.getPreSignedUrl(userName.value, `${(userName.value).toLowerCase()}-cover-${Date.now()}.${profileBanner.value.name.split('.').pop()}`, profileBanner.value.type);

      // upload image to presigned url
      await this.commonService.uploadToPresignedUrl(coverPreSignedUrl['url'], profileBanner.value);
    }

    const profile = {
      'name': displayName.value,
      'userName': userName.value,
      'email': emailAddress.value,
      'walletAddress': walletAddress.value,
      'bio': bio.value ? bio.value : '',
      'website': website.value ? website.value : '',
      'discord': discord.value ? discord.value : '',
      'facebook': facebook.value ? facebook.value : '',
      'twitter': twitter.value ? twitter.value : '',
      'instagram': instagram.value ? instagram.value : '',
      'youtube': youtube.value ? youtube.value : '',
      'profileImage': this.profileFile ? profilePreSignedUrl['url'].split('?').shift() : this.profile ? this.profile : '',
      'profileBanner': profileBanner.value != '' ? coverPreSignedUrl['url'].split('?').shift() : this.banner ? this.banner : '',
    };

    this.editProfileService.updateProfile(this.user._id, profile).subscribe({
      next: (response: any) => {
        this.updateProfileLoader = false;
        this.toastr.success(response.message);
        this.router.navigate(['/profile']);
      },
      error: (error) => {
        this.updateProfileLoader = false;
        this.toastr.error(error.message, 'Failed to update profile');
      },
    });
  }

  /**
   * Determines whether select on
   * @param {any} event
   */
  public onSelect(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      // check file is valid
      if (!this.commonService.validateFile(event.target.files[0].name)) {
        this.toastr.error('This format is not supported');
        return;
      }

      reader.onload = () => {
        this.bannerChange = reader.result as string;
        this.banner = event.target.files[0];
        this.editProfileForm.patchValue({
          profileBanner: event.target.files[0],
        });
      };
    }
  }
  /**
   * Determines whether profile change on
   * @param {any} event
   */
  public onProfileChange(event: any) {
    this.showCrope = true;
    this.imageChangedEvent = event;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      // check file is valid
      if (!this.commonService.validateFile(event.target.files[0].name)) {
        this.toastr.error('This format is not supported');
        return;
      }

      reader.onload = () => {
        this.tempProfile = reader.result as string;
        this.profile = event.target.files[0];
        this.editProfileForm.patchValue({
          profileImage: event.target.files[0],
        });
      };
    }
  }

  /**
   * Gets user
   */
  public getUser() {
    this.user = this.storage.get('user');
    this.profileService.getUser(this.user._id).subscribe({
      next: (response: any) => {
        this.userDetails = response['data']['user'];
        if (this.userDetails.profileImage) this.profile = this.userDetails.profileImage; else this.profile = null;
        if (this.userDetails.profileBanner) this.banner = this.userDetails.profileBanner; else this.banner = null;
        this.userDetailsName = this.userDetails.name;
        this.userDetailsuserName = this.userDetails.userName;
        this.userDetailsbio = this.userDetails.bio;
        this.editProfileForm.patchValue({
          displayName: this.userDetails.name,
          userName: this.userDetails.userName,
          emailAddress: this.userDetails.email,
          walletAddress: this.userDetails.walletAddress,
          bio: this.userDetails.bio,
          website: this.userDetails.website,
          discord: this.userDetails.discord,
          facebook: this.userDetails.facebook,
          twitter: this.userDetails.twitter,
          instagram: this.userDetails.instagram,
          youtube: this.userDetails.youtube,
        });
        this.pageLoader = false;
      },
      error: (error) => {
        this.pageLoader = false;
        this.toastr.error(error.message, 'Failed to fetch user details');
      },
    });
  }
  /**
   * Checks user email
   * @param {string} username
   */
  public checkUserEmail(username: string) {
    this.authService.checkUserEmail(username, this.user._id).subscribe({
      next: (response: any) => {
        if (response.data.status == 1) {
          this.userEmailError = 'Email is already exists';
        } else {
          this.userEmailError = '';
        }
      },
      error: () => {
        this.userEmailError = '';
      },
    });
  }

  /**
   * Values change name
   * @param {string} value
   */
  public valueChangeName(value:string) {
    const textLength = value ? (value)?.length : 0;
    this.remainingTextName = textLength +'/'+30 + ' Characters left';
  }

  /**
   * Params edit profile component
   * @param {string} value
   */
  public valueChangeUserName(value:string) {
    const textLength = value ? (value)?.length : 0;
    this.remainingTextUserName = textLength +'/'+15 + ' Characters left';
  }

  /**
   * Values change bio
   * @param {string} value
   */
  public valueChangeBio(value:string) {
    const textLength = (value)?.length ? (value)?.length : 0;
    this.remainingTextBio = textLength +'/'+200 + ' Characters left';
  }

  /**
   * Checks user name
   * @param {string} username
   */
  public checkUserName(username: string) {
    this.authService.checkUserName(username, this.user._id).subscribe({
      next: (response: any) => {
        if (response.data.status == 1) {
          this.userNameError = 'Username already exists';
        } else {
          this.userNameError = '';
        }
      },
      error: () => {
        this.userNameError = '';
      },
    });
  }
}
