import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[loaded]',
})

/**
 * Export Class
 */
export class LoadedDirective {
  @Output() loaded = new EventEmitter();

  /**
   * Hosts listener
   */
  @HostListener('load')
  onLoad() {
    this.loaded.emit();
  }

  /**
   * Creates an instance of loaded directive.
   * @param {ElementRef} elRef
   */
  constructor(private elRef: ElementRef<HTMLImageElement>) {
    if (this.elRef.nativeElement.complete) {
      this.loaded.emit();
    }
  }
}
