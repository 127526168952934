import { Component, HostListener, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { CollectionService } from 'src/app/services/collection.service';

@Component({
  selector: 'app-top-collections',
  templateUrl: './top-collections.component.html',
  styleUrls: ['./top-collections.component.css'],
})

/**
 * Component
 */
export class TopCollectionsComponent implements OnInit {
  public collections:any = [];
  public collection:any = {};
  public innerWidth!:number;
  public pageLoader:boolean = false;
  public cell!: number;
  public getNftResponse : boolean = false;
  public blogPost: OwlOptions = {
    loop: false,
    margin: 30,
    autoplay: false,
    center: false,
    dots: false,
    nav: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1600: {
        items: 3,
        margin: 40,
      },
    },
  };
  /**
   * Creates an instance of top collections component.
   */
  constructor( private toastr: ToastrService,
    private collectionService: CollectionService) { }

  /**
   * on init
   */
  ngOnInit(): void {
    this.topCollections();
    this.onResize();
  }
  /**
   * Get Top Collections
   */
  topCollections() {
    this.getNftResponse = true;
    this.collectionService.getTopCollections().subscribe({
      next: (response:any) => {
        this.getNftResponse = false;
        this.collections = [...response.data];
        this.pageLoader = false;
      },
      error: (error) => {
        this.getNftResponse = false;
        this.pageLoader = false;
      },
    });
  }
  /**
   * window resize
   */
    @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth=window.innerWidth;

    if (this.innerWidth <= 640) {
      this.cell = 1;
    } else if (this.innerWidth <= 991) {
      this.cell = 2;
    } else if (this.innerWidth <= 1199) {
      this.cell = 3;
    } else {
      this.cell = 3;
    }
  }
}
