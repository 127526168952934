import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3(environment.provider);
const abi = require('../constants/abi/erc721.json');


@Injectable({
  providedIn: 'root',
})

/**
 * Export Class
 */
export class Erc721ContractService {
  /**
   * Gets token uri
   * @param {string} contractAddress
   * @param {string} tokenId
   * @return {httpresponse}
   */
  async getTokenURI(contractAddress: string, tokenId: string) {
    const contract = new web3.eth.Contract(abi, contractAddress);
    return await contract.methods.tokenURI(tokenId).call();
  }

  /**
   * Gets base uri
   * @param {string} contractAddress
   * @return {httpresponse}
   */
  async getBaseUri(contractAddress: string) {
    const contract = new web3.eth.Contract(abi, contractAddress);
    return await contract.methods.baseURI().call();
  }

  /**
   * Gets name
   * @param {string} contractAddress
   * @return {httresponse}
   */
  async getName(contractAddress: string) {
    const contract = new web3.eth.Contract(abi, contractAddress);
    return await contract.methods.name().call();
  }
}
