import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css'],
})

/**
 * Export Class
 */
export class CollectionsComponent implements OnInit {
  /**
   * on init
   */
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
