<aside class="filter-group">
    <div class="item_filter_group">
        <h4>Partners</h4>
        <div class="search">
            <img src="assets/images/search.png" class="w-3 h-3 md:w-4 md:h-4" alt="search">
            <input type="text" placeholder="Search" name="search"
                class="pl-4 w-full fw-regular focus:outline-none outline-none text-sm partner-search"
                [(ngModel)]="partnerFilterSearch">
        </div>
        <div class="de_form partner-list-section">
            <div *ngIf="searchLength == 0" class="d-flex justify-content-evenly no-image">
                <img src="assets/images/no-image.svg" class="" alt="bx-nft">
                <p class="">No Partners Found</p>
            </div>
            <div class="de_checkbox"
                *ngFor="let partner of partnerList | objectTextFilter:partnerFilterSearch:'name'; let i = index;">
                <input #partnerSelectedList (click)="onPartnerCheck()"
                    [checked]="selectedPartner?.indexOf(partner?._id) !== -1" type="checkbox" id="partner-name{{i}}"
                    value="{{partner?._id}}">
                <label for="partner-name{{i}}">{{partner?.name}}</label>
                <label class="flex text-white text-base fw-semibold cursor-pointer partner-count">
                    <p class="text-grey pl-1.5">({{partner?.collectionCount}})</p>
                </label>
            </div>
        </div>
    </div>

    <div class="item_filter_group">
        <h4>All Category</h4>
        <div class="de_form">
            <div class="de_checkbox"
                *ngFor="let category of categoryFilterList | objectTextFilter:categoryFilterSearch:'categoryName'; let i = index;">
                <input #categorySelectedList (click)="onCategoryCheck()" type="checkbox" id="category_id{{i}}"
                    value="{{category?._id}}">
                <label for="category_id{{i}}" for="category_id{{i}}">{{category?.categoryName}}</label>
            </div>
            <div *ngIf="categoryFilterList.length == 0" class="d-flex justify-content-evenly no-image">
                <img src="assets/images/no-image.svg" class="" alt="bx-nft">
                <p class="">No Category Found</p>
            </div>
        </div>
    </div>
</aside>