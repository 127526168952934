import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { TimerDirective } from 'src/app/directives/timer.directive';

@Component({
  selector: 'app-nft-lists',
  templateUrl: './nft-lists.component.html',
  styleUrls: ['./nft-lists.component.css'],
})

/**
 * Export Class
 */
export class NftListsComponent {
  @Input() collection : any = [];
  @Input() nfts : any = [];
  public bidTimerValue:any;
  public timedBidTimerValue:any;
  public bidEndtimerValue:any;
  /**
   * Creates an instance of nft lists component.
   */
  constructor(
    private timer:TimerDirective,
  ) {
  }


  /**
   * Timers nft details component
   * @param {any} date
   * @param {any} i
   */
  public timedBidTimer(date:any, i:any) {
    if (moment(date).isAfter(new Date())) {
      setInterval(() => {
        const countDownDate = new Date(date).getTime();
        this.timedBidTimerValue = this.timer.getTimer(countDownDate);
        const countDownValue = document.getElementById('countDown'+i);
        if (countDownValue) {
          if (!this.timedBidTimerValue) {
            return;
          }
          countDownValue.innerHTML = this.timedBidTimerValue;
        }
      }, 1000);
    }
  }

  /**
   * Timers nft details component
   * @param {any} date
   * @param {any} i
   */
  public bidEndtimer(date:any, i:any) {
    if (moment(date).isAfter(new Date())) {
      setInterval(() => {
        const countDownDate = new Date(date).getTime();
        this.bidEndtimerValue = this.timer.getTimer(countDownDate);
        const countDownValue = document.getElementById('countDown'+i);
        if (countDownValue) {
          if (!this.bidEndtimerValue) {
            return;
          }
          countDownValue.innerHTML = this.bidEndtimerValue;
        }
      }, 1000);
    }
  }

  /**
   * Timers nft details component
   * @param {any} date
   * @param {any} i
   */
  public OffersTimer(date:any, i:any) {
    if (moment(date).isAfter(new Date())) {
      setInterval(() => {
        const countDownDate = new Date(date).getTime();
        this.bidEndtimerValue = this.timer.getTimer(countDownDate);

        const countDownValue = document.getElementById('countDownOffers'+i);
        if (countDownValue) {
          if (!this.bidEndtimerValue) {
            return;
          }
          countDownValue.innerHTML = this.bidEndtimerValue;
        }
      }, 1000);
    }
  }

  /**
 * Check if bid is Started
 * @param {string} date
 * @return {boolean}
 */
  public checkIfStartEnded(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }


  /**
   * Check if bid is ended
   * @param {string} date
   * @return {boolean}
   */
  public checkIfBidEnded(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }
}
