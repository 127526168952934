import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FeaturedPartnersService } from 'src/app/services/featured-partners.service';


@Component({
  selector: 'app-top-partners',
  templateUrl: './top-partners.component.html',
  styleUrls: ['./top-partners.component.css'],
})

/**
 * Export Class
 */
export class TopPartnersComponent implements OnInit {
  public partners: any = [];
  public pageLoader:boolean = false;
  /**
   * Creates an instance of top sellers component.
   */
  constructor(private featuredPartners: FeaturedPartnersService, private toastr: ToastrService) {
    this.pageLoader = true;
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.getLatestPartners();
  }

  /**
   * Gets latest partners
   */
  getLatestPartners() {
    this.featuredPartners.getFeaturedPartners()
        .subscribe({
          next: (response:any) => {
            this.partners = response['data'];
            this.pageLoader = false;
          },
          error: (error : any) => {
            this.pageLoader = false;
          },
        });
  }
}

