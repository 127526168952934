<div class="spacer-10"></div>
<div class="d-flex flex-row trade-details-card">
    <div class="mr40">
        <h6>Creator</h6>
        <div class="item_author">
            <div class="author_list_pp">
                <a >
                    <img class="lazy profile-img-icon" src="{{nft?.nftCollection.partner?.profileImage ? nft?.nftCollection.partner?.profileImage : 'assets/images/collections/coll-thumbnail-1.jpg'}}" alt="Creator-Profile">
                    <i class="fa fa-check"></i>
                </a>
            </div>
            <div class="author_list_info">
                <a [routerLink]="['/partner', nft?.nftCollection.partner?._id]" >{{nft?.nftCollection.partner?.name}}</a>
            </div>
        </div>
    </div>
    <div>
        <h6>Collection</h6>
        <div class="item_author">
            <div class="author_list_pp">
                <a >
                    <img class="lazy profile-img-icon"
                        src="{{nft?.nftCollection?.collectionImage ? nft?.nftCollection?.collectionImage : 'assets/images/collections/coll-thumbnail-1.jpg'}}"
                        alt="Profile-Image">
                    <i class="fa fa-check"></i>
                </a>
            </div>
            <div class="author_list_info">
                <a [routerLink]="['/nft-details', nft?.nftCollection?._id]">{{nft?.nftCollection?.collectionName}}</a>
            </div>
        </div>
    </div>
</div>

<div class="spacer-40"></div>

<div class="de_tab tab_simple">

    <ul class="de_nav">
        <li (click)="grapgToggle(false)" class="active"><span>Details</span></li>
        <li (click)="grapgToggle(true)" *ngIf="priceAverage > 0"><span>Pricing History</span></li>
        <li (click)="grapgToggle(false)">
            <span *ngIf="nft?.saleType !== 'Buy' && nft.nftSale && nft.nftSellerAddress === account?.walletAddress && (nft.isPendingConfirmation === 0 && nft.isSold === 0);">Offers</span>
        </li>
    </ul>
    <!-- Pricing History -->
    <div id="chart" *ngIf="showgraph">
                    <h4 class="text-sm fw-regular pb-2 text-end">All Time Avg. Price</h4>
                    <div class="flex items-center gap-x-2 text-end">
                        <span class="text-md fw-medium">{{priceAverage.toFixed(2)}} BNB</span>
                    </div>
                    
                
        <apx-chart [series]="options?.series" [chart]="options?.chart" [xaxis]="options?.xaxis"
            >
        </apx-chart>
    </div>
    <div class="de_tab_content">
        
        <!-- Details Tab  -->
        <div class="tab-1">
            <h6>Owner</h6>
            <div class="item_author">
                <div class="author_list_pp">
                    <a >
                        <img class="lazy profile-img-icon" src="{{nft?.result?.nftOwnerProfileImage ? nft?.result?.nftOwnerProfileImage : 'assets/images/collections/coll-thumbnail-1.jpg'}}" alt="Owner-Profile">
                        <i class="fa fa-check"></i>
                    </a>
                </div>
                <div class="author_list_info">
                    <a [routerLink]="['/partner', nft?.result?.nftOwnerId]">{{nft?.result?.nftOwnerName}}</a>
                </div>
            </div>
            <div class="spacer-40"></div>
            <h6>Attributes</h6>
            <div class="row gx-2" *ngIf="nft?.attributes?.length > 0">
                <ng-container *ngFor="let attribute of nft?.attributes">
                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="objectKeys(attribute)[0]">
                        <a (click)="attributeFilter(attribute)" class="nft_attr cursor-pointer">
                            <h5>{{objectKeys(attribute)[0]}}</h5>
                            <h4>{{attribute[objectKeys(attribute)[0]]}}</h4>
                            <span *ngIf="attribute.attributePercentage != 'New triat'">{{attribute.attributePercentage}}
                                have this triat</span>
                            <span
                                *ngIf="attribute.attributePercentage == 'New triat'">{{attribute.attributePercentage}}</span>
                        </a>
                    </div>
                </ng-container>
            </div>

        </div>


        
        <div class="tab-2"></div>
         <!-- Offers Tab -->
         <div class="tab-3" *ngIf="nft.saleType != 'Buy'">
            <div class="p_list" *ngFor="let offer of offers">
                <div class="p_list_pp">
                    <a >
                        <img class="lazy profile-img-icon"
                            src="{{offer.buyerImage ? offer.buyerImage : 'assets/images/author_single/author_thumbnail.jpg'}}"
                            alt="">
                        <i class="fa fa-check"></i>
                    </a>
                </div>
                <div class="p_list_info">
                    <b>{{offer.buyerName}}</b>
                    <div class="d-flex">{{(offer.bidAmount).toFixed(4)}} BNB
                        <div class="ms-5">
                            <p class="text-sm fw-regular" style="color: red;" *ngIf="offer.isCancelled">Cancelled</p>
                            <p class="text-sm fw-regular" style="color: rgb(0, 106, 255);" *ngIf="offer.isUpdated">
                                Edited</p>
                            <p class="text-sm fw-regular" style="color: green;"
                                *ngIf="!offer.isCancelled && !offer.isUpdated">Active</p>
                        </div>
                        <div class="ms-5"
                            *ngIf="nft.nftSale && nft.nftSellerAddress === account?.walletAddress && (nft.isPendingConfirmation === 0 && nft.isSold === 0)">
                            <span *ngIf="nft.saleType == 'TimedBid'">
                                <span *ngIf="!checkIfBidEnded(nft.bidEndTime)"> - </span>
                                <p class="text-sm fw-regular" *ngIf="offer.isCancelled || offer.isUpdated"> -
                                </p>
                            </span>
                            <span >
                                <img src="assets/icons/check-square.svg" class="cursor-pointer w-6 h-6 cursor-pointer"
                                    alt="" (click)="acceptBidModal(offer)" data-bs-toggle="modal"
                                    data-bs-target="#acceptBidModal" *ngIf="!offer.isCancelled && !offer.isUpdated">
                                <p class="text-sm fw-regular" *ngIf="offer.isCancelled || offer.isUpdated"> -
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p_list" *ngIf="offers.length <= 0">
                <p>Offers Not Found</p>
            </div>
            <div class="modal fade" id="acceptBidModal" tabindex="-1" aria-labelledby="acceptBidModal"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered de-modal">
                    <div class="modal-content">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #acceptOfferClose></button>
                        <div class="modal-body">
                            <div class="p-3 form-border">
                                <h3>Accept Offer</h3>
                                You are about to accept bit on <b>{{nft?.nftCollection?.collectionName}}
                                    #{{nft.tokenId}}</b> for <b
                                    *ngIf="offer.bidAmount > 0">{{offer.bidAmount.toFixed(4)}} BNB</b>
                                <div class="spacer-single"></div>
                                <div class="de-flex">
                                    <div>Price</div>
                                    <div><b *ngIf="offer?.bidAmount > 0">{{offer?.bidAmount.toFixed(4)}} BNB</b></div>
                                </div>
                                <div class="de-flex">
                                    <div>Creator Fee <b>({{nft?.nftCollection?.creatorFee}}%)</b> </div>
                                    <div><b *ngIf="offer?.creatorFee > 0">{{offer?.creatorFee.toFixed(4)}} BNB</b></div>
                                </div>
                                <div class="de-flex">
                                    <div>Platform Fee <b>({{nft?.nftCollection?.ektaFee}}%)</b> </div>
                                    <div><b *ngIf="offer?.ektaFee > 0">{{offer?.ektaFee.toFixed(4)}} BNB</b></div>
                                </div>
                                <div class="de-flex">
                                    <div>You will receive</div>
                                    <div><b>{{offer?.total?.toFixed(4)}} BNB</b></div>
                                </div>
                                <div class="spacer-single"></div>
                                <button (click)="acceptBid(offer)" [disabled]="showBidLoader" class="cursor-pointer btn-main btn-fullwidth">
                                        <span *ngIf="showBidLoader">
                                            Processing...
                                        </span>
                                        <span *ngIf="!showBidLoader">
                                            Accept Offer
                                        </span>
                                        
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="spacer-10"></div>