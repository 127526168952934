<div aria-label="section" class="wallect-popup-section">
    <div class="">
        <div class="row connect-row">
            <div class="col-lg-6 mb30">
                <a class="box-url cursor-pointer" (click)="connectMetaMask()">
                    <span class="box-url-label">Most Popular</span>
                    <img src="assets/images/wallet/1.png" alt="" class="mb20">
                    <h4>Metamask</h4>
                    <p>Start exploring blockchain applications in seconds. Trusted by over 1 million users
                        worldwide.</p>
                </a>
            </div>

            <!-- <div class="col-lg-6 mb30">
                <a class="box-url cursor-pointer">
                    <img src="assets/images/wallet/4.png" alt="" class="mb20">
                    <h4>WalletConnect</h4>
                    <p>Open source protocol for connecting decentralised applications to mobile wallets.</p>
                </a>
            </div> -->

        </div>
    </div>
</div>

