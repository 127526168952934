/* eslint-disable require-jsdoc */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, catchError, Observable, retry } from 'rxjs';
import { StorageService } from 'ng-blockchainx';
import { CommonService } from './common.service';

interface BuyNftParam {
  nftId: string;
  buyerId: string;
  sellerId: string;
  txHash: string;
  saleType: string;
}

@Injectable({
  providedIn: 'root',
})
export class TradeService {
  
  public bidStatus = new BehaviorSubject({
    isCompleted: false
  });
  public saleStatus = new BehaviorSubject({
    isClosed: false
  });

  constructor(
    private http: HttpClient, 
    private storage: StorageService, 
    private commonService: CommonService
  ) {}

  public buyNft(reqBody: BuyNftParam) {
    const account: any = this.storage.get('auth');
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + account.token,
    });
    return this.http.post(`${environment.api_url}/api/v1/buy-nft`, reqBody, { headers: reqHeader })
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  public getOffers(id: any) {
    return this.http.get(`${environment.api_url}/api/v1/get-bid-list/${id}`)
    .pipe(
        retry(2), // retry a failed request up to n times
        catchError(this.commonService.handleError), // handle error
    );
  }

  public getNftTransactionActivity(nftId: string) {
    return this.http.get(`${environment.api_url}/api/v1/get-nft-sale-details/${nftId}`)
    .pipe(
        retry(2), // retry a failed request up to n times
        catchError(this.commonService.handleError), // handle error
    );
  }

  public placeSellOrder(params: any) {
    return this.http.post(`${environment.api_url}/api/v1/sale-nft`, params)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  public getPriceHistory(id: number) {
    return this.http.get(`${environment.api_url}/api/v1/get-nft-sale-history/${id}`,)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  public acceptBid(id: string) {
    return this.http.post(`${environment.api_url}/api/v1/confirm-bid/${id}`, {})
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  public cancelBid(id: string, reqBody: any) {
    return this.http.patch(`${environment.api_url}/api/v1/edit-bid-transaction/${id}`, reqBody)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  public editBid(id: string, reqBody: any) {
    return this.http.patch(`${environment.api_url}/api/v1/edit-bid-price/${id}`, reqBody)
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }

  sendBidTrigger(status: boolean) {
    this.bidStatus.next({isCompleted: status});
  }

  getBidStatus() {
    return this.bidStatus.asObservable();
  }

  sendSaleStatus(status: boolean) {
    this.saleStatus.next({isClosed: status});
  }

  getSaleStatus() {
    return this.saleStatus.asObservable();
  }

  cancelSale(id: string) {
    return this.http.post(`${environment.api_url}/api/v1/remove-nft-from-sale/${id}`, {})
    .pipe(
      retry(2), // retry a failed request up to n times
      catchError(this.commonService.handleError), // handle error
    );
  }
}
