import { Component, OnInit, NgZone, HostListener, ElementRef, Inject, ViewChild } from '@angular/core';
import { MetaMaskService, StorageService, WalletConnectService } from 'ng-blockchainx';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Web3 from 'web3';

import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/services/account.service';
import { DOCUMENT } from '@angular/common';
import { ClipboardService } from 'ngx-clipboard';

const web3 = new Web3(environment.provider);

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})

/**
 * Component
 */
export class NavBarComponent implements OnInit {
  public isScrolled: boolean = false;
  public isLearnRespOpen: boolean = false;
  public account:any = {};
  public user: any = {};
  public chainId: string = '';
  public registerForm: any = this.formBuilder.group({
    name: ['', [Validators.required]],
    profileImage: [''],
    profileImagePreview: [''],
    walletAddress: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    userName: ['', [Validators.required]],
  });
  public formSubmitted:boolean = false;
  public tempProfile:string = '';
  public networkChangeDetected:boolean = false;
  public metaMaskSubscription: any;
  public userNameError: string = '';
  public remainingTextName: string = '';
  public remainingTextUserName: string = '';
  public isMetamask: boolean = true;
  public metamaskInstallLink = '';
  public registerFormLoader: boolean = false;
  public isCreateAccount:boolean = false;
  public textValueName:any;
  public textValueUserName:any;
  @ViewChild('createAccountModalOpen') createAccountModalOpen:any;
  @ViewChild('createAccountModalClose') createAccountModalClose:any;
  @ViewChild('connectWalletModalClose') connectWalletModalClose:any;
  @ViewChild('connectWalletModalButton') connectWalletModalButton:any;
  @ViewChild('changeNetworkButton') changeNetworkButton:any;
  public copyWalletAddress : any;

  /**
   * constructor
   */
  constructor(
    private router: Router,
    private storageService: StorageService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private metaMaskService: MetaMaskService,
    private walletConnectService: WalletConnectService,
    private toastr: ToastrService,
    private authService: AuthService,
    private ngZone: NgZone,
    private accountService: AccountService,
    private clipboardApi: ClipboardService,
    // @ts-ignore
    private elementRef: ElementRef, @Inject(DOCUMENT) private doc,
  ) { }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    const s14 = document.createElement('script');
    s14.type = 'text/javascript';
    s14.src = environment.base_url+'/assets/js/plugins.js';
    this.elementRef.nativeElement.appendChild(s14);

    const s1 = document.createElement('script');
    s1.type = 'text/javascript';
    s1.src = environment.base_url+'/assets/js/designesia.js';
    this.elementRef.nativeElement.appendChild(s1);

    // @ts-ignore
    if (typeof window['ethereum'] === 'undefined') {
      this.isMetamask = false;
      this.metamaskInstallLink = 'https://metamask.io/download/';
    }
    this.account = this.storageService.get('auth');
    if (this.account) {
      this.checkWalletLogin();
      this.validateAccount(this.account.walletAddress);
    }
    this.accountService.openWalletObserve.subscribe((response:any)=>{
      if (response.status) {
        this.connectWalletModalButton.nativeElement.click();
      }
      if (response.status && response.type == 'disconnect') {
        this.disconnect(false);
      }
    });

    // Wallet Connect Lisitener
    this.walletConnectService.connectionListener.subscribe((response:any) => {
      if (response.code == 250700) {
        this.disconnect(false);
      } else {
        if (response.data['account']) {
          this.validateAccount(response.data['account'][0]);
        }
      }
    });
  }


  /**
   * Creates account modal
   * @param {boolean} value
   */
  public CreateAccountModal(value: boolean) {
    this.isCreateAccount = value;
    this.connectWalletModalClose.nativeElement.click();
    this.createAccountModalOpen.nativeElement.click();
  }

  /**
   * Metemasks listener
   */
  public metemaskListener() {
    this.metaMaskSubscription = this.metaMaskService.connectionListener.subscribe(async (response: any) => {
      if (response.code == 250601) {
        this.chainId = await this.metaMaskService.getChainId();
      }

      if (response.code == 250610) {
        window.location.reload();
        this.chainId = response.data.chainId;
      }
      if (response.code == 250611) {
        if (response.data.accounts[0]) {
          if (this.account) this.validateAccount(response.data.accounts[0]);
        } else {
          this.disconnect(false);
        }
      }
      this.toggleNetworkChangeDetected();
    });
  }

  /**
   * check Wallet Login
   */
  public async checkWalletLogin() {
    let accountsLength;
    const that = this;
    // @ts-ignore
    const web3Check = new Web3(window['ethereum']);
    await web3Check.eth.getAccounts(function(err, accounts) {
      accountsLength = accounts.length;
      const checkWallect = that.storageService.get('walletconnect');
      if (accountsLength == 0 && that.account?.address != '' && checkWallect == null ) {
        that.connectMetaMask();
      }
    });
  }


  showMenu = false;
  showMobileMenu = false;
  showToggleConnectDropdown = false;
  isConnectWalletShown = false;

  /**
   * on destroy
   */
  public ngOnDestroy(): void {
    this.metaMaskSubscription?.unsubscribe();
  }
  /**
   * Params wallets component
   * @param {string} value
   */
  public valueChangeName(value:string) {
    const textLength = value ? (value)?.length : 0;
    this.remainingTextName = textLength +'/'+30 + ' Characters left';
  }
  /**
   * @param {string} value
   */
  public valueChangeUserName(value:string) {
    const textLength = value ? (value)?.length : 0;
    this.remainingTextUserName = textLength +'/'+15 + ' Characters left';
  }
  /**
   * Changes network
   */
  public changeNetwork() {
    try {
      this.addNetworkToMetamask();
      // this.metaMaskService.addNetwork(addNetworkParam); TODO need to call addtokenparam functio on metamask library
      this.metaMaskService.changeNetwork(environment.BSC_CHAINID);
    } catch (exception) {
    }
  }

  /**
   * Hosts listener
   */
  @HostListener('window:scroll')
  public scrollEvent() {
    window.scrollY >= 50 ? (this.isScrolled) : (!this.isScrolled);
  }

  /**
   * Toggles network change detected
   */
  public async toggleNetworkChangeDetected() {
    this.chainId = await this.metaMaskService.getChainId();
    if (this?.chainId != environment.BSC_CHAINID) {
      this.networkChangeDetected = true;
      this.changeNetworkButton.nativeElement.click();
      return;
    } else {
      this.networkChangeDetected = false;
    }
  }


  /**
   * Sets user
   * @param {any} data
   */
  public async setUser(data: any) {
    this.user = data.user;
    this.user.balance = await this.commonService.getEktaBalance(this.user.walletAddress);
    const userWalletAddress = this.user.walletAddress;
    this.storageService.set('user', this.user);
    const accountWalletAddress = this.account ? this.account.walletAddress : '';
    this.account = { walletAddress: this.user.walletAddress, token: data['token'] };
    this.storageService.set('auth', this.account);
    if (userWalletAddress != accountWalletAddress ) {
      window.location.reload();
    }
  }

  /**
   * connect meta mask
   */
  public async connectMetaMask() {
    const connection: any = await this.metaMaskService.connectMetaMask().catch((error:any) => {
      if (error.data.code == -32002) {
        this.toastr.error('Connect Wallet to Proceed');
      } else {
        this.toastr.error('Failed to connect meta mask', error.data.message);
      }
    });

    this.validateAccount(connection['data'][0]);
  }

  /**
   * Wallets connect
   */
  public walletConnect() {
    this.walletConnectService.openWalletConnectModal();
  }
  /**
   * Validates account
   * @param {String} address
   */
  public validateAccount(address: string) {
    this.authService.checkRegister(address)
        .subscribe({
          next: async (response:any) => {
            if (
              response.success === 1 &&
              await this.authService.checksumAddress(response['data']['user']['walletAddress']) === await this.authService.checksumAddress(address)
            ) {
              this.ngZone.run(async () => {
                response.data.user.walletAddress = web3.utils.toChecksumAddress(response.data.user.walletAddress);
                this.accountService.setAccount({ walletAddress: response.data.user.walletAddress });
                this.setUser(response.data);
                !this.metaMaskSubscription || this.metaMaskSubscription?.closed ? this.metemaskListener() : null;
              });
              if (this.isCreateAccount) {
                this.CreateAccountModal(false);
              }
            } else {
              this.registerForm.patchValue({
                walletAddress: address,
              });
              this.CreateAccountModal(true);

              this.disconnect(false);
            }
          },
          error: (error) => {
            this.toastr.error(error.message);
          },
        });
  }
  /**
   * Gets form get
   */
  get formGet() {
    return this.registerForm.controls;
  }

  /**
   * Determines whether profile change on
   * @param {any} event
   */
  public onProfileChange(event:any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.registerForm.patchValue({ profileImage: event.target.files[0] });

      // check file is valid
      if (!this.commonService.validateFile(event.target.files[0].name)) {
        this.toastr.error('This format is not supported');
        return;
      }

      reader.onload = () => {
        this.tempProfile = reader.result as string;
      };
    }
  }


  /**
   *
   * Registers form submit
   */
  public async registerFormSubmit() {
    const { name, userName, email, walletAddress, profileImage } = this.formGet;
    this.formSubmitted = true;
    this.registerFormLoader = true;
    if (!email.value) {
      this.registerForm.get('email').clearValidators();
      this.registerForm.get('email').updateValueAndValidity();
    }
    if (this.registerForm.invalid || this.userNameError !== '') {
      this.registerFormLoader = false;
      return;
    }


    let profilePreSignedUrl;
    if (profileImage.value != '') {
      profilePreSignedUrl = await this.commonService.getPreSignedUrl(userName.value, `${(userName.value).toLowerCase()}-${Date.now()}.${profileImage.value.name.split('.').pop()}`, profileImage.value.type);

      // upload image to presigned url
      await this.commonService.uploadToPresignedUrl(profilePreSignedUrl['url'], profileImage.value);
    }

    const profile = {
      name: name.value,
      userName: userName.value,
      email: email.value,
      walletAddress: walletAddress.value,
      profileImage: profileImage.value != '' ? profilePreSignedUrl['url'].split('?').shift() : '',
    };

    this.authService.register(profile)
        .subscribe({
          next: (response: any) => {
            this.storageService.set('auth', { walletAddress: walletAddress.value, token: response['token'] });
            this.toastr.success(response.message);
            this.registerFormLoader = false;
            this.createAccountModalClose.nativeElement.click();
            this.router.navigate(['/profile']);
          },
          error: (error:any) => {
            this.registerFormLoader = false;
            this.toastr.error(error.error.message, 'Failed to create profile');
          },
        });
  }


  /**
   * Disconnects wallets component
   * @param {boolean} showToast
   */
  public disconnect(showToast: boolean = true) {
    if (this.account && this.account?.walletAddress != '') {
      this.account = null;
      this.accountService.setAccount({ walletAddress: '' });
      this.storageService.remove('auth');
      this.storageService.remove('user');
      this.storageService.remove('walletconnect');
      if (this.router.url === '/profile' || this.router.url === '/edit-profile') {
        this.ngZone.run(async () => {
          this.router.navigate(['/home']);
        });
      }
      window.location.reload();
      if (showToast) this.toastr.success('Logged Out Successfully');
      this.metaMaskSubscription?.unsubscribe();
    }
  }

  /**
   * Adds network to metamask
   */
  public addNetworkToMetamask() {
    // @ts-ignore
    const web3Check = window['ethereum'];
    web3Check.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: environment.BSC_CHAINID,
        chainName: environment.CHAIN_NAME,
        nativeCurrency: {
          name: environment.CURRENCY_NAME,
          symbol: environment.CURRENCY_SYMBOL,
          decimals: 18,
        },
        rpcUrls: [environment.provider],
        blockExplorerUrls: [environment.EXPLORER_URL],
      }],
    })
        .then((_success:any) => {
        })
        .catch((error:any) => {
          if (error.code === 4001) {
            this.toastr.error('User rejected');
          } else {
            this.toastr.error('Something went wrong');
          }
        });
  }

  /**
   * Checks user name
   * @param {string} username
   */
  public checkUserName(username: string) {
    this.authService.checkUserName(username).subscribe({
      next: (response: any) => {
        if (response.data.status == 1) {
          this.userNameError = 'Username already exists';
        } else {
          this.userNameError = '';
        }
      },
      error: () => {
        this.userNameError = '';
      },
    });
  }

  /**
   * Copy User Wallet Address
   */
  public copyUserWalletAddress() {
    this.copyWalletAddress = this.user.walletAddress;
    this.clipboardApi.copyFromContent(this.copyWalletAddress);
    this.toastr.success('Copied to Clipboard');
  }
}
