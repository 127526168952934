<div class="title-wrap text-left pb-0">
<h3 class="d-flex justify-content-between">{{nft?.name}} #{{nft?.tokenId}}
    
        <span class="sale-status md:mx-4  btn-style-yellow" *ngIf="!nft?.isSaleRemoved && (nft?.isSold !== 0 || nft?.isPendingConfirmation !==0)">
            <span *ngIf="nft?.saleType === 'Buy'" >Sold Out</span>
            <span *ngIf="nft?.saleType !== 'Buy' && !checkIfBidEnded(nft.bidEndTime)"> Sale Ended
            </span>
            <span *ngIf="nft?.saleType !== 'Buy' && nft?.isPendingConfirmation !==0 && checkIfBidEnded(nft.bidEndTime)">Sold Out
            </span>
        </span>
        <span class="md:mx-4 sale-status btn-style-yellow" *ngIf="!isOwnerWallet && (nft?.isSaleRemoved)">
            <span> Sale Ended</span> 
        </span>
        <span class="md:mx-4 sale-status btn-style-yellow" *ngIf="!isOwnerWallet && (!nft?.isSaleRemoved) && (checkIfBidEnded(nft.bidEndTime) && nft?.saleType == 'TimedBid')">
             Sale Ended 
        </span>
        <span class="md:mx-4 sale-status btn-style-yellow"
            *ngIf="nft.forSale === 1 && (checkIfBidStarted(nft.bidStartTime) && !checkIfBidEnded(nft.bidEndTime) && nft?.saleType == 'TimedBid')">
            Sale Live
        </span>
        <span class="md:mx-4 sale-status btn-style-yellow"
            *ngIf="nft.forSale === 1 && (!checkIfBidStarted(nft.bidStartTime) && checkIfBidEnded(nft.bidEndTime) && nft?.saleType == 'TimedBid')">
            Sale Upcomming
        </span>
        <span class="md:mx-4 sale-status btn-style-yellow" *ngIf="nft.forSale === 1 && !checkIfBidStarted(nft.bidStartTime) && nft?.saleType == 'Bid'">
            Sale Upcomming
        </span>
</h3>
<h4 class="cursor-pointer text-blue" [routerLink]="['/nft-details/', nft?.nftCollection?._id]">{{nft?.nftCollection?.collectionName}}</h4>

<p class="text-sm lg:text-lg line-clamp-2 pb-4">Owned by
    <span class="text-blue fw-semibold cursor-pointer" [routerLink]="['/partner', nft?.nftPartner?._id]" *ngIf="!nft.nftSale">{{nft?.nftPartner?.name}}</span>
    <span class="text-blue fw-semibold cursor-pointer" [routerLink]="['/partner', nft?.nftSeller?._id]" *ngIf="nft.nftSale">{{nft?.nftSeller?.name}}</span>
</p>
</div>
<div class="price-time-div" *ngIf="nft.saleType != 'Buy'">
    <div *ngIf="nft.forSale === 1 && (nft.saleType === 'TimedBid' || nft.saleType === 'Bid') && (!checkIfBidStarted(nft?.bidStartTime) || (nft.saleType === 'TimedBid' && !checkIfBidEnded(nft?.bidEndTime)))"
        class="d-flex timer-div">
        <ng-container *ngIf="!checkIfBidStarted(nft?.bidStartTime) && nft.saleType === 'TimedBid'">
            <h6>
                Bids Starts In
            </h6>
        </ng-container>
        <ng-container *ngIf="!checkIfBidStarted(nft?.bidStartTime) && nft.saleType === 'Bid'">
            <h6>
                Offer Starts In
            </h6>
        </ng-container>
            <h6 *ngIf="!checkIfBidEnded(nft?.bidEndTime) && checkIfBidStarted(nft?.bidStartTime)">
                Offer Ends In
            </h6>
        <h4>{{currentSaleTime?.days}}d {{currentSaleTime?.hours}}h {{currentSaleTime?.minutes}}m
            {{currentSaleTime?.seconds}}s</h4>
    </div>
    <hr class="border-grey border-t border-solid my-4"
        *ngIf="nft.forSale === 1 && nft.saleType !== 'Buy' && !checkIfBidEnded(nft?.bidEndTime)">
    <div class="d-flex">
        <h5 class="text-sm fw-medium pb-1" *ngIf="nft.saleType == 'TimedBid'">
            {{!nft.isSaleRemoved || !isOwnerWallet ? 'Minimum Bid' : 'Price before removed from sale'}}</h5>
        <h5 class="text-sm fw-medium pb-1" *ngIf="nft.saleType == 'Bid'">
            {{!nft.isSaleRemoved || !isOwnerWallet ? 'Minimum Offer' : 'Price before removed from sale'}}
        </h5>

        <div class="items-center gap-2 p-4 pt-0" *ngIf="nft.saleType != 'Buy'">
            <span class="text-sm sm:text-base xl:text-xl fw-medium">{{nft.price}} BNB
                <span class="text-sm fw-medium">(${{(nft.price * ektaPrice).toFixed(3)}})</span></span>
        </div>
    </div>
    <div *ngIf="nft.lastBid > 0" class="d-flex">
        <h5 class="text-xs xl:text-sm fw-medium pb-1">Highest Offer</h5>
        <div class="items-start sm:items-center gap-2 p-4 pt-0">
            <span class="text-sm sm:text-base xl:text-xl fw-medium">{{(nft.lastBid).toFixed(4)}}
                BNB<span class="text-xs fw-medium">(${{(nft.lastBid *
                    ektaPrice).toFixed(3)}})</span></span>
        </div>
    </div>
    <div *ngIf="nft.lastBid > 0 && userBids?.length > 0" class="d-flex">
        <h5 class="text-xs xl:text-sm fw-medium pb-1">Your Last Offer</h5>
        <div class="items-start sm:items-center gap-2 p-4 pt-0">
            <span class="text-sm sm:text-base xl:text-xl fw-medium">
                {{(userBids[0]?.bidAmount).toFixed(4)}} BNB <span
                    class="text-xs fw-medium">(${{(userBids[0]?.bidAmount *
                    ektaPrice).toFixed(3)}})</span></span>
        </div>
    </div>
    <form [formGroup]="amountForm" (ngSubmit)="submitAmount()" #amountFormRef="ngForm"
        *ngIf="(nft.saleType === 'Bid' || (nft.saleType === 'TimedBid' && !checkIfBidEnded(nft?.bidEndTime))) && !nft.isSaleRemoved && (nft?.isSold !== 1 && nft?.isPendingConfirmation !== 1)">
        <div *ngIf="checkIfBidStarted(nft?.bidStartTime) && nft?.saleType !== 'Buy' && !isOwnerWallet">
            <input type="number" placeholder="Amount" formControlName="amount" name="amount"
                class="input-amount pl-4 fw-regular focus:outline-none outline-none text-base amount"
                (mousewheel)="restrictScroll()" min="0"
                onkeydown="return event.keyCode !== 69 && event.keyCode !== 189">
        </div>
        <div *ngIf="amountSubmitted && f?.amount?.errors">
            <div *ngIf="f?.amount?.errors?.required" class="pl-4 text-red pb-2">Amount is required</div>
            <div *ngIf="userBids?.length === 0 && !f?.amount?.errors?.required && (((nft.saleType === 'TimedBid' && nft.lastBid === 0) || (nft.saleType === 'Bid')) && f?.amount?.errors?.gte)"
                class="pl-4 text-red pb-2">Amount should be greater than Price</div>
            <div *ngIf="userBids?.length === 0 && nft.saleType === 'TimedBid' && nft.lastBid > 0 && f?.amount?.errors?.gte"
                class="pl-4 text-red pb-2">Amount should be greater than last highest Offer</div>
            <div *ngIf="userBids?.length > 0 && f?.amount?.errors?.gte" class="pl-4 text-red pb-2">Amount should
                be greater than your last Offer</div>
        </div>
    </form>
</div>
<div class="d-flex flex-row flex-wrap items-center py-2 offer-remove-sale-btn"
    *ngIf="currentNftDetails?.isSold !== 1 && currentNftDetails?.isPendingConfirmation !== 1 && account?.walletAddress != '' && account?.walletAddress != null && account?.walletAddress != undefined">
    <button type="button" class="px-12 btn-style-yellow btn--red"
        [disabled]="isBuyerBlacklisted || isSellerBlacklisted || sellerAddress == account?.walletAddress || (nft.isPendingConfirmation != 0 || nft.isSold != 0)"
        *ngIf="nft.forSale === 1 && nft.saleType === 'Bid' && userBids?.length == 0 && !isOwnerWallet && checkIfBidStarted(nft?.bidStartTime)"
        (click)="onContinue(nft.saleType)" type="button">
        Offer Now
    </button>
    <span data-bs-toggle="modal" data-bs-target="#openOfferNowModal" #openOfferModal type="button"
        data-modal-toggle="defaultModal" class="open-offernow-modal"></span>
    <button type="button" class="px-12  btn-style-yellow btn--red"
        [disabled]="isBuyerBlacklisted || isSellerBlacklisted || sellerAddress == account?.walletAddress || (nft.isPendingConfirmation != 0 || nft.isSold != 0 || !checkIfBidStarted(nft.bidStartTime))"
        *ngIf="nft.forSale === 1 && nft.saleType == 'TimedBid' && !checkIfBidEnded(nft?.bidEndTime) && checkIfBidStarted(nft?.bidStartTime) && userBids?.length == 0 && !isOwnerWallet"
        (click)="onContinue(nft.saleType)" type="button">
        Bid Now
    </button>
    <button type="button" class="px-12 btn-style-yellow btn--red"
        *ngIf="!isOwnerWallet && userBids?.length > 0" (click)="onContinue(nft.saleType)"
        [disabled]="isBuyerBlacklisted || isSellerBlacklisted"
        type="button"
    >
        Edit Offer
    </button>
    <button type="button" class="px-12  btn-style-yellow btn--red"
        *ngIf="!isOwnerWallet && userBids?.length > 0" data-bs-toggle="modal" data-bs-target="#openCancelModal"
        [disabled]="isBuyerBlacklisted || isSellerBlacklisted"
        type="button" data-modal-toggle="defaultModal"
    >
        Cancel Offer
    </button>
    <button type="button" class="px-12  btn-style-yellow btn--red"
        *ngIf="isOwnerWallet && nft.forSale === 1 && !nft.isSaleRemoved" data-bs-toggle="modal"
        data-bs-target="#openRemoveModal" type="button" data-modal-toggle="defaultModal"
        [disabled]="isBuyerBlacklisted || isSellerBlacklisted"
    >
        Remove Sale
    </button>
    <button type="button" class="px-12  btn-style-yellow btn--red"
        *ngIf="isOwnerWallet && nft.forSale === 0" data-bs-toggle="modal" 
        data-bs-target="#putForSaleModal" (click)="showPutForSale()" data-modal-toggle="defaultModal">
        Put for Sale
    </button>
    <p *ngIf="isBuyerBlacklisted" style="color: red;" class="mt-3">You are Blacklisted.</p>
    <p *ngIf="isSellerBlacklisted" style="color: red;" class="mt-3">Seller is Blacklisted.</p>
</div>
<div class="buy-now-div" *ngIf="nft.forSale === 1 && nft.saleType == 'Buy' && !tradeLoader && !isOwnerWallet">
    <h6>Price</h6>
    <div class="nft-item-price"><img src="assets/images/icons/bnb.svg"
            alt="bnb-icon"><span>{{nft.price}}</span>(${{(nft.price *
        ektaPrice).toFixed(3)}})</div>

    <!-- Button trigger modal -->
    <span data-bs-toggle="modal" data-bs-target="#openBuyNowModal" #openBuyNowModalButton></span>
    <a class="cursor-pointer btn-main btn-lg" *ngIf="account?.walletAddress != '' && account?.walletAddress != null && account?.walletAddress != undefined"  (click)="onContinue(nft.saleType)">
        Buy Now
    </a>
</div>
<button *ngIf="account?.walletAddress == '' || account?.walletAddress == null || account?.walletAddress == undefined" type="button" class="btn-style-yellow mb-4 mt-4" (click)="connectWallet()"  type="button">Connect Wallet</button>
<!-- Put for sale popup modal -->

            <app-put-for-sale [isPutForSaleShown]="isPutForSaleShown" [user]="user" [nft]="nft">
            </app-put-for-sale>


<!-- BEGIN: Remove sale Modal -->
<div class="modal fade" id="openRemoveModal" tabindex="-1" aria-labelledby="openRemoveModal" aria-hidden="true">
    <div class="openRemoveModalDiv">
    <div class="modal-dialog modal-sm">
        <div class="modal-content remove-modal text-center">
            <div class="modal-title">
                <h4 class="">Are you sure want to remove sale?</h4>
            </div>
            <div class="modal-body">
                <div class="remove-modal-section d-flex justify-content-between gap-4">
                    <button class="text-center minw-40 btn-style-yellow btn-no" data-bs-dismiss="modal" aria-label="Close"
                        #closeRemoveModal>No</button>
                    <button [disabled]="isRemoveSale" class="minw-40 btn-yes btn-style-yellow text-center"
                        (click)="removeSale(nft.saleType)">
                        <span *ngIf="!isRemoveSale">Yes</span>
                        <span *ngIf="isRemoveSale">Processing...</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- END: Remove sale Modal -->

<!-- BEGIN: Cancel Offer Modal -->
<div class="modal fade" id="openCancelModal" tabindex="-1" aria-labelledby="openCancelModal" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content remove-modal text-center">
            <div class="modal-title">
                <h4 class="">Are you sure want to Cancel Offer?</h4>
            </div>
            <div class="modal-body">
                <div class="row remove-modal-section d-flex justify-content-between">
                    <button class="text-center minw-40 btn-style-yellow btn-no" data-bs-dismiss="modal" aria-label="Close"
                        #closeCancelModal>No</button>
                    <button [disabled]="isRemoveSale" class="text-center minw-40 btn-style-yellow" (click)="closeBid()"> Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END: Cancel Offer Modal -->

<!-- BEGIN: Buy Processing Modal -->
<div class="modal fade" id="openBuyNowModal" aria-labelledby="openBuyNowModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="openBuyNowModal-dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row purchasing-section">
                    <div class="tems-center col-span-12 text-center">
                        <a class="btn-close" *ngIf="!showClose" data-bs-dismiss="modal" aria-label="Close" #closeBuyNowModal></a>
                        <h2 class="fw-semibold text-xl text-center text-black pb-2" *ngIf="tradeLoader">Your purchase is
                            Processing!</h2>
                        <h2 class="fw-semibold text-xl text-center text-black pb-2" *ngIf="!tradeLoader">Your purchase
                            is
                            Completed!</h2>
                        <p class="text-black fw-regular text-lg text-center offer-popup">
                            <span class="pr-1" *ngIf="tradeLoader">You are going to Purchase </span>
                            <span class="pr-1" *ngIf="!tradeLoader">You have Purchased </span>
                            <span class="fw-semibold pr-1">{{nft?.name}} #{{nft?.tokenId}} for</span>
                        </p>
                        <div class="text-center items-center fw-semibold amount-value">{{nft?.price}}
                            <p class="d-inline pl-0.5">BNB</p>
                        </div>
                        <img [src]="[nft.nftImageS3 ? nft?.nftImageS3 : nft?.nftCollection?.baseUrl + nft.nftImage]"
                            class="my-4 w-48 h-48 mx-auto purchase-img" alt="BX-nft">
                    </div>
                    <div class="row">
                        <div class="col-6 popup-status mb-2">
                            <h6 class="text-xs fw-regular text-black pb-1">Status</h6>
                            <div *ngIf="tradeLoader" type="button"
                                class="text-blue inline-flex items-center leading-6 text-sm fw-semibold text-[#fff] transition ease-in-out duration-150">
                                <div class="d-flex loader-section-trade">
                                    <div class="spinner-border text-blue"></div>
                                    <span>
                                        Processing
                                    </span>
                                </div>
                            </div>
                            <button *ngIf="!tradeLoader" type="button"
                                class="complete-btn inline-flex items-center leading-6 text-sm fw-semibold text-[#1fc51f] transition ease-in-out duration-150 btn-style-yellow">
                                <img src="assets/icons/circle-check.svg" class="mr-3 h-5 w-5 text-[#1fc51f]"
                                    alt="BX-nft">
                                Completed
                            </button>
                        </div>
                        <div class="col-6 popup-transh mb-2">
                            <div class=float-right>
                                <h6 class="text-xs fw-regular text-black pb-1">Transaction Hash</h6>
                                <a href="{{ektaScan + '/tx/' + txHash}}" target="_blank" *ngIf="txHash !== ''"
                                    class="inline-flex items-center leading-6 text-sm fw-semibold text-[#2e7dd5] underline transition ease-in-out duration-150">
                                    {{txHash.slice(0, 5)}}...{{txHash.slice(txHash.length - 5)}}
                                </a>
                                <div *ngIf="txHash == ''">-</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-center" *ngIf="!tradeLoader">
                            <button class="btn-style-yellow" (click)="goToProfile()">View NFT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- END: Buy Processing Modal -->

<!-- BEGIN: Offer Processing Modal -->
<div class="modal fade" id="openOfferNowModal" aria-labelledby="openOfferNowModal" aria-labelledby="openBuyNowModal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="openBuyNowModal-dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="purchasing-section">
                <div class="tems-center text-center">
                    <span class="btn-close" *ngIf="!showClose" data-bs-dismiss="modal" aria-label="Close" #closeOfferModal></span>
                    <h2 class="fw-semibold text-xl text-center text-black pb-2" *ngIf="tradeLoader">Your Offer is
                        Processing!</h2>
                    <h2 class="fw-semibold text-xl text-center text-black pb-2" *ngIf="!tradeLoader">Your Offer is
                        Completed!</h2>
                    <p class="fw-regular text-lg text-center offer-popup mb-0">
                        <span *ngIf="tradeLoader">You are going to Offer </span> <span *ngIf="!tradeLoader">You have
                            placed a Offer on </span>
                        <span class="fw-semibold pl-1 ">{{nft?.name}} #{{nft?.tokenId}} for</span>
                    </p>
                    <div class="text-center fw-semibold pl-1 pr-1 items-center amount-value">{{f.amount.value}}
                        <p class="d-inline mb-0 pl-0.5">BNB</p>
                    </div>
                    <img [src]="[nft.nftImageS3 ? nft?.nftImageS3 : nft?.nftCollection?.baseUrl + nft.nftImage]"
                        class="my-4 w-48 h-48 rounded-xl mx-auto purchase-img" alt="Purchase Image">
                    <div class="row offer-purchase-details">
                        <div class="d-flex justify-content-between">
                            <p class="mb-0 text-bold">Convert BNB to WBNB</p>
                            <app-contract-call-progress [progress]="progressStatus?.C"></app-contract-call-progress>
                        </div>

                        <a href="https://docs.google.com/document/d/1Cvy-n5wmxtmtcdNAkrsYBEGJRTo6qNl8-QKqoxx8g-g/edit"
                            target="_blank" class="font-12 why-wekta cursor-pointer">Why WBNB</a>
                        <div class="font-12 d-flex items-center text-xs">Your Offer: &nbsp;
                            <p class="font-12 mb-0 pr-1">{{this.amountForm.value?.amount}}</p> &nbsp;
                            <p class="font-12 pl-0.5 mb-0">WBNB</p>
                        </div>
                        <div class="font-12 d-flex items-center text-xs">Your balance: &nbsp;
                            <p class="pr-1 mb-0 font-12">{{balance}}</p> &nbsp;
                            <p class="pl-0.5 mb-0 font-12">WBNB</p>
                        </div>
                        <div *ngIf="this.amountForm.value?.amount > balance"
                            class="font-12 text-xs d-flex items-center">
                            {{(this.amountForm.value?.amount - balance).toFixed(3)}} &nbsp;
                            <p class="pl-0.5 mb-0 font-12">BNB will be converted to </p> &nbsp;
                            <p class="pl-0.5 mb-0 font-12">WBNB</p>
                        </div>
                        <div class="col-10 text-start font-bold my-2">
                            Approve Exchange contract
                        </div>
                        <div class="col-2 d-flex justify-content-end my-2">
                            <app-contract-call-progress [progress]="progressStatus?.A"></app-contract-call-progress>
                        </div>
                        <div class="col-10 text-start font-bold my-2">
                            Signing the order
                        </div>
                        <div class="col-2 d-flex justify-content-end my-2">
                            <app-contract-call-progress [progress]="progressStatus?.S"></app-contract-call-progress>
                        </div>
                        <div class="col-10 text-start font-bold my-2">
                            Complete Offer
                        </div>
                        <div class="col-2 d-flex justify-content-end my-2">
                            <app-contract-call-progress [progress]="progressStatus?.B"></app-contract-call-progress>
                        </div>
                        <div class="col-12 d-flex justify-content-center"
                            *ngIf="progressStatus?.B === 'C' || progressStatus?.B === 'E'">
                            <button class="btn-done" (click)="bidComplete()">{{progressStatus?.B === 'E' ?
                                'Try Again' : 'Done'}}</button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
</div>
<!-- END: Offer Processing Modal -->