<div class="row">
    <div class="col-lg-12">

        <div class="items_filter d-flex align-items-center">
            <form class="row form-dark" id="form_quick_search" method="post" name="form_quick_search">
                <div class="col text-center">
                    <input class="form-control" (input)="searchByNameCallback($event)" [(ngModel)]="searchByName"
                        placeholder="search item here..." type="text" name="searchByName"/> 
                    <span id="btn-submit"><i class="fa fa-search bg-color-secondary"></i></span>
                    <div class="clearfix"></div>
                </div>
            </form>

            <app-multi-select-dropdown [list]="list" (shareCheckedList)="shareCheckedList($event)" #multiselect>
            </app-multi-select-dropdown>
            <div>
                <div (mouseleave)="showDropDown = false">
                    <button class="drop-toggle btn flat checkbox-dropdown" (click)="showDropDown=!showDropDown">
                        <span>{{priceSortText}}</span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <div class="drop-show" *ngIf="showDropDown">
                        <label (click)="onPriceSort(1)">
                            <span >Price Low to High</span>
                        </label>
                        <label (click)="onPriceSort(-1)">
                            <span >Price High to Low</span>
                        </label>
                    </div>
                </div>
            </div>
             
            <div id="price-range-btn" #priceRangeModal class="dropdown d-sm-flex d-block" data-bs-toggle="modal"
                data-bs-target="#priceRangeModal">
                Price Range
            </div>
            <div id="price-range-btn" #attributesModal class="dropdown d-sm-flex d-block" data-bs-toggle="modal"
                data-bs-target="#attributesModal">
                Attributes
            </div>
            <div *ngIf="isAttrFilter || isApplyFilter || isPriceApplyFilter || isSaleTypeApplyFilter || searchByName !== '' || isPriceSortFilter" (click)="clearAllFilters()" class="ms-auto cursor-pointer">
                Clear All Filters
            </div>
            <!-- Price Range Modal -->
            <div class="modal fade modal-popup-new" id="priceRangeModal" tabindex="-1" aria-labelledby="priceRangeModal"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <form class="row price-range-text-section" [formGroup]="priceRangeForm" (ngSubmit)="priceRangeFilter()">
                                <div class="col-12">
                                    <div class="price-range-text">
                                        BNB
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label>Min Price</label>
                                    <input type="number" class="price-range-text-box" name="minPrice" formControlName="minPrice" placeHolder="Min Price">
                                    <div *ngIf="priceRangeFormSubmitted && priceRangeForm.controls.minPrice?.errors">
                                        <p class="red" *ngIf="priceRangeForm.controls.minPrice.errors?.required">Min price is required</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label>Max Price</label>
                                    <input type="number" class="price-range-text-box" name="maxPrice" formControlName="maxPrice" placeHolder="Max Price">
                                    <div *ngIf="priceRangeFormSubmitted && priceRangeForm.controls.maxPrice?.errors">
                                        <p class="red" *ngIf="priceRangeForm.controls.maxPrice.errors?.required">Max price is required</p>
                                        <p class="red" *ngIf="priceRangeForm.controls.maxPrice.errors?.min">Max price must be greater than min price</p>
                                    </div>
                                    <p class="red" *ngIf="isMaxPriceError">Max price must be greater than min price</p>
                                </div>
                                <div class="col-6">
                                    <button class="btn-style-one clear-btn" type="button" (click)="priceRangeFilter(true)">Clear All</button>
                                </div>
                                <div class="col-6">
                                    <button class="btn-style-one" type="submit">Apply</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Attribute Modal -->
            <div class="modal fade modal-popup-new" id="attributesModal" tabindex="-1" aria-labelledby="attributesModal"
                aria-hidden="true">
                <div class="modal-dialog attribut-modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex attribute-head-popup">
                                <img src="assets/images/icons/attribute.svg" class="attribute-icon" alt="attribute">
                                <h3 class="text-lg fw-semibold text-black mb-0">Attribute</h3>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="search">
                                <img src="assets/images/search.png" class="w-3 h-3 md:w-4 md:h-4" alt="search">
                                <input type="text" placeholder="Search" name="search"
                                    class="pl-4 w-full fw-regular focus:outline-none outline-none text-sm"
                                    (input)="searchAttributeCallback($event)" [(ngModel)]="searchAttribute">
                            </div>
                            <div class="accordion" id="accordionExample">
                                <span *ngIf="(objectKeys(attributesFilter) | textFilter:searchAttribute).length == 0">Attributes Not Found</span>
                                <div class="accordion-item"
                                    *ngFor="let element of objectKeys(attributesFilter) | textFilter:searchAttribute; let i = index;">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button  [ngClass]="{'': !attributesSubToggle[element], 'attr-active': attributesSubToggle[element]}" class="accordion-button collapsed" type="button" (click)="toggleAttributesSubItem(i)">
                                            {{element}}
                                        </button>
                                    </h2>
                                    <div  [ngClass]="{'d-none': currentAttribute != i, 'd-block attr-active': currentAttribute == i}" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="search">
                                                <img src="assets/images/search.png" class="w-3 h-3 md:w-4 md:h-4"
                                                    alt="search">
                                                <input type="text" placeholder="Search" name="search"
                                                    class="pl-4 w-full fw-regular focus:outline-none outline-none text-sm"
                                                    (input)="searchSubAttributeCallback($event)"
                                                    [(ngModel)]="searchSubAttribute">
                                            </div>
                                            <span class="color-textgray" *ngIf="(attributesFilter[element] | textFilter:searchSubAttribute).length == 0">Attributes Not Found</span>
                                            <div class="d-flex justify-between"
                                                *ngFor="let attribute of attributesFilter[element] | textFilter:searchSubAttribute; let i = index;">
                                                <label class="accordion-body-label  cursor-pointer"
                                                    for="checkbox-value{{i}}">{{attribute}}</label>
                                                <input id="checkbox-value{{i}}" type="checkbox" [value]="attribute"
                                                    class="attr-checkbox"
                                                    (click)="selectSubAttributes(element, attribute, $event)"
                                                    #subattributes>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="row col-12 items-center">
                                <div class="col-6">
                                    <button type="button" class="btn-style-one clear-btn" (click)="clearAllAttributeFilters()">
                                        Clear All
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn-style-one" (click)="filterAttributes()">
                                        Apply
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" ms-auto">{{nfts?.length}}
                <span *ngIf="nfts?.length > 1;else item">items</span>
                <ng-template #item>item</ng-template>
            </div>
        </div>
    </div>
</div>