import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollectionService } from 'src/app/services/collection.service';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['./collection-list.component.css'],
})

/**
 * Export Class
 */
export class CollectionListComponent implements OnInit {
  @Input() collections:any = [];
  @Input() isloadMore: any;
  @Output() onCategorySelectValue = new EventEmitter<any>();
  @Output() onPartnerSelectValue = new EventEmitter<any>();
  @Output() loadMoreValue = new EventEmitter<any>();
  @Output() onCategorySearchValue = new EventEmitter<any>();
  @Input() initialLoader:boolean = false;
  public categoryFilterList:Array<any> = [];
  public partnerList:Array<any> = [];
  public loadMoreLoader:boolean = false;
  public searchLength:number | undefined;
  public collectionKeywordSearch = '';

  /**
   * constructor
   */
  constructor(
    private collectionService: CollectionService,
  ) { }

  /**
   * Initial Loader
   */
  public ngOnInit(): void {
    this.getAllCategories();
    this.getAllPartnersForFiltering();
  }

  /**
   * after view init
   */
  public ngAfterViewInit() {
    this.initialLoader = false;
  }

  /**
   * on changes
   */
  public ngOnChanges() {
    this.loadMoreLoader = false;
  }

  /**
   * Get all categories
   */
  private getAllCategories() {
    this.collectionService.getAllCategories()
        .subscribe({
          next: (response:any)=>{
            this.categoryFilterList = response?.data?.category;
          },
        });
  }

  /**
   * Get all partners for filtering
   */
  private getAllPartnersForFiltering() {
    this.collectionService.getPartners()
        .subscribe({
          next: (response:any)=>{
            this.partnerList = response?.data;
          },
        });
  }


  /**
   * Determines whether partner select event on
   * @param {any} event
   */
  public onPartnerSelectEvent(event : any) {
    this.onPartnerSelectValue.emit(event);
  }
  /**
   * Determines whether partner select event on
   * @param {any} event
   */
  public onCategorySelectEvent(event : any) {
    this.onCategorySelectValue.emit(event);
  }

  /**
   * Determines whether scroll down on
   */
  public loadMore() {
    this.loadMoreLoader = true;
    this.loadMoreValue.emit();
  }


  /**
   * Search callback
   */
  public searchCallback() {
    this.onCategorySearchValue.emit(this.collectionKeywordSearch);
  }
}
