import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs';
import { CommonService } from './common.service';

const apiUrl = environment.api_url;

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
  ) { }

  /**
   * Get banners
   * @return {HttpResponse}
   */
  public getCollectionBanners() {
    return this.http.get(`${apiUrl}/api/v1/get-all-banner`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  public getSaleBanners() {
    return this.http.get(`${apiUrl}/api/v1/get-all-private-sale-banner`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  getPartnerBanners() {
    return this.http.get(`${environment.api_url}/api/v1/top-partners-cover-photo?limit=3`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  getBuzzPageBanners() {
    return this.http.get(`${apiUrl}/api/v1/get-all-buzz-page-banner`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }
}
