   
    <!-- section begin -->
    <section class="top-collections collections-page">
        <div class="container">
            <div class="bg-gradient-1 bg-right-top"></div>
            <div class="bg-gradient-2 bg-right-top"></div>
            <div class="bg-gradient-1 bg-left-bottom"></div>

           
            <div class="search-section d-flex justify-content-between align-items-center">
                <div class="text-left title-wrap">
                    <h2 class="pb-0">Collections</h2>
                </div>
                <div class="search">
                    <img src="assets/images/search.png" class="w-3 h-3 md:w-4 md:h-4" alt="search">
                    <input type="text" id="footer-field" placeholder="Search by collection name" name="footer-input"
                        class="pl-4 w-full fw-regular focus:outline-none outline-none text-sm partner-search"
                        [(ngModel)]="collectionKeywordSearch" (input)="searchCallback()">
                </div>
            </div>

            <div class="row wow fadeIn">
                <div class="col-md-3">
                    <app-collection-filter (onPartnerSelect)="onPartnerSelectEvent($event)"
                        (onCategorySelect)="onCategorySelectEvent($event)"></app-collection-filter>
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <!-- nft item begin -->
                        <div class="d-item col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngFor="let collection of collections">
                            <!-- <div class="nft__item style-2" [routerLink]="['/nft-details/', collection._id]">
                                <div class="author_list_pp profile-img">
                                    <a>
                                        <img class="lazy profile-img-icon"
                                            [src]=" collection.collectionImage ? collection.collectionImage : 'assets/images/author/author-1.jpg'"
                                            alt="Profile-Image">
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="floor-section">
                                    <span class="fw-regular text-[.745rem]">Floor Price</span>
                                    <img src="assets/images/icons/bnb.svg"
                                        class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img"
                                        alt="bx-nft">
                                    <span
                                        class="text-[.745rem] fw-medium w-14 truncate lg:text-clip floor-price-text"
                                        tooltip="{{collection?.floorPrice}} BNB"
                                        placement="top">{{collection?.floorPrice | number:'1.3-3'}} BNB</span>
                                </div>
                                <div class="nft__item_wrap">
                                    <a [routerLink]="['/nft-details']" [routerLink]="['/nft-details']">
                                        <img [src]=" collection.coverPhoto ? collection.coverPhoto : 'assets/images/items/static-1.jpg'"
                                            class="lazy nft__item_preview coverPhoto" alt="">
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a [routerLink]="['/nft-details']">
                                        <h4>{{collection?.collectionName}}</h4>
                                    </a>
                                    <span
                                        class="d-inline fw-medium text-sm xl:text-base">{{collection?.partner?.category?.categoryName}}
                                        | by <a class="text-green fw-medium"
                                            [routerLink]="['/partner', collection?.partner?._id]">{{collection?.partner?.name}}</a></span>
                                    <small
                                        class="text-sm xl:text-base fw-regular line-clamp-2 px-6 description">{{collection?.description}}</small>
                                    <div class="flex space-x-4 items-center px-6 feature-section"
                                        *ngIf="collection?.features?.length > 0">
                                        <div *ngFor="let feature of collection.features" class="feature-div">
                                            <img src='{{feature.featureImage ? feature.featureImage : "assets/images/discover-nft/explore-collection/community.svg"}}'
                                                class="w-4 h-4 feature-image" alt="BX-nft"> <span
                                                class="">{{feature.feature}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex view-btn cursor-pointer" [routerLink]="['/nft-details/', collection._id]">
                                        <div class="nft__item_action btn-main">
                                            <a class="text-them">View Collection</a>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                           
                            <div class="nft__item" [routerLink]="['/nft-details/', collection._id]">
                                <div class="nft__item_wrap">
                                    <a [routerLink]="['/nft-details']" [routerLink]="['/nft-details']">
                                        <img [src]=" collection.coverPhoto ? collection.coverPhoto : 'assets/images/items/static-1.jpg'"
                                            class="lazy nft__item_preview coverPhoto" alt="">
                                    </a>
                                </div>
                                <div class="nft__item_info">
                                    <a [routerLink]="['/nft-details']">
                                        <h4>{{collection?.collectionName}}</h4>
                                    </a>
                                    <p
                                        class="line-clamp-2 description">{{collection?.description}}</p>
                                    <div class="d-flex items-center feature-section"
                                        *ngIf="collection?.features?.length > 0">
                                        <div *ngFor="let feature of collection.features" class="feature-div">
                                            <!-- <img src='{{feature.featureImage ? feature.featureImage : "assets/images/discover-nft/explore-collection/community.svg"}}'
                                                class="w-4 h-4 feature-image" alt="BX-nft">  -->
                                                <span>{{feature.feature}}</span>
                                        </div>
                                    </div>
                                    <hr />
                                        <div class="floor-section d-flex justify-content-between">
                                            <span class="">Floor Price</span>
                                            <div class="d-flex gap-2">
                                            <img src="assets/images/icons/bnb.svg"
                                                class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                            
                                                <span
                                                class="truncate lg:text-clip floor-price-text"
                                                tooltip="{{collection?.floorPrice}} BNB"
                                                placement="top">{{collection?.floorPrice | number:'1.3-3'}} BNB</span>                                                
                                               
                                            </div>
                                        </div>
                                        <div class="nft__item_action">
                                            <a [routerLink]="['/nft-details/', collection._id]" class="btn-style-one">View
                                                Collection</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 text-center" *ngIf="isloadMore && !loadMoreLoader && collections?.length != 0">
                            <a (click)="loadMore()" class="btn-main wow fadeInUp lead cursor-pointer">Load more</a>
                        </div>
                        <div class="col-md-12 text-center">
                            <div *ngIf="collections?.length == 0" class="no-image">
                                <img src="assets/images/no-image.svg" class="" alt="bx-nft">
                                <p class="flex justify-center">No Collections Found</p>
                            </div>
                        </div>
                        <div class="row" *ngIf="loadMoreLoader">
                            <div class="col-6">
                                <app-shimmer></app-shimmer>
                            </div>
                            <div class="col-6">
                                <app-shimmer></app-shimmer>
                            </div>
                            <div class="col-6">
                                <app-shimmer></app-shimmer>
                            </div>
                            <div class="col-6">
                                <app-shimmer></app-shimmer>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </section>