import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.css'],
})

/**
 * Component
 */
export class ShimmerComponent implements OnInit {
  /**
   * Creates an instance of shimmer component.
   */
  constructor() { }

  /**
   * on init
   */
  ngOnInit(): void {
  }
}
