<!-- header begin -->
<header>
    <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="de-flex">
                            <div id="logo">
                                <a [routerLink]="['/home']">
                                    <img alt="Logo" class="logo-img" src="assets/images/logo-dark.svg" />
                                </a>
                            </div>
                
        
            <div class="collapse navbar-collapse" id="navbarSupportedContent">                            
                        <!-- mainmenu begin -->
                        <ul id="mainmenu" class="navbar-nav mx-auto">
                            <li>
                                <a routerLinkActive="active" [routerLink]="['/home']">Home</a>
                            </li>
                            <li>
                                <a routerLinkActive="active"
                                    [routerLink]="['/collections']">Collections</a>
                            </li>
                            <li>
                                <a routerLinkActive="active" [routerLink]="['/nft-launchpad']">NFT
                                    Launchpad</a>
                            </li>
                            <li>
                                <a routerLinkActive="active" [routerLink]="['/trending']">Trending</a>
                            </li>
                            <li>
                                <a routerLinkActive="active" [routerLink]="['/blogs']">Blogs</a>
                            </li>

                        </ul>
                </div>
                <div class="d-flex gap-4">
                        <div class="menu_side_area">
                            <a *ngIf="!account" class="btn-style-one" data-bs-toggle="modal"
                                data-bs-target="#connectWalletModal" type="button" data-modal-toggle="defaultModal" #connectWalletModalButton>
                                <i class="connect-wallet-icon"></i>
                                <span>Connect Wallet</span>
                            </a>
                            <span *ngIf="account" id="de-click-menu-profile"
                                class="btn-style-one cursor-pointer">
                                <span id="de-click-menu-profile" class="de-menu-profile">
                                    <img [src]="user.profileImage ? user.profileImage : 'assets/images/author_single/author_thumbnail.jpg'"
                                        class="img-fluid" alt="">
                                </span>
                                {{account?.walletAddress.slice(0,
                                5)}}...{{account?.walletAddress.slice(account.walletAddress.length - 5)}}
                            </span>

                            <div id="de-submenu-profile" class="de-submenu">
                                <div class="d-name">
                                    <h4>{{user.name}}</h4>
                                </div>
                                <div class="spacer-10"></div>
                                <div class="d-balance">
                                    <h4>Balance</h4>
                                    {{user?.balance?.toFixed(3) || 0}} BNB
                                </div>
                                <div class="spacer-10"></div>
                                <div class="d-wallet">
                                    <h4>My Wallet</h4>
                                    <span id="wallet" class="d-wallet-address">{{ user.walletAddress }}</span>
                                    <button (click)="copyUserWalletAddress()" id="btn_copy" title="Copy Text">Copy</button>
                                </div>

                                <div class="d-line"></div>

                                <ul class="de-submenu-profile">
                                    <li><a [routerLink]="['/profile']"><i class="fa fa-user"></i> My profile</a>
                                    <li><a [routerLink]="['/edit-profile']"><i class="fa fa-pencil"></i> Edit
                                            profile</a>
                                    <li><a (click)="disconnect()" class="cursor-pointer"><i class="fa fa-sign-out"></i>
                                            Sign out</a>
                                </ul>
                            </div>
                        </div>

                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                      </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
</header>

<!-- header close -->

<!-- BEGIN: Connect Wallet popup -->
<div class="modal fade" id="connectWalletModal" tabindex="-1" aria-labelledby="connectWalletModal" aria-hidden="true">
    <div class="modal-dialog modal-lg  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header wallect-popup-header">
                <!-- <div class="col-4">
                </div> -->
                <!-- <div class="col-4 text-center">
                    <h3 class="mb-0 pb-0">Wallet</h3>
                </div> -->
                <!-- <div class="col-4">
                    <img src="assets/icons/cross-icon.png" alt="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #connectWalletModalClose>
                </div> -->
                <h4 class="modal-title">Connect Wallet</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #connectWalletModalClose></button>


            </div>
            <div class="modal-body">
                <app-wallets [isCreateAccount]="isCreateAccount" (connectMetaMaskUpdate)="connectMetaMask()"></app-wallets>
            </div>
        </div>
    </div>
</div>

<!-- END: Connect Wallet popup -->
<span data-bs-toggle="modal" data-bs-target="#createAccountModal"  data-modal-toggle="defaultModal" #createAccountModalOpen></span>
<!-- BEGIN: Create Account popup -->
<div class="modal fade" id="createAccountModal" tabindex="-1" aria-labelledby="createAccountModal" aria-hidden="true">
    <div class="modal-dialog mx-width-42">
     <div class="modal-content border-radioue-1">
        <div class="modal-header">
                    <div class="create-title">Create Your Account
                        <p class="fw-bold">Please Enter your info to Create Account</p>
                    </div>
                    
        </div>
        <div class="modal-body create-account-modal">
            <form [formGroup]="registerForm" autocomplete="off" (ngSubmit)="registerFormSubmit()">
            <div class="row">
                <div class="col-4">
                    <div class="image-upload cursor-pointer mt-4">
                        <label for="file-input">
                            <img [src]="tempProfile != '' ? tempProfile : 'assets/images/profile-avatar.png'" class="border-radioue-1 w-48 h-48" alt="BX-nft" />
                        </label>
                        <input id="file-input" type="file" accept="image/*" (change)="onProfileChange($event)" formControlName="profileImagePreview"/>
                    </div>
                </div>
                <div class="col-8">
                    <div class="form-group mb-4">
                        <label class="form-label" for="name" class="d-block mb-1 text-sm fw-regular">Display Name <span
                                class="text-danger"> * </span></label>
                        <input autocomplete="no" type="text" maxlength="30" [(ngModel)]="textValueName"
                            (ngModelChange)="valueChangeName(textValueName)" id="name" formControlName="name" class="form-control"
                            class="create-account-textbox"
                            required name="name">
                        <small class="text-danger" *ngIf="remainingTextName != ''">{{remainingTextName}}</small>
                        <div *ngIf="formSubmitted && formGet['name']['errors']" class="text-danger">
                            <small class="text-danger" *ngIf="formGet['name']['errors']['required']">Please enter display name</small>
                        </div>
                    </div>
                    <div class="form-group mb-4">
                        <label class="form-label" for="username" class="d-block mb-1 text-sm fw-regular">Username <span class="text-danger"> * </span></label>
                    <input autocomplete="no" type="text" maxlength="15" [(ngModel)]="textValueUserName" (ngModelChange)="valueChangeUserName(textValueUserName)" id="username" formControlName="userName"
                            class="create-account-textbox"  required (keyup)="checkUserName(formGet['userName'].value)"
                        >
                        <small class="text-danger d-block" *ngIf="remainingTextUserName != ''" >{{remainingTextUserName}}</small>
                        <div *ngIf="formSubmitted && formGet['userName']['errors']" class="text-danger">
                            <small class="text-danger d-block" *ngIf="formGet['userName']['errors']['required']">Please enter user name</small>
                        </div>
                        <small class="text-danger d-block" *ngIf="userNameError != ''" >{{userNameError}}</small>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label class="form-label" for="walletAddress" class="d-block mb-1 text-sm fw-regular">Wallet Address <span class="text-danger"> * </span></label>
                    <input type="text" id="walletAddress" formControlName="walletAddress" class="form-control"
                        class="create-account-textbox" 
                        required [readonly]="true"
                    >
                    <div *ngIf="formSubmitted && formGet['walletAddress']['errors']" class="text-danger">
                        <small class="text-danger" *ngIf="formGet['walletAddress']['errors']['required']">Please enter wallet address</small>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label class="form-label" for="email" class="d-block mb-1 text-sm fw-regular">Email</label>
                    <input autocomplete="no" type="email" id="c" formControlName="email" class="form-control"
                        class="create-account-textbox" 
                        required
                    >
                    <div *ngIf="formSubmitted && formGet['email']['errors']" class="text-danger">
                        <small class="text-danger" *ngIf="formGet['email']['errors']['email'] || formGet['email']['errors']['pattern']">Enter a valid email</small>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <button type="button" class="btn-back" data-bs-dismiss="modal" aria-label="Close" #createAccountModalClose>Cancel</button>
                <button type="submit" [disabled]="registerFormLoader" class="btn-submit">
                    <span *ngIf="registerFormLoader">Processing</span> 
                    <span *ngIf="!registerFormLoader">Create your Account</span> 
                    
                </button>
            </div>
        </form>
        </div>
        </div>
    </div>
</div>
<!-- END: Create Account popup -->
<span data-bs-toggle="modal"
data-bs-target="#changeNetworkModal" data-modal-toggle="defaultModal" #changeNetworkButton></span>
<!-- Wrong Network -->
<div class="modal fade" id="changeNetworkModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" tabindex="-1" aria-labelledby="changeNetworkModal" aria-hidden="true">
    <div class="changeNetworkModalDiv">
    <div class="modal-dialog changeNetworkModalDialog">
        <div class="modal-content">
            <div class="modal-header wallect-popup-header wrong-network">
                <div class="col-">
                    <div class="change-modal-title">Wrong Network</div>
                    <div class="change-modal-content">Change your network to BSC</div>
                </div>
                <div class="col-">
                    <button class="btn-style-yellow" (click)="changeNetwork()"> Change Network</button>
                </div>

            </div>
        </div>
    </div>
</div>
</div>

   