import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const web3 = new Web3(environment.provider);
const abi = require('../constants/abi/exchange.json');

const contract = new web3.eth.Contract(abi, environment.exchange_contract);


@Injectable({
  providedIn: 'root',
})

/**
 * Injectable
 */
export class ExchangeContractService {
  /**
   * Completes fixed sale
   * @param {any} order
   */
  async completeFixedSale(order:any = {}) {
    const saleEncodedAbi = await contract.methods.completeFixedSale(order).encodeABI();
    return saleEncodedAbi;
  }

  /**
   * Gets token type
   * @param {string} type
   */
  async getTokenType(type:string = 'ERC721') {
    const assetBytes = type === 'ERC721' ? await contract.methods.ASSET_TYPE_ERC721().call() : await contract.methods.ASSET_TYPE_ERC1155().call();
    return assetBytes;
  }

  /**
   * Gets sale type
   * @param {string} type
   */
  async getSaleType(type:string = 'Buy') {
    const saleTypeBytes = type === 'Buy' ? await contract.methods.FIXED_SALE_CLASS().call() : type === 'Bid' ? await contract.methods.OPEN_AUCTION_SALE_CLASS().call() : await contract.methods.CLOSED_AUCTION_SALE_CLASS().call();
    return saleTypeBytes;
  }

  /**
   * Creates order
   * @param {any} order
   * @param {string} signature
   */
  async createOrder(order: any, signature: string) {
    const createOrderAbi = await contract.methods.createOrder(order, signature).encodeABI();
    return createOrderAbi;
  }

  /**
   * Determines whether blacklisted is
   * @param {string} address
   */
  async isBlacklisted(address:string) {
    const blacklisted = await contract.methods.isBlacklisted(address).call();
    return blacklisted;
  }

  /**
   * Completes bidding
   * @param {any} order
   * @param {string} signature
   */
  async completeBidding(order: any, signature: string) {
    const completeBiddingApi = await contract.methods.completeBidding(order, signature).encodeABI();
    return completeBiddingApi;
  }

  /**
   * Gets order status
   * @param {any} order
   */
  async getOrderStatus(order: any) {
    return await contract.methods.OrderStatus(order).call();
  }

  /**
   * Cancels order
   * @param {any} order
   */
  async cancelOrder(order: any) {
    const cancelOrderAbi = await contract.methods.cancelOrder(order).encodeABI();
    return cancelOrderAbi;
  }
}
