
        <div class="nft__item style-2">
            <ngx-shimmer-loading [width]="'50px'" [height]="'100px'" [shape]="'circle'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'70%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'80%'" [height]="'15px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'100%'" [height]="'10px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'100%'" [height]="'10px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'100%'" [height]="'10px'"></ngx-shimmer-loading>
        </div>
