<section class="top-collections">
    <div class="container">
        <div class="bg-gradient-1 bg-right-top"></div>
        <div class="bg-gradient-2 bg-right-top"></div>
        <div class="row wow fadeIn">
            <div class="col-lg-12">
                <div class="text-center title-wrap">
                    <h2>Top Collections</h2>
                <p>Discover a new realm of gaming on the Blockchainx Arcade, a hub of games powered by Blockchainx <br/>
                    uniting gamers, investors and developers seamlessly.</p>
                </div>
                <!-- <div class="text-center">
                    <h2>Top Collections</h2>
                    <div class="small-border bg-color-2"></div>
                </div> -->
            </div>
            <owl-carousel-o [options]="blogPost">
                <ng-template carouselSlide *ngFor="let collection of collections; let i = index;">
                    <div class="nft__item" [ngClass]="{'row col-md-4': collections.length < 2}">
                        <!-- <div class="author_list_pp profile-img" [ngClass]="{'author_list_sm': collections.length < 2}">
                            <a [routerLink]="['/nft-details']">
                                <img class="lazy"
                                    [src]=" collection.collectionImage ? collection.collectionImage : 'assets/images/author/author-1.jpg'"
                                    alt="Profile Image">
                                <i class="fa fa-check"></i>
                            </a>
                        </div> -->
                      
                        <div class="nft__item_wrap">
                            <a [routerLink]="['/nft-details']" [routerLink]="['/nft-details']">
                                <img [src]=" collection.coverPhoto ? collection.coverPhoto : 'assets/images/items/static-1.jpg'"
                                    class="lazy nft__item_preview coverPhoto" alt="">
                            </a>
                        </div>
                        <div class="nft__item_info">
                            <a [routerLink]="['/nft-details']">
                                <h4>{{collection?.collectionName}}</h4>
                            </a>
                            <p
                                class="line-clamp-2 description">{{collection?.description}}</p>
                            <div class="d-flex items-center feature-section"
                                *ngIf="collection?.features?.length > 0">
                                <div *ngFor="let feature of collection.features" class="feature-div">
                                    <!-- <img src='{{feature.featureImage ? feature.featureImage : "assets/images/discover-nft/explore-collection/community.svg"}}'
                                        class="w-4 h-4 feature-image" alt="BX-nft">  -->
                                        <span class="">{{feature.feature}}</span>
                                </div>
                            </div>
                            <hr />
                                <div class="floor-section d-flex justify-content-between">
                                    <span class="">Floor Price</span>
                                    <div class="d-flex gap-2">
                                    <img src="assets/images/icons/bnb.svg"
                                        class="w-5 h-5 border-solid rounded-md border-2 border-white coin-img" alt="BX-nft">
                                    <span
                                        class="truncate lg:text-clip floor-price-text"
                                        tooltip="{{collection?.result?.floor_price}} BNB"
                                        placement="top">{{collection?.result?.floor_price | number:'1.2-4'}} <span>BNB</span></span>
                                    </div>
                                </div>
                                <div class="nft__item_action">
                                    <a [routerLink]="['/nft-details/', collection._id]" class="btn-style-one">View
                                        Collection</a>
                                </div>
                        </div>
                    </div>


                </ng-template>
            </owl-carousel-o>
            <div class="row" *ngIf="collections.length == 0 && !getCollectionsResponse" class="no-trend-nft">
                Top Collections yet to be update
            </div>
            <div class="row" *ngIf="getCollectionsResponse">
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
                <div class="col-4">
                    <app-shimmer></app-shimmer>
                </div>
            </div>


        </div>
        <a [routerLink]="['/collections']" *ngIf="getCollectionsResponse" class="btn-style-default">Explore All Collections</a>
    </div>
</section>