<app-loader *ngIf="pageLoader"></app-loader>
    <div id="wrapper" *ngIf="!pageLoader">
        <app-nav-bar></app-nav-bar>
        <!-- content begin -->
        <!-- <div class="no-bottom no-top" id="content">
            <div id="top"></div> -->

            <!-- section begin -->
            <!-- <section id="subheader">
                <div class="center-y relative text-center">
                    <div class="container">
                    </div>
                </div>
            </section> -->
            <!-- section close -->

    
            <section class="top-collections collections-page">
                <div class="container">
                    <div class="bg-gradient-1 bg-right-top"></div>
                    <div class="bg-gradient-2 bg-right-top"></div>
                    <div class="bg-gradient-1 bg-left-bottom"></div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div class="text-center title-wrap">
                                <h2 class="pb-0">Edit Profile</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <form action="#" [formGroup]="editProfileForm" (ngSubmit)="updateProfile()"
                                enctype="multipart/form-data">
                                <div class="de_tab tab_simple">
                                    <div class="de_tab_content edit-profile">
                                        <div class="tab-1">
                                            <div class="row wow fadeIn">
                                                <div class="col-lg-8 mb-sm-20">
                                                    <div class="field-set">
                                                        <h5>Display Name</h5>
                                                        <input type="text" autocomplete="off" maxlength="30"
                                                            [(ngModel)]="userDetailsName"
                                                            (ngModelChange)="valueChangeName(userDetailsName)"
                                                            formControlName="displayName" class="form-control text-box"
                                                            placeholder="Enter Display Name" />
                                                        <small class="text-red"
                                                            *ngIf="remainingTextName != ''">{{remainingTextName}}</small>
                                                        <div *ngIf="editProfileForm && profileForm['displayName']['errors']"
                                                            class="text-red">
                                                            <small class="text-danger"
                                                                *ngIf="profileForm['displayName']['errors']['required']">Please
                                                                enter display name</small>
                                                        </div>
                                                        <div class="spacer-20"></div>

                                                        <h5>Username</h5>
                                                        <input type="text" autocomplete="off" id="user-name"
                                                            maxlength="15" [(ngModel)]="userDetailsuserName"
                                                            (ngModelChange)="valueChangeUserName(userDetailsuserName)"
                                                            formControlName="userName"
                                                            (keyup)="checkUserName(profileForm['userName'].value)"
                                                            class="form-control text-box"
                                                            placeholder="Enter username" />
                                                        <div *ngIf="editProfileForm && profileForm['userName']['errors']"
                                                            class="text-red">
                                                            <small class="text-danger"
                                                                *ngIf="profileForm['userName']['errors']['required']">Please
                                                                enter username</small>
                                                        </div>
                                                        <small class="text-red"
                                                            *ngIf="remainingTextUserName != ''">{{remainingTextUserName}}</small>
                                                        <small class="text-red d-flex"
                                                            *ngIf="userNameError != ''">{{userNameError}}</small>
                                                        <div class="spacer-20"></div>

                                                        <h5>Email Address</h5>
                                                        <input type="email" formControlName="emailAddress" id="email-id"
                                                            (keyup)="checkUserEmail(profileForm['emailAddress'].value)"
                                                            class="form-control text-box"
                                                            placeholder="Enter your Email" />
                                                        <div *ngIf="editProfileForm && profileForm['emailAddress']['errors']"
                                                            class="text-red">
                                                            <small class="text-danger"
                                                                *ngIf="profileForm['emailAddress']['errors']['required'] || profileForm['emailAddress']['errors']['email']">Please
                                                                enter email</small>
                                                        </div>
                                                        <small class="text-red"
                                                            *ngIf="userEmailError != ''">{{userEmailError}}</small>
                                                        <div class="spacer-20"></div>

                                                        <h5>Wallet Address</h5>
                                                        <input type="text" formControlName="walletAddress"
                                                            class="form-control text-box" readonly />
                                                        <div *ngIf="editProfileForm && profileForm['walletAddress']['errors']"
                                                            class="text-red">
                                                            <small class="text-danger"
                                                                *ngIf="profileForm['walletAddress']['errors']['required']">Please
                                                                enter wallet address</small>
                                                        </div>
                                                        <div class="spacer-20"></div>

                                                        <h5>Bio</h5>
                                                        <textarea formControlName="bio" maxlength="200"
                                                            [(ngModel)]="userDetailsbio"
                                                            (ngModelChange)="valueChangeBio(userDetailsbio)"
                                                            class="form-control text-box"
                                                            placeholder="Tell the world who you are!"></textarea>
                                                        <small class="text-red"
                                                            *ngIf="remainingTextBio != ''">{{remainingTextBio}}</small>
                                                        <div class="spacer-20"></div>

                                                    </div>
                                                </div>

                                                <div id="sidebar" class="col-lg-4 text-center">
                                                    <h5>Profile image </h5>
                                                    <img [src]="croppedImage != '' ? croppedImage : profile != '' ? (profile != null ? profile : 'assets/images/author/author-11.jpg') : 'assets/images/author/author-11.jpg'"
                                                        id="click_profile_img"
                                                        class="d-profile-img-edit img-fluid profile-image" alt="profile-img">
                                                    <button class="btn-main input-file-button" type="button">
                                                        <label>Choose File</label>
                                                        <input type="file" (change)="onProfileChange($event)">
                                                    </button>


                                                    <div class="spacer-30"></div>

                                                    <h5>Profile banner </h5>
                                                    <img [src]="bannerChange ? bannerChange : banner != '' ? (banner != null ? banner : 'assets/images/background/subheader.jpg') : 'assets/images/background/subheader.jpg'"
                                                        id="click_banner_img"
                                                        class="d-banner-img-edit img-fluid cover-image" alt="cover-img">
                                                    <button class="btn-main input-file-button" type="button">
                                                        <label>Choose File</label>
                                                        <input type="file" (change)="onSelect($event)">
                                                    </button>

                                                </div>

                                                <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->



                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="spacer-30"></div>
                                <button type="submit" [disabled]="updateProfileLoader" id="submit" class="btn-style-yellow">
                                    <span *ngIf="!updateProfileLoader"> Update profile </span>
                                    <span *ngIf="updateProfileLoader"> Processing... </span>
                                </button>
                                
                            </form>
                        </div>
                        <div class="col-lg-2">
                            <image-cropper *ngIf="showCrope"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"
    format="png"
    (imageCropped)="imageCropped($event)"
></image-cropper>

                        </div>
                    </div>
                </div>
            </section>

     
        <!-- content close -->
        <app-footer></app-footer>
    </div>