<div class="row">
        <!-- nft item begin -->
        <div class="d-item col-xs-12 nft-launch-details" [ngClass]="isPartner ? 'col-lg-4 col-md-6 col-sm-6' : 'col-lg-6 col-md-6 col-sm-6'"
                    *ngFor="let collection of privateSales; let i = index;">
            <!-- <div class="nft__item style-2" [routerLink]="['/single-launchpad-nft', collection._id]">
                <div class="de_countdown">
                    <span
                        *ngIf="collection?.saleStatus == 'live' && collection?.leftNftCount == 0">Soldout</span>
                    <span *ngIf="collection?.saleStatus == 'end'">Sale Ended</span>
                    <div *ngIf="collection?.saleStatus != 'end'  && collection?.leftNftCount != 0">
                        <span *ngIf="!checkIfEnted(collection?.saleEndTime) && !checkIfStarted(collection?.saleStartTime)">
                            Sale Ends In
                            {{currentSaleTimer(collection?.saleEndTime, i)}}
                            <b id="countDown{{i}}"> </b>
                        </span>
                        <span *ngIf="checkIfStarted(collection?.saleStartTime)">
                            Sale Starts In
                            {{currentSaleTimer(collection?.saleStartTime, i)}}
                            <b id="countDown{{i}}"> </b>
                        </span>
                    </div>
                </div>
                <div class="author_list_pp">
                    <a>
                        <img class="lazy"
                            src="{{collection.collectionImage ? collection.collectionImage : 'assets/images/author/author-1.jpg'}}"
                            alt="">
                        <i class="fa fa-check"></i>
                    </a>
                </div>
                <div class="nft__item_wrap">
                    <a >
                        <img src="{{collection.coverPhoto ? collection.coverPhoto : 'assets/images/items/static-1.jpg'}}"
                            class="lazy nft__item_preview coverPhoto" alt="">
                    </a>
                </div>
                <div class="nft__item_info">
                    <a>
                        <h4>{{collection?.collectionName}}</h4>
                    </a>
                    <span
                        class="d-inline fw-medium text-sm xl:text-base">{{collection?.partnerId?.category?.categoryName}}
                        | by <a class="text-green fw-medium"
                            [routerLink]="['/partner', collection?.partnerId?._id]">{{collection?.partnerId?.name}}</a></span>
                    <small
                        class="text-white text-sm xl:text-base fw-regular line-clamp-2 px-6 description">{{collection?.description}}</small>
                    <div class="flex space-x-4 items-center px-6 feature-section"
                        *ngIf="collection?.features?.length > 0">
                        <div *ngFor="let feature of collection.features" class="feature-div">
                            <img src='{{feature.featureImage ? feature.featureImage : "assets/images/discover-nft/explore-collection/community.svg"}}'
                                class="w-4 h-4 feature-image" alt="ekta-nft"> <span
                                class="">{{feature.feature}}</span>
                        </div>
                    </div>
                    <div class="nft-details text-white d-flex justify-content-between">
                        <div class="nft-let">
                            NFT Left {{collection?.leftNftCount}}
                        </div>
                        <div class="max-mint">
                            Max Mint {{collection?.nftCount}} NFT
                        </div>
                    </div>
                    <div class="d-flex view-btn cursor-pointer" [routerLink]="['/single-launchpad-nft', collection._id]">
                        <div class="nft__item_action btn-main">
                            <a  class="text-white">View
                                Sale</a>
                        </div>
                    </div>

                </div>
            </div> -->

             
            <div class="nft__item" [routerLink]="['/single-launchpad-nft/', collection._id]">
                <div class="nft__item_wrap">
                    <a [routerLink]="['/single-launchpad-nft']">
                        <img [src]=" collection.coverPhoto ? collection.coverPhoto : 'assets/images/items/static-1.jpg'"
                            class="lazy nft__item_preview coverPhoto" alt="">
                    </a>
                </div>
                <div class="nft__item_info">
                    <a>
                        <h4>{{collection?.collectionName}}</h4>
                    </a>
                    <span
                        class="d-inline fw-medium text-sm xl:text-base">{{collection?.partnerId?.category?.categoryName}}
                        | by <a class="text-green fw-medium"
                            [routerLink]="['/partner', collection?.partnerId?._id]">{{collection?.partnerId?.name}}</a></span>
                    <p
                        class="line-clamp-2 description">{{collection?.description}}</p>
                    <div class="d-flex items-center feature-section"
                        *ngIf="collection?.features?.length > 0">
                        <div *ngFor="let feature of collection.features" class="feature-div">
                            <!-- <img src='{{feature.featureImage ? feature.featureImage : "assets/images/discover-nft/explore-collection/community.svg"}}'
                                class="w-4 h-4 feature-image" alt="BX-nft">  -->
                                <span class="">{{feature.feature}}</span>
                        </div>
                    </div>
                    <hr />
                        <div class="nft-details d-flex justify-content-between">
                            <div class="nft-let">
                                NFT Left {{collection?.leftNftCount}}
                            </div>
                            <div class="max-mint">
                                Max Mint {{collection?.nftCount}} NFT
                            </div>
                        </div>
                        <div class="nft__item_action">
                            <a [routerLink]="['/single-launchpad-nft', collection._id]" class="btn-style-one">View
                                Sale</a>
                        </div>
                </div>
            </div>

        </div>

    </div>
