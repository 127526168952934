import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturedPartnersService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
  ) { }

  /**
   * Get Top Partners
   * @return {HttpResponse}
   */
  public getFeaturedPartners() {
    return this.http.get(`${environment.api_url}/api/v1/top-sellers`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get Top Partners List
   * @return {HttpResponse}
   */
  public getTopPartnersList(searchKeyword: string = '') {
    return this.http.get(`${environment.api_url}/api/v1/get-partners?name=${searchKeyword}`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }

  /**
   * Get Top Partners
   * @return {HttpResponse}
   */
  public getTopPartners() {
    return this.http.get(`${environment.api_url}/api/v1/top-sellers`)
        .pipe(
            retry(2), // retry a failed request up to n times
            catchError(this.commonService.handleError), // handle error
        );
  }
}
