import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { CommonService } from 'src/app/services/common.service';
import { ExchangeContractService } from 'src/app/services/exchange-contract.service';
import { TradeService } from 'src/app/services/trade.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexTitleSubtitle, ApexXAxis, ChartComponent } from 'ng-apexcharts';

import Web3 from 'web3';
const provider = (window as { [key: string]: any })['ethereum'] as string;
const web3 = new Web3(provider || environment.provider);
export type ChartOptions = {
  series: ApexAxisChartSeries;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-trade-details',
  templateUrl: './trade-details.component.html',
  styleUrls: ['./trade-details.component.css'],
})

/**
 * Component
 */
export class TradeDetailsComponent implements OnInit {
  public options: Partial<ChartOptions> | any;


  @ViewChild('chart', { static: false }) chart: any = ChartComponent;
  public chartOptions: any = {};
  @Input() nft: any = {};
  @Input() offers: any = {};
  @Input() nftId: any = {};
  objectKeys = Object.keys;
  public isAcceptBidModalShown = false;
  public offer: any = {};
  @Input() account: any = {};
  public nftActivity: any = [];
  public nftActivityList: any = [];
  public totalPages: number = 1;
  public limit: number = 5;
  public page: number = 0;
  public showBidLoader: boolean = false;
  public showgraph: boolean = false;
  public priceAverage: number = 0;
  @ViewChild('acceptOfferClose') acceptOfferClose:any;
  /**
   * Creates an instance of trade details component.
   */
  constructor(
    private router: Router,
    private tradeService: TradeService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private exchangeContractService: ExchangeContractService,
    private accountService: AccountService,
  ) {
    this.options = {
      chart: {
        type: 'area',
      },

    };
  }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.getNftTransactionActivity();
    this.priceChart();
  }

  /**
   * Grapgs toggle
   * @param {boolean} type
   */
  grapgToggle(type : boolean) {
    this.showgraph = type;
  }
  /**
   * Attributes filter
   * @param {any} attribute
   */
  public attributeFilter(attribute: any) {
    const triatName = Object.keys(attribute)[0];
    const triatValue = Object.values(attribute)[0];
    const query = { [triatName]: triatValue };
    this.router.navigate([`/nft-details/${this.nft.nftCollection._id}`], { queryParams: { 'attributes': JSON.stringify(query) } });
  }

  /**
   * Accepts bid modal
   * @param {any} offer
   */
  public acceptBidModal(offer: any) {
    this.isAcceptBidModalShown = true;
    this.offer = offer;
    this.offer.feePercentage = this.nft?.nftCollection?.creatorFee + this.nft?.nftCollection?.ektaFee;
    this.offer.fee = (this.offer.bidAmount * (this.nft?.nftCollection?.creatorFee + this.nft?.nftCollection?.ektaFee)) / 100;
    this.offer.creatorFee = (this.offer.bidAmount * this.nft?.nftCollection?.creatorFee ) / 100;
    this.offer.ektaFee = (this.offer.bidAmount * (this.nft?.nftCollection?.ektaFee)) / 100;
    this.offer.total = this.offer.bidAmount - this.offer.fee;
  }

  /**
   * Prices chart
   */
  public priceChart() {
    this.tradeService.getPriceHistory(this.nftId).subscribe({
      next: (response: any) => {
        const price: any = [];
        const timestamp: any = [];
        let sumOfPrice = 0;
        response.data.map((item: any, index: number) => {
          if (item.price) {
            price.push(item.price);
            sumOfPrice += item.price;
            timestamp.push(moment(new Date(item.createdAt)).format('DD MMM YY'));
            if (index === response.data.length - 1) {
              this.priceAverage = sumOfPrice / (price.length);
              this.options.series = [{ name: 'Price', data: price }];
              this.options.xaxis = { categories: timestamp,
                labels: {
                  style: {
                    colors: '#fff',
                  },
                },
              };
              this.options.yaxis = {
                labels: {
                  style: {
                    colors: '#fff',
                  },
                },
              };
            }
          }
        });
      },
      error: (_error) => {
      },
    });
  }

  /**
   * Check if bid is ended
   *
   * @param {string} date
   * @return {boolean}
   */
  public checkIfBidEnded(date: string): boolean {
    return new Date().getTime() > new Date(date).getTime();
  }

  /**
   * Gets nft transaction activity
   */
  public getNftTransactionActivity() {
    this.tradeService.getNftTransactionActivity(this.nftId).subscribe({
      next: (response: any) => {
        this.nftActivityList = [...response['data']];
        this.totalPages = Math.ceil(this.nftActivityList.length / this.limit);
        this.nftActivity = this.nftActivityList.slice(this.page, this.limit);
      },
      error: (error) => {
        this.toastr.error(error.message, 'Failed to fetch offers');
      },
    });
  }

  /**
   * Accepts bid
   * @param {any} bid
   */
  public async acceptBid(bid: any) {
    try {
      this.showBidLoader = true;
      // complete bidding
      const order = await this.organizeOrder(this.nft.saleType);
      const bidOrder = await this.commonService.BidOrder(order, bid.buyerId, bid.bidAmount.toString());
      const completeBidAbi = await this.exchangeContractService.completeBidding(bidOrder, bid.signature);
      const message: any = {
        method: 'eth_sendTransaction',
        from: this.account.walletAddress,
        to: environment.exchange_contract,
        data: completeBidAbi,
        chainId: environment.BSC_CHAINID,
      };

      web3.eth.sendTransaction(message)
          .on('receipt', async (receipt) => {
            this.tradeService.acceptBid(bid._id).subscribe((response) => {
              const data = {
                status: true,
              };
              this.accountService.updateAcceptBid(data);
              this.isAcceptBidModalShown = false;
              this.acceptOfferClose.nativeElement.click();
              this.showBidLoader = false;
              this.toastr.success('Offer Accepted');
            });
          })
          .on('error', (error: any) => {
            this.showBidLoader = false;
            this.acceptOfferClose.nativeElement.click();
            if (error.code === 4001) this.toastr.error('User rejected');
            else this.toastr.error('Transaction Failed');
          });
    } catch (error) {
      this.acceptOfferClose.nativeElement.click();
      this.showBidLoader = false;
    }
  }

  /**
   * Organizes order
   * @param {string} type
   */
  public async organizeOrder(type: string) {
    let order;
    if (type === 'Buy') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),
        this.nft?.nftCollection?.collectionAddress,
        Number(this.nft?.tokenId),
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        0, // start time is always 0 for buy
        0, // end time is always 0 for buy
        this.nft?.nonce,
      );
    }
    if (type === 'Bid') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),

        this.nft?.nftCollection?.collectionAddress,
        Number(this.nft?.tokenId),
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        moment(this.nft?.bidStartTime).unix(),
        0, // end time is always 0 for bid
        this.nft?.nonce,
      );
    }
    if (type === 'TimedBid') {
      order = await this.commonService.Order(
          await this.getTokenType(this.nft?.nftCollection?.nftType),
          await this.getSaleType(this.nft?.saleType),
        !this.nft.nftSale ? web3.utils.toChecksumAddress(this.nft.partnerAddress) : web3.utils.toChecksumAddress(this.nft.nftSellerAddress),

        this.nft?.nftCollection?.collectionAddress,
        this.nft?.tokenId,
        1, // TODO: handle tokenAmount for ERC1155
        this.nft?.price,
        moment(this.nft?.bidStartTime).unix(),
        moment(this.nft?.bidEndTime).unix(),
        this.nft?.nonce,
      );
    }
    return order;
  }

  /**
   * Gets token type
   * @param {string} type
   * @return  {string}
   */
  public getTokenType(type: string) {
    return this.exchangeContractService.getTokenType(type);
  }

  /**
   * Gets sale type
   * @param {string} type
   * @return  {string}
   */
  public getSaleType(type: string) {
    return this.exchangeContractService.getSaleType(type);
  }
}
