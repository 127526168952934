import { Component, Output, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-wallets',
  templateUrl: './wallets.component.html',
  styleUrls: ['./wallets.component.css'],
})

/**
 * Export Class
 */
export class WalletsComponent implements OnInit {
  @Output() connectMetaMaskUpdate = new EventEmitter<any>();
  @Input() isCreateAccount : boolean = false;
  @ViewChild('createAccountModalOpen') createAccountModalOpen:any;
  isShownModal: any;

  /**
   * Creates an instance of wallets component.
   */
  constructor(
    private accountService:AccountService,
  ) {

  }

  /**
   * on init
   */
  ngOnInit() {
    this.accountService.openWalletObserve.subscribe((response : any) => {
      if (response.status) {
        // const data = {
        //   status: false,
        //   type: 'metamask',
        // };
        this.isShownModal = response.status;
        this.createAccountModalOpen.nativeElement.click();
        // this.accountService.openWalletModal(data);
      }
    });
  }
  /**
   * Connects meta mask
   */
  connectMetaMask() {
    this.connectMetaMaskUpdate.emit();
  }
}
