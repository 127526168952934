import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CollectionService } from 'src/app/services/collection.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css'],
})

/**
 * Export Class
 */
export class PartnerComponent implements OnInit {
  public partner: any = {};
  public partnerCollections : any = [];
  public partnerNftsOnSale : any = [];
  public partnerCollectionId:string = '';
  public partnerNftsLength!:number;
  public partnerPrivateSale: any = [];
  public isShowMoreExpanded : boolean = false;
  public isMenuActive: number = 0;
  public isPriceDropdownShown = false;
  public pageLoader: boolean = false;

  /**
   * Creates an instance of partner component.
   */
  constructor(
    private partnerCollectionService: CollectionService,
    private profileService: ProfileService,
    private toastr:ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.pageLoader = true;
  }

  /**
   * on init
   */
  public ngOnInit(): void {
    window.scrollTo(0, 0);

    const partnerId = this.activatedRoute.snapshot.params['id'];
    this.activatedRoute.queryParams
        .subscribe((params) => {
          this.isMenuActive = (params.active >= 0 && params.active < 3) ? params.active : 0;
        },
        );
    this.getPartnerProfile(partnerId);
    this.getPartnerCollections(partnerId);
    this.getPartnerNftsOnSale(partnerId);
    this.getPartnerPrivateSale(partnerId);
  }

  /**
   * Shows more
   */
  public showMore() {
    this.isShowMoreExpanded = true;
  }
  /**
   * Shows less
   */
  public showLess() {
    this.isShowMoreExpanded =false;
  }
  /**
   * Sets active menu
   * @param {number} index
   */
  public setActiveMenu(index: number) {
    this.isMenuActive = index;
  }
  /**
   * Toggles price
   */
  public togglePrice() {
    this.isPriceDropdownShown = !this.isPriceDropdownShown;
  }

  /**
   * Gets partner nfts on sale
   * @param {string} id
   */
  public getPartnerNftsOnSale(id:string) {
    this.partnerCollectionService.getPartnerNftsOnSale(id).subscribe({
      next: (response:any) => {
        this.partnerNftsOnSale = response['data'];
        this.pageLoader = false;
      },
      error: (error:any) => {
        this.pageLoader = false;
        this.toastr.error(error.message, 'Failed to fetch collection details');
      },
    });
  }

  /**
   * Gets partner private sale
   * @param {string} id
   */
  public getPartnerPrivateSale(id:string) {
    this.partnerCollectionService.getPartnerPrivateSale(id).subscribe({
      next: (response:any) => {
        this.partnerPrivateSale = response['data'];
        this.pageLoader = false;
        this.processSaleList();
      },
      error: (error:any) => {
        this.pageLoader = false;
        this.toastr.error(error.message, 'Failed to fetch private sale list');
      },
    });
  }

  /**
   * Gets partner collections
   * @param {string} id
   */
  public getPartnerCollections(id:string) {
    this.partnerCollectionService.getPartnerCollection(id).subscribe({
      next: (response:any) => {
        this.partnerCollections = response['data'];
        this.pageLoader = false;
      },
      error: (error:any) => {
        this.pageLoader = false;
        this.toastr.error(error.message, 'Failed to fetch collection details');
      },
    });
  }

  /**
   * Process sale list
   */
  public processSaleList() {
    this.partnerPrivateSale.map((item:any) => {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000); // unix timestamp in seconds
      //  if sale is upcoming or live or ended
      if (currentTimeInSeconds >= item.saleStartTime) {
        item.saleStatus = 'live'; // ends in
        if (currentTimeInSeconds > item.saleEndTime) {
          item.saleStatus = 'end'; // sale ended
        }
      } else {
        item.saleStatus = 'upcoming'; // starts in
      }
    });
  }

  /**
   * Gets partner profile
   * @param {string} id
   */
  public getPartnerProfile(id: string) {
    this.profileService.getPartnerProfile(id).subscribe({
      next: (response:any) => {
        this.partner = response['data']['partner'];
        this.pageLoader = false;
      },
      error: (error:any) => {
        this.toastr.error(error.message, 'Failed to fetch partner details');
        this.pageLoader = false;
      },
    });
  }
}
