import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollectionsRoutingModule } from './collections-routing.module';
import { CollectionDetailsComponent } from './collection-details/collection-details.component';
import { CollectionFilterComponent } from './collection-details/collection-filter/collection-filter.component';
import { CollectionListComponent } from './collection-details/collection-list/collection-list.component';
import { NftDetailsComponent } from './nft-details/nft-details.component';
import { NftFiltersComponent } from './nft-details/nft-filters/nft-filters.component';
import { NftListsComponent } from './nft-details/nft-lists/nft-lists.component';
import { SingleNftDetailsComponent } from './single-nft-details/single-nft-details.component';
import { TradeDetailsComponent } from './single-nft-details/trade-details/trade-details.component';
import { TradeComponent } from './single-nft-details/trade/trade.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    CollectionDetailsComponent,
    CollectionFilterComponent,
    CollectionListComponent,
    NftDetailsComponent,
    NftFiltersComponent,
    NftListsComponent,
    SingleNftDetailsComponent,
    TradeDetailsComponent,
    TradeComponent,
  ],
  imports: [
    CommonModule,
    CollectionsRoutingModule,
    PipesModule,
    SharedModule,
  ],
  exports: [
    NftListsComponent,
  ],
})

/**
 * Export Class
 */
export class CollectionsModule { }
