<app-loader *ngIf="pageLoader"></app-loader>
<div id="wrapper" *ngIf="!pageLoader">
  <app-nav-bar></app-nav-bar>

  <!-- content begin -->
  <!-- <div class="no-bottom no-top" id="content"> -->
  <!-- <div id="top"></div> -->

  <section class="single-nft-details">
    <div class="container">
      <div class="bg-gradient-1 bg-right-top"></div>
      <div class="bg-gradient-2 bg-right-top"></div>
      <div class="bg-gradient-1 bg-left-bottom"></div>
      <div class="row g-5">
        <div class="col-md-6 text-center col-img-section">
          <div class="nft-image-wrapper">
            <img
              src="{{ collection?.collectionImage }}"
              class="image-autosize img-fluid img-rounded mb-sm-30"
              alt=""
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="item_info">
            <div class="feature-section mb-2">
              <div
                *ngFor="let feature of collection?.features"
                class="feature-div"
              >
                <!-- <img
                  src="{{
                    feature.featureImage
                      ? feature.featureImage
                      : 'assets/images/discover-nft/explore-collection/community.svg'
                  }}"
                  class="w-4 h-4 feature-image"
                  alt="bx-nft"
                /> -->
                <span class="">{{ feature.feature }}</span>
              </div>
            </div>
            <div
              class="title-section d-flex justify-content-between align-items-start"
            >
              <h2 class="title">{{ collection?.collectionName }}</h2>
              <div class="sale-status">
                <span *ngIf="saleStatus == 'ended'">Sale Ended</span>
                <span
                  *ngIf="saleStatus == 'live' && collection?.leftNftCount != 0"
                  >Sale Live</span
                >
                <span *ngIf="saleStatus == 'upcoming'">Sale Upcomming</span>
                <span
                  *ngIf="saleStatus == 'live' && collection?.leftNftCount == 0"
                  >Sold Out</span
                >
              </div>
            </div>
            <div class="name-section d-block d-sm-flex justify-content-between gap-4">
              <span
                *ngIf="account"
                [routerLink]="['/partner', collection?.partnerId?._id]"
                class="cursor-pointer"
              >
                <p class="pb-2">
                  <span class="fw-semibold">{{ collection?.symbol }} | </span>
                  Owned by
                  <span class="text-blue fw-semibold">{{
                    collection?.partnerId?.name
                  }}</span>
                </p>
              </span>
              <span  *ngIf="!isWhiteListed && isWhiteListingEnabled"               
                class="not-elgible"                
                >
                <p class="pb-2" style="color: rgb(248, 14, 14)">Your wallet is not eligible.</p></span
              >
              <p
              *ngIf="isWhiteListed && isWhiteListingEnabled"
                class="text-success pb-2"
                style="color: rgb(0 94 77 / var(--tw-text-opacity))"
              >
                Your wallet is eligible.
              </p>
            </div>
            <p class="pb-4">{{ collection?.description }}</p>
            <div *ngIf="statusHandled && !saleEnded">
              <span *ngIf="saleStarted">
                <h5>
                  Sale Ends In
                  <b
                    >{{ timerValue }}
                    {{ currentSaleTimer(collection?.saleEndTime) }}</b
                  >
                </h5>
              </span>
              <span *ngIf="!saleStarted">
                <h6>
                  Sale Starts In
                  <b
                    >{{ timerValue }}
                    {{ currentSaleTimer(collection?.saleStartTime) }}</b
                  >
                </h6>
              </span>
            </div>
            <hr
              class="border-grey border-t border-solid my-4"
              *ngIf="statusHandled && !saleEnded"
            />
            <div
              class="buy-now-div pb-4 d-block d-sm-flex justify-content-between"
            >
              <div class="d-block">
                <h6>Price</h6>
                <div class="nft-item-price">
                  <img src="assets/images/icons/bnb.svg" alt="bnb-icon" /><span
                    >{{ collection?.price }}</span
                  >(${{ collection?.price * ektaPrice | number: "1.2-2" }}) BNB
                </div>
              </div>
              <div class="d-block">
                <h6 class="text-sm text-grey fw-medium">Minted / Max</h6>
                <p>
                  {{ collection?.nftCount - collection?.leftNftCount }} /
                  {{ collection?.nftCount }} NFT
                </p>
              </div>
              <!-- Button trigger modal -->
            </div>
          </div>

          <div class="d-block d-sm-flex justify-content-between buynow-quantity">
            <div
              *ngIf="account"
              class="buy-btn d-flex bg-transparent border border-light-grey rounded-pill align-items-center col-6 buynow-section"
            >
              <input
                type="number"
                min="0"
                [(ngModel)]="quantity"
                step="1"
                id="enter-quantity"
                class="border-0 bg-transparent form-control"
                oninput="validity.valid||(value='');"
                placeholder="Quantity"
              />
              <button
                *ngIf="!isBuyerBlacklisted && account"
                [disabled]="
                  (isWhiteListingEnabled && !isWhiteListed) ||
                  saleStatus != 'live' ||
                  quantity * collection?.price > userBalance
                "
                (click)="buyNow()"
                type="button"
                class="btn button-buy btn-style-yellow"
              >
                <span *ngIf="loading"> Processing... </span>
                <span *ngIf="!loading"> Buy Now </span>
              </button>
            </div>
            <button
              *ngIf="!account"
              type="button"
              class="btn-style-yellow"
              (click)="connectWallet()"
              type="button"
            >
              Connect Wallet
            </button>
            <div class="d-flex sm:flex-row items-center gap-4 wallet-balance">
              <div
                *ngIf="account"
                class="py-0 pl-0 sm:py-4 sm:pl-6 wallet-balance-details"
              >
                <span class="d-flex text-sm items-center gap-2"
                  ><img src="assets/icons/connect-wallet.svg" alt="" />Wallet
                  Balance:
                </span>
                <div class="d-flex items-center">
                  <p class="d-flex items-center text-sm fw-medium">
                    {{ userBalance | number: "1.2-2" }}
                  </p>
                  <p class="ps-1">
                    BNB
                    <span class="text-xs text-grey fw-medium"
                      >(${{ userBalance * ektaPrice | number: "1.2-2" }})</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex" *ngIf="isBuyerBlacklisted">
            <p class="text-danger">You are Blacklisted.</p>
          </div>
          <div class="my-4 md:w-auto" *ngIf="quantity > 0">
            <h5 class="text-sm fw-medium">Buy Price</h5>
            <div class="flex items-center gap-2">
              <div class="text-lg fw-medium truncate buy-price" placement="top">
                <span>
                  {{ quantity * collection?.price | number: "1.2-4" }} BNB
                </span>
                <span class="text-sm text-grey fw-medium truncate">
                  (${{
                    quantity * collection?.price * ektaPrice | number: "1.2-4"
                  }})
                </span>
              </div>
            </div>
            <div
              class="col-6"
              *ngIf="quantity * collection?.price > userBalance"
            >
              <h5 class="text-sm text-red fw-medium">Insufficient Balance</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- </div> -->
  <!-- content close -->

  <app-footer></app-footer>
</div>
