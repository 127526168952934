import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NftLaunchpadRoutingModule } from './nft-launchpad-routing.module';
import { NftLaunchpadFilterComponent } from './nft-launchpad-filter/nft-launchpad-filter.component';
import { NftLaunchpadListsComponent } from './nft-launchpad-lists/nft-launchpad-lists.component';
import { SingleLaunchpadNftComponent } from './single-launchpad-nft/single-launchpad-nft.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NftLaunchpadComponent } from './nft-launchpad.component';
import { NftLaunchpadDetailsComponent } from './nft-launchpad-details/nft-launchpad-details.component';

@NgModule({
  declarations: [
    NftLaunchpadFilterComponent,
    NftLaunchpadListsComponent,
    SingleLaunchpadNftComponent,
    NftLaunchpadComponent,
    NftLaunchpadDetailsComponent,
  ],
  imports: [
    CommonModule,
    NftLaunchpadRoutingModule,
    FormsModule,
    SharedModule,
    PipesModule,
  ],
  exports: [
    NftLaunchpadDetailsComponent,
  ],
})

/**
 * Export Class
 */
export class NftLaunchpadModule { }
